import React, { useState, useContext, useEffect } from "react";

import { ScaleLoader } from "react-spinners";
import ReactStars from "react-rating-stars-component";
import { AuthContext } from "../Context/AuthState";
import { useParams,Link } from "react-router-dom";
import { url } from "../Services/Url";
import Rating from "react-rating";
import ReviewComments from "./ReviewComments";
import axios from "axios";

const ReviewRatings = ({ reviews, loading, setReviews }) => {
  const loaderStyle = { textAlign: "center" };

  const myratings = reviews.map((item) => Number(item.rating));
  const test = reviews
    ? myratings.reduce((n, current) => n + current, 0) / reviews.length
    : "none";
  const { state } = useContext(AuthContext);

  const [show, setShow] = useState(false);
  const [ratingStar, setRatingStar] = useState(0);
  const [ratingComment, setRatingComment] = useState("");
  const { bookname } = useParams();

  const config = {
    headers: { Authorization: `Bearer ${state.token}` },
  };
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };
  useEffect(() => {
    axios
      .get(`${url}/api/v1/reviews/${bookname}`, config)
      .then((res) => {
        console.log(res.data.message);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const getReview = () => {
    axios
      .get(`${url}/api/v1/products/${bookname}`)
      .then((res) => {
        setReviews(res.data.reviews);
      })
      .catch((err) => {
        console.log(err.data);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      slug: bookname,
      rating: ratingStar,
      comment: ratingComment,
    };
    axios
      .post(`${url}/api/v1/reviews/${bookname}`, data, config)
      .then((res) => {
        setShow(false);
        getReview();
      });
  };

  return (
    <>
      {loading ? (
        <div style={loaderStyle}>
          <ScaleLoader color="#24902f" />
        </div>
      ) : (
        <div
          data-bs-spy="scroll"
          data-bs-target="#list-example"
          data-bs-offset="0"
          className="scrollspy-example"
          tabIndex="0"
        >
          <div className="review-ratings" id="reviews">
            <div className="container">
              <div
                className="row"
                style={{
                  borderTop: "1px solid #e5e5e5",
                }}
              >
                <h4 style={{ color: "#333333" }} className="review-title mt-3">
                  Reviews and Ratings
                </h4>
              </div>
            </div>
            <div
              className="container mb-3"
              style={{ borderBottom: "1px solid #e5e5e5" }}
            >
              <div className="row">
                {reviews.length > 0 ? (
                  <>
                    <div className="col-lg-1 mt-2">
                      <h3>{parseFloat(test).toFixed(2)}</h3>
                    </div>
                    <div className="col-lg-5 mt-1">
                      <span
                        className="text-secondary "
                        style={{ display: "block" }}
                      >
                        {reviews ? reviews.length : 0} Ratings and{" "}
                        {reviews ? reviews.length : 0} Reviews
                      </span>

                      {/* <ReactStars
                        value={test}
                        activeColor="#24902f"
                        size="30"
                        edit={false}
                        isHalf={true}
                      /> */}
                      <Rating
                        initialRating={test}
                        readonly
                        emptySymbol="far fa-star fa-1x "
                        fullSymbol="fas fa-star  fa-1x "
                        style={{ color: "#259030" }}
                      />
                    </div>
                  </>
                ) : (
                  <div className="col-lg-5 mt-1">
                    <span
                      className="text-secondary "
                      style={{ display: "block" }}
                    >
                      No Review & Ratings
                    </span>
                  </div>
                )}
                {!state.isAuthenticated ? (
                  <div className="col-lg-6">
                    <h5 className="text-right">
                      Please login to write review{" "}
                     <Link to="/login"> <button className="btn btn-outline-success ml-3">
                        Login
                      </button></Link>
                    </h5>
                  </div>
                ) : (
                  <div className="col-lg-6">
                    <h5 className="text-right">
                      <button
                        className="btn btn-outline-success ml-3"
                        onClick={handleShow}
                      >
                        Write a Review
                      </button>
                    </h5>
                  </div>
                )}
              </div>
              {show ? (
                <div className="col-sm-12">
                  <form onSubmit={handleSubmit}>
                    {" "}
                    <div class="form-group myreview">
                      <label htmlFor="exampleFormControlTextarea1"></label>
                      <textarea
                        class="form-control mytextarea"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        placeholder="Please write yout opinion and give a rating"
                        onChange={(e) => setRatingComment(e.target.value)}
                      ></textarea>
                    </div>
                    <ReactStars
                      activeColor="#24902f"
                      size="30"
                      onChange={(newValue) => {
                        setRatingStar(newValue);
                      }}
                    />
                    <input
                      type="submit"
                      value="Submit"
                      className="btn btn-link mb-3"
                      style={{
                        background: "#259030",
                        color: "white",
                        borderRadius: "0px",
                        textDecoration: "none",
                      }}
                    />
                  </form>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
      <ReviewComments
        reviews={reviews}
        loading={loading}
        getReview={getReview}
      />
    </>
  );
};

export default ReviewRatings;
