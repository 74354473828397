import React from 'react'
import BlogArchive from './BlogArchive'
import BlogCategories from './BlogCategories'
import BlogRecentPosts from './BlogRecentPosts'
import BlogSearch from './BlogSearch'
import BlogTag from './BlogTag'

const BlogLeftSideBar = () => {
    return (
        <>
            <div className="col-lg-3 col-md-12 col-12 order-lg-1 order-2" style={{marginTop:"50px"}}>
                <BlogSearch/>
                <BlogCategories/>
                <BlogRecentPosts/>
                <BlogArchive/>
                <BlogTag/>
            </div>
        </>
    )
}

export default BlogLeftSideBar
