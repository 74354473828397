import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
const Breadcumbs = () => {
  const refreshPage = () => {
    window.location.href = "/";
  };
  const location = useLocation();
  let lo = location.pathname;
  const {  category } = useParams();


  return (
    <>
      <div className="breadcrumbs-area" style={{ marginBottom: "0px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav
                aria-label="breadcrumb"
                style={{ backgroundColor: "#fff !important" }}
              >
                <ol className="breadcrumb" style={{ backgroundColor: "#fff" }}>
                  <li className="breadcrumb-item ">
                    <Link
                      to="/"
                      style={{ textDecoration: "none" }}
                      onClick={refreshPage}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item  active">
                    {category === undefined ? (
                      <span
                        className="text-secondary"
                        style={{ textTransform: "capitalize" }}
                      >
                        {lo.slice(1)}
                      </span>
                    ) : (
                      <span style={{ textTransform: "capitalize" }}>
                        {/* {lo.slice(1)} */}
                        <Link to="/books" style={{ textDecoration: "none" }}>
                          Books{" "}
                        </Link>{" "}
                        / {category}
                      </span>
                    )}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Breadcumbs;
