import React,{useContext} from 'react'
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from '../Context/AuthState';
const ProtectedRoute = ({component:Component,...restOfProps}) => {
    const {state} = useContext(AuthContext);
    const isAuthenticated = state.isAuthenticated
    return (
        <>
            <Route
            { ...restOfProps}
            render={(props)=>
                isAuthenticated?<Component {...props}/>:<Redirect to="/login" />
            }
            />
        </>
    )
}

export default ProtectedRoute
