import React from "react";
import { Link } from "react-router-dom";
import { Data } from "../Services/Data";
import MultirowSlickSlider from "./MultirowSlickSlider";
const DealsWeek = () => {
  const bestSeller1 = Data.slice(0, 2).map((item, index) => item);
  const bestSeller2 = Data.slice(2, 4).map((item, index) => item);
  const bestSeller3 = Data.slice(4, 6).map((item, index) => item);
  return (
    <>
      <div className="bestseller-area mt-5" style={{ paddingBottom: "50px", paddingTop: "50px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 col-12 mb-4">
              <div className="row">
                <div className="bestseller-content col-12 col-md-6">
                  <h1 style={{ fontWeight: "bold" }}> Deals of the week</h1>
                  <h2>
                    Inspiration
                    <br />
                    Speaks
                  </h2>
                  <p>
                    Vestibulum porttitor iaculis gravida. Praesent vestibulum
                    varius placerat. Cras tempor congue neque, id aliquam orci
                    finibus sit amet. Fusce at facilisis arcu.{" "}
                  </p>
                </div>
                <div className="banner-img-2 col-12 col-md-6">
                  <Link to="#">
                    <img
                      src="img/Boi-Bazaar/Deals_of_the_week.jpg"
                      alt="banner"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-12 mb-4">
              <MultirowSlickSlider start="6" end="12" />
            </div>
            {/* <MultirowSlickSlider start="0" end="4"/> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default DealsWeek;
