import React,{useEffect,useContext} from "react";
import { useForm } from "react-hook-form";
import axios from "axios"
import { AuthContext } from "../../Context/AuthState";
import {url} from '../../Services/Url'
import { toast } from "react-toastify";
const CheckoutShipping = ({setUserAddress}) => {
    const {state} = useContext(AuthContext)
    const { register, handleSubmit, formState: { errors },reset  } = useForm();
    const config = {
    headers:{Authorization:`Bearer ${state.token}`}
  }
  

  const getAddress = ()=>{
      axios.get(`${url}/api/v1/user/addresses`,config)
      .then((res)=>{setUserAddress(res.data)})
      .catch((err)=>console.log(err.message))
  }

    const onSubmit = (data) =>{
       axios.post(`${url}/api/v1/user/addresses`,data,config)
       .then((res)=>{
           getAddress();
           toast.success("New Address Added Successfully", { autoClose: 2000 });
           reset();
        })
       .catch((err)=>console.log(err.message))
    };
  return (
    <>
      <div className="shipping-accordion mt-5">
        <h3>
          <a
            className="btn btn-link"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "14px",
            }}
            data-toggle="collapse"
            href="#collapseExample"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            {/* <span style={{ fontWeight: "bold" }}>
              {" "}
              Ship to a different address?
            </span> */}
            <div className="ship-different-title">
                <h3>
                    <label>Ship to a different address?</label>
                    <input type="checkbox" id="ship-box" />
                </h3>
            </div>
          </a>
        </h3>
        <div className="collapse" id="collapseExample">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="checkout-form-list">
                <label>
                  <strong>First Name </strong><span className="required">*</span>
                </label>
                <input type="text" placeholder="" {...register("first_name", { required: true })} />
                {errors.first_name && <span style={{color:"red"}}>*This field is required</span>}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="checkout-form-list">
                <label>
                  <strong>Last Name </strong><span className="required">*</span>
                </label>
                <input type="text" placeholder="" {...register("last_name", { required: true })}/>
                {errors.last_name && <span style={{color:"red"}}>*This field is required</span>}
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="checkout-form-list">
                <label>
                  <strong>Address </strong><span className="required">*</span>
                </label>
                <input type="text" placeholder="Street address" {...register("address_1", { required: true })} />
                {errors.address_1 && <span style={{color:"red"}}>*This field is required</span>}
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="checkout-form-list">
                <label>
                  <strong>Town / City </strong><span className="required">*</span>
                </label>
                <input type="text" placeholder="Town / City" {...register("city", { required: true })} />
                {errors.city && <span style={{color:"red"}}>*This field is required</span>}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="checkout-form-list">
                <label>
                  <strong>State / Country </strong><span className="required">*</span>
                </label>
                <input type="text" placeholder="" {...register("country", { required: true })}/>
                {errors.country && <span style={{color:"red"}}>*This field is required</span>}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div className="checkout-form-list">
                <label>
                  <strong>Postcode / Zip </strong><span className="required">*</span>
                </label>
                <input type="text" placeholder="Postcode / Zip" {...register("zip", { required: true })}/>
                {errors.zip && <span style={{color:"red"}}>*This field is required</span>}
              </div>
            </div>

          </div>
          <input
            type="submit"
            value="Submit"
            className="btn btn-link"
            style={{
              background: "#259030",
              color: "white",
              borderRadius: "0px",
              textDecoration: "none",
            }}
          />
          </form>
        </div>
      </div>
    </>
  );
};

export default CheckoutShipping;
