import React, { useState, useEffect, useContext } from "react";
import { CartContext } from "../Context/GlobalContext/GlobalState";
import { Link } from "react-router-dom";
const StickyButton = () => {
  const { cart, addToCart } = useContext(CartContext);
  const [totalAmount, setTotal] = useState(0);

  useEffect(() => {
    let res = cart
      ? cart.reduce(function (accumulator, currentValue) {
        return (
          accumulator +
          currentValue.special_price.amount * currentValue.quantity
        );
      }, 0)
      : "";
    setTotal(res);
  }, [cart]);
  const floatingButton = {
    position: "fixed",
    top: "85%",
    right: "3%",
    border: "1px solid #e7e7e7",
    background: "linear-gradient(#fdfdfd, #FFFFFF)",
    boxShadow: " 0 0 3px 2px rgb(231 231 231 / 75%)",
    textAlign: "center",
    padding: "22px 6px 0px 6px",
    borderRadius: "4px",
    zIndex: "3040",
  };
  return (
    <>
      <div>
        <section className="floating-button floating-button-renew" >
          <div className="btn-cart resize" style={{ padding: "0px 8px" }}>
            <Link to="/cart">
              <div className="count-wrapper" style={{ position: "relative" }}>
                <img src="/img/Boi-Bazaar/cart.png" alt="" width="26px" />
                <span
                  style={{
                    color: "white",
                    textDecoration: "none",
                    background: "#259030 none repeat scroll 0 0",
                    borderRadius: "50%",
                    bottom: "18px",
                    display: "inline-block",
                    fontSize: "18px",
                    height: "25px",
                    left: "20px",
                    lineHeight: "25px",
                    position: "absolute",
                    textAlign: "center",
                    width: "25px",
                  }}
                >
                  {cart ? cart.length : 0}
                </span>
              </div>
            </Link>
            <p
              className="amount"
              style={{
                fontSize: "13px",
                letterSpacing: "-0.5px",
                paddingTop: "2px",
                fontWeight: "700",
                color: "#333",
              }}
            >
              TK. {cart ? totalAmount : 0}
            </p>
          </div>
        </section>
      </div>
    </>
  );
};

export default StickyButton;
