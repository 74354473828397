import axios from 'axios'
import React, {useEffect, useState} from 'react'
import GlobalFooter from '../../Components/GlobalFooter'
import HeaderTopBar from '../../Components/HeaderTopBar'
import LogoBar2 from '../../Components/LogoBar2'
import MenuBar from '../../Components/MenuBar'
import BlogLeftSideBar from './BlogLeftSideBar/BlogLeftSideBar'
import BlogPostDetails from './BlogPostDetails'
import { url, local } from "../../Services/Url";

// post: {},
// comments: [],
// categories: [],
// tags: [],
// isLoading: true,
// formErrors: [],
// formLoading: false,
// slug: null

const BlogDetails = (props) => {

    

    const [post, setPost] = useState({});
    const [comments, setComments] = useState([]);
    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [formErrors, setFormErrors] = useState([]);
    const [formLoading, setFormLoading] = useState(true);
    const [slug, setSlug] = useState(null);

    const submitFromComment = (comment) => {
        let allComments = comments;
        
        allComments.push(comment);

        setComments(allComments);
    }

    useEffect(() => {
        setIsLoading(true)
        const slug = props.match.params.slug;

        setSlug(slug)
        
        axios.get(`${local}/blogs/posts/${slug}`)
        .then(response => {
            console.log(response);
            setPost(response.data.post)
            setComments(response.data.comments)
            setCategories(response.data.categories)
            setTags(response.data.tags)
            setIsLoading(false)

            document.title = `${response.data.post.name} - Boi Bazaar`;
        })



    }, [props.match.params.slug])
    
    return (
        <>
            <HeaderTopBar/>
            <LogoBar2/>
            <MenuBar/>
            <div className="blog-main-area " style={{marginBottom:"70px"}}>
                <div className="container">
                    <div className="row">
                        <BlogLeftSideBar/>
                        <BlogPostDetails submitFromComment={submitFromComment} slug={slug} post={post} comments={comments} categories={categories} tags={tags} isLoading={isLoading} />
                    </div>
                </div>
            </div>
            <GlobalFooter/>
        </>
    )
}

export default BlogDetails
