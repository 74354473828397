import React,{useState,useEffect,useContext} from "react";
import axios from "axios"
import {url} from "../../Services/Url"
import { AuthContext } from "../../Context/AuthState";
const ProfileDashboard = ({data}) => {
  const [userData,setUserData] = useState([])

  const {state} = useContext(AuthContext)
  const config = {
    headers:{Authorization:`Bearer ${state.token}`}
  }



  const fetchUser = async () => {
    await axios.get(`${url}/api/v1/user`, config)
    .then((res) => {
      setUserData(res.data);
    })
    .catch((err)=>{
      console.log(err.message);
    });
  };
 
  useEffect(()=>{
    fetchUser()
  },[])




  return (
    <>
      {" "}
      <div className="tab-pane fade show active" id="dashboad" role="tabpanel">
        <div className="myaccount-content">
          <h5>Dashboard</h5>
          <div className="welcome">
            <p>
              {/* Hello, <strong>{userData?userData.name:""}</strong>  */}
                            Hello, <strong>{data?data.first_name + " "+data.last_name :""}</strong> 
            </p>
          </div>
          <p className="mb-0">
            From your account dashboard. you can easily check & view your recent
            orders, manage your shipping and billing addresses and edit your
            password and account details.
          </p>
        </div>
      </div>
    </>
  );
};

export default ProfileDashboard;
