export const PublicationsData = [
  {
    id: 1,
    author: "অন্যপ্রকাশ",
    image: "/img/publisher.png",
  },
  {
    id: 2,
    author: "সেবা প্রকাশনী",
    image: "/img/publisher.png",
  },
  {
    id: 3,
    author: "প্রথমা প্রকাশন",
    image: "/img/publisher.png",
  },
  {
    id: 4,
    author: "বাংলা একাডেমি",
    image: "/img/publisher.png",
  },
  {
    id: 5,
    author: "বাতিঘর প্রকাশনী",
    image: "/img/publisher.png",
  },
  {
    id: 6,
    author: "ইসলামিক ফাউন্ডেশন",
    image: "/img/publisher.png",
  },
  {
    id: 7,
    author: "বিশ্বসাহিত্য কেন্দ্র",
    image: "/img/publisher.png",
  },
  {
    id: 8,
    author: "আদর্শ",
    image: "/img/publisher.png",
  },
  {
    id: 9,
    author: "সময় প্রকাশন",
    image: "/img/publisher.png",
  },
  {
    id: 10,
    author: "তাম্রলিপি",
    image: "/img/publisher.png",
  },
  {
    id: 11,
    author: "জ্ঞানকোষ প্রকাশনী",
    image: "/img/publisher.png",
  },
  {
    id: 12,
    author: "আগামী প্রকাশনী",
    image: "/img/publisher.png",
  },
  {
    id: 13,
    author: "অনন্যা",
    image: "/img/publisher.png",
  },
  {
    id: 14,
    author: "পাঠক সমাবেশ",
    image: "/img/publisher.png",
  },
  {
    id: 15,
    author: "অক্ষরপত্র প্রকাশনী",
    image: "/img/publisher.png",
  },
  {
    id: 16,
    author: "পাঞ্জেরী পাবলিকেশন",
    image: "/img/publisher.png",
  },
  {
    id: 17,
    author: "তাওহীদ পাবলিকেশন্স",
    image: "/img/publisher.png",
  },
  {
    id: 18,
    author: "সোলেমানিয়া বুক হাউস",
    image: "/img/publisher.png",
  },
  {
    id: 19,
    author: "এমদাদিয়া লাইব্রেরী",
    image: "/img/publisher.png",
  },
  {
    id: 20,
    author: "আনন্দ পাবলিশার্স",
    image: "/img/publisher.png",
  },
  {
    id: 21,
    author: "সিসটেক পাবলিকেশন্স",
    image: "/img/publisher.png",
  },
  {
    id: 22,
    author: "হারপারকলিন্স পাবলিশার্স",
    image: "/img/publisher.png",
  },
  {
    id: 23,
    author: "ইত্যাদি গ্রন্থ প্রকাশ",
    image: "/img/publisher.png",
  },
  {
    id: 24,
    author: "অনুপম প্রকাশনী",
    image: "/img/publisher.png",
  },
];
