import React, { Fragment } from "react";

const ProductInfo = ({ datas, books }) => {
  const firstExample = {
    size: 30,
    value: 2.5,
    isHalf: true,
    activeColor: "#24902f",
    edit: false,
  };

  return (
    <>
      <div className="product-info-area" style={{ marginTop: "80px" }}>
        <ul className="nav">
          <li>
            <a
              className="active"
              href="#specification"
              data-toggle="tab"
              style={{ textDecoration: "none" }}
            >
              Specification
            </a>
          </li>
          <li>
            <a
              className=""
              href="#author"
              data-toggle="tab"
              style={{ textDecoration: "none" }}
            >
              Author
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div className="tab-pane fade show active" id="specification">
            <div className="valu">
              <table className="table table-bordered">
                <tbody>
                  {datas
                    ? datas.map((item, index) => (
                        <Fragment key={index}>
                          <tr>
                            <th
                              style={{
                                backgroundColor: "#d9f2d8",
                                textTransform: "capitalize",
                              }}
                              width="20%"
                              scope="row"
                            >
                              {item.name}
                            </th>
                            <td>{item.value}</td>
                          </tr>
                        </Fragment>
                      ))
                    : ""}
                  {books.publisher ? (
                    <tr>
                      <th
                        style={{ backgroundColor: "#d9f2d8" }}
                        width="20%"
                        scope="row"
                      >
                        Publisher
                      </th>
                      <td>{books.publisher.name}</td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="tab-pane fade " id="author">
            <>
              {books.writers
                ? books.writers.map((item, index) => (
                    <Fragment key={index}>
                      <div className="valu">
                        <h6>{item.name}</h6>
                      </div>
                    </Fragment>
                  ))
                : ""}
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductInfo;
