import React, { useContext, useState, useEffect } from 'react'
import Slider from "react-slick";
import axios from "axios"
import { Link } from "react-router-dom";
import { url } from "../Services/Url";
import { CartContext } from '../Context/GlobalContext/GlobalState';

const MultiThreeRowSlickSlider = ({ start, end ,books}) => {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    // speed: 2000,
    rows: 3,
    slidesPerRow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    speed: 600,

  };


  // const [data, setData] = useState([]);
  // const { cart, addToCart } = useContext(CartContext);

  // useEffect(() => {
  //   axios.get(`${url}/api/v1/products`)
  //     .then((res) => {
  //       setData(res.data.products)
  //     })
  // }, [])

  // const books = data.slice(start, end).map((item) => item)

  return (
    <>
      {books.length > 0 ? (
        <Slider {...settings}>
          {books.map((item, index) => (
            <div className="single-most-product bd mb-3" key={index}>
              <div className="most-product-img">
                <Link to="#">
                  <img
                    src={
                      item.files && item.files.length > 0
                        ? item.base_image.path
                        : "/img/product/6.jpg"
                    }
                    alt=""
                  />
                </Link>
              </div>
              <div className="most-product-content">
                <h4>
                  <Link
                    to={{
                      pathname: `/product-details/${item.slug}`,
                    }}
                    style={{
                      fontFamily: "Hind Siliguri",
                    }}
                  >
                    {item.name}
                  </Link>
                </h4>
                <p>
                  <span style={{ fontWeight: "500" }}>
                    {" "}
                    {item.writers
                      ? item.writers.map((item) => (
                          <span
                            key={item.id}
                            style={{
                              fontFamily: "Hind Siliguri",
                            }}
                          >
                            {item.name}
                          </span>
                        ))
                      : ""}
                  </span>
                </p>
                <div className="product-price">
                  <ul>
                    <li>
                      {" "}
                      TK. {parseFloat(item.special_price.amount).toFixed(
                        2
                      )}{" "}
                    </li>
                    <li className="old-price">
                      Tk. {parseFloat(item.price.amount).toFixed(2)}{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        ""
      )}
    </>
  );
}

export default MultiThreeRowSlickSlider
