import React,{useEffect,useContext,useState} from "react";
import axios from "axios";
import {url} from "../../Services/Url"
import { AuthContext } from "../../Context/AuthState";

const ProfilePaymentMethod = () => {
  const {state} = useContext(AuthContext)
  const [payment,setPayment]=useState([])
  const config = {
    headers:{
      Authorization:`Bearer ${state.token}`
    }
  }

  useEffect(()=>{
      axios.post(`${url}/api/v1/payment-methods`, [], config)
      .then((res)=>
         setPayment(res.data)
        )
      .catch((err)=>console.log(err.message))
  },[])
  return (
    <>
      <div className="tab-pane fade" id="payment-method" role="tabpanel">
        <div className="myaccount-content">
          <h5>Payment Method</h5>
          {payment.map((item,index)=>(
            <p className="saved-message" key={index}>
               {item.name}
            </p>
          ))}
          
        </div>
      </div>
    </>
  );
};

export default ProfilePaymentMethod;
