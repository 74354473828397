import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { url } from "../../Services/Url";
import { AuthContext } from "../../Context/AuthState";
import dateformat from "dateformat";
import ProfileOrderDetails from "./ProfileOrderDetails";
const ProfileOrders = ({ setClicking,clicking }) => {
  const [orders, setOrders] = useState([]);
  const { state, dispatch } = useContext(AuthContext);
  const [showOrders, setShowOrders] = useState(false);
  const [myId, setId] = useState(null);
  const config = {
    headers: { Authorization: `Bearer ${state.token}` },
  };
  useEffect(() => {
    axios
      .get(`${url}/api/v1/orders`, config)
      .then((res) => setOrders(res.data.data))
      .catch((err) => console.log(err.message));
  }, []);

  const showDetails = (id) => {
    setId(id);
    setShowOrders(true);
    setClicking(true);
  };

  return (
    <>
      <div className="tab-pane fade" id="orders" role="tabpanel">
        <div className="myaccount-content">
          <h5>Orders</h5>
          {orders.length > 0 ? (
            <>
              {!showOrders || !clicking  ? (
                <>
                  <div className="myaccount-table table-responsive text-center ">
                    <table className="table table-bordered">
                      <thead className="thead-light">
                        <tr>
                          <th>Order</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Total</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orders.map((item, index) => (
                          <tr key={index}>
                            <td>{item.id}</td>
                            <td>
                              {dateformat(item.created_at, "mmmm dS,yyyy")}
                            </td>
                            <td>{item.status}</td>
                            <td>
                              Tk. {parseFloat(item.total.amount).toFixed(2)}
                            </td>
                            <td>
                              <Link
                                to="#"
                                className=""
                                onClick={() => showDetails(item.id)}
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <>
                  <ProfileOrderDetails
                    id={myId}
                    setShowOrders={setShowOrders}
                    setClicking={setClicking}
                  />
                </>
              )}
            </>
          ) : (
            <div className="card">
              <div className="card-body">You haven't placed any order yet.</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileOrders;
