import React from "react";
// import { Link } from "react-router-dom";
const Pagination = ({
  booksPerPage,
  totalBooks,
  loading,
  currentPage,
  paginate,
  prevPage,
  nextPage,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalBooks / booksPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      {!loading ? (
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li
              className={currentPage <= 1 ? "page-item disabled" : "page-item"}
            >
              <a
                className="page-link"
                href="!#"
                onClick={(e) => {
                  prevPage();
                  window.scrollTo({
                    top: 650,
                    left: 0,
                    behavior: "smooth",
                  });
                  e.preventDefault();
                }}
              >
                Previous
              </a>
            </li>
            {pageNumbers.map((number, index) => (
              <>
                <li
                  key={index}
                  className={
                    number === currentPage ? "page-item active" : "page-item"
                  }
                >
                  <a
                    onClick={(e) => {
                      paginate(number);
                      e.preventDefault();
                      window.scrollTo({
                        top: 650,
                        left: 0,
                        behavior: "smooth",
                      });
                    }}
                    className="page-link"
                    href="!#"
                  >
                    {number}
                  </a>
                </li>
              </>
            ))}

            <li
              className={
                currentPage >= pageNumbers.length
                  ? "page-item disabled"
                  : "page-item"
              }
            >
              <a
                className="page-link"
                href="!#"
                onClick={(e) => {
                  nextPage();
                  e.preventDefault();
                  window.scrollTo({
                    top: 650,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                Next
              </a>
            </li>
          </ul>
        </nav>
      ) : (
        ""
      )}
    </>
  );
};

export default Pagination;
