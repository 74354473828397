export const CategoriesData = [
  {
    id: 1,
    author: "অতিরিক্ত ছাড়",
    image: "/img/category.png",
  },
  {
    id: 2,
    author: "অনুবাদ",
    image: "/img/category.png",
  },
  {
    id: 3,
    author: "অমর একুশে গ্রন্থমেলা",
    image: "/img/category.png",
  },
  {
    id: 4,
    author: "অলিম্পিয়াড",
    image: "/img/category.png",
  },
  {
    id: 5,
    author: "আইন ও বিচার",
    image: "/img/category.png",
  },
  {
    id: 6,
    author: "আত্ম-উন্নয়ন ওমোটিভেশনাল ",
    image: "/img/category.png",
  },
  {
    id: 7,
    author: "ইউনিভার্সিটি",
    image: "/img/category.png",
  },
  {
    id: 8,
    author: "ইতিহাস ও ঐতিহ্য ",
    image: "/img/category.png",
  },
  {
    id: 9,
    author: "ইন স্টক বুকস",
    image: "/img/category.png",
  },
  {
    id: 10,
    author: "ইংরেজি ভাষার বই",
    image: "/img/category.png",
  },
  {
    id: 11,
    author: "উপন্যাস",
    image: "/img/category.png",
  },
  {
    id: 12,
    author: "কমিক্স, নকশা  ",
    image: "/img/category.png",
  },
  {
    id: 13,
    author: "কম্পিউটার,ফ্রিল্যান্সিং ও আউটসোর্সিং",
    image: "/img/category.png",
  },
  {
    id: 14,
    author: "কৃষি ও কৃষক",
    image: "/img/category.png",
  },
  {
    id: 15,
    author: "খেলাধুলা",
    image: "/img/category.png",
  },
  {
    id: 16,
    author: "গণমাধ্যম ও সাংবাদিকতা",
    image: "/img/category.png",
  },
  {
    id: 17,
    author: "গণিত, বিজ্ঞান ও প্রযুক্তি",
    image: "/img/category.png",
  },
  {
    id: 18,
    author: "গল্প",
    image: "/img/category.png",
  },
  {
    id: 19,
    author: "ছড়া, কবিতা ও আবৃত্তি",
    image: "/img/category.png",
  },
  {
    id: 20,
    author: "জীবনী, স্মৃতিচারণ ও সাক্ষাৎকার",
    image: "/img/category.png",
  },
  {
    id: 21,
    author: "দর্শন",
    image: "/img/category.png",
  },
  {
    id: 22,
    author: "ধর্মীয় বই",
    image: "/img/category.png",
  },
  {
    id: 23,
    author: "নাটকের বই",
    image: "/img/category.png",
  },
  {
    id: 24,
    author: "পরিবেশ ও প্রকৃতি",
    image: "/img/category.png",
  },
];
