import React, { useEffect,useContext } from "react";
import AuthorBanner from "../Components/AuthorBanner";
import AuthorsList from "../Components/AuthorsList";
import Breadcumbs from "../Components/Breadcumbs";


import WeeklyTopAuthors from "../Components/WeeklyTopAuthors";

import StickyButton from "../Components/StickyButton";
import StickyWishlist from "../Components/StickyWishlist";
import { AuthContext } from "../Context/AuthState";

const Authors = () => {
  const {state} = useContext(AuthContext)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {state.isAuthenticated ?   <StickyWishlist/> : ""}
      <StickyButton />
     
      <Breadcumbs />
      <AuthorBanner />
      <WeeklyTopAuthors />
      <AuthorsList />

 
    </>
  );
};

export default Authors;
