import React, { Fragment, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { url } from "../Services/Url";
import SingleGridBook from "./SingleGridBook";
import { ScaleLoader } from "react-spinners";
import Pagination from "./Pagination";
import Breadcumbs from "./Breadcumbs";
import LeftFilterBookList from "./LeftFilterBookList";
const TapCategoriesBook = ({ data }) => {
  let { slug } = useParams();
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [booksPerPage, setBooksPerPage] = useState(40);
  const loaderStyle = { textAlign: "center" };

  // if (data === 'best-seller') {
  //   console.log("bestSeller 🐱‍👤");
  // } else if(data === 'new-released') {
  //   console.log("newReleased 🐱");
  // }
  // else if(data === 'price-low-to-high') {
  //   console.log("price-low-to-high 🐱‍🚀");
  // }
  // else if(data === 'price-high-to-low') {
  //   console.log("price-high-to-low 🎃");
  // }
  // else if(data === 'discount-low-to-high') {
  //   console.log("discount-low-to-high 🎨");
  // }
  // else if(data === 'discount-high-to-low') {
  //   console.log("discount-high-to-low 🏆");
  // }
  // else{
  //   console.log("All data 🎈");
  // }

  const fetchBook = async () => {
    setLoading(true);
    await axios.get(`${url}/api/v1/categories/${slug}`).then((res) => {
      setBooks(res.data.products);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetchBook();
  }, [slug]);

  const indexOfLastPage = currentPage * booksPerPage;
  const indexOfFirstPage = indexOfLastPage - booksPerPage;
  const currentBooks = books
    ? books.slice(indexOfFirstPage, indexOfLastPage)
    : "";

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  return (
    <>
    <LeftFilterBookList/>
      <div className="col-lg-9 col-md-12 col-12 order-lg-2 order-1">
      <div className="category-image" style={{ marginBottom: "30px" }}>
        <Link to="#">
          <img
            src="/img/Boi-Bazaar/Header-for-book-list-page.jpg"
            alt="banner"
            style={{ width: "100%" }}
          />
        </Link>
      </div>
      <div className="section-title-5" style={{ marginBottom: "30px" }}>
        <h2>Book</h2>
      </div>
      <Breadcumbs />
      {loading ? (
        <div style={loaderStyle}>
          <ScaleLoader color="#24902f" />
        </div>
      ) : (
        <>
          {books.length <= 40 ? (
            <div className="row">
              {books
                ? books.map((item, index) => (
                    <Fragment key={index}>
                      <SingleGridBook
                        key={item.id}
                        book={item}
                        loading={loading}
                      />
                    </Fragment>
                  ))
                : ""}
            </div>
          ) : (
            <>
              <div className="row">
                {books
                  ? currentBooks.map((item) => (
                      <>
                        <SingleGridBook
                          key={item.id}
                          book={item}
                          loading={loading}
                        />
                      </>
                    ))
                  : ""}
              </div>
              <Pagination
                booksPerPage={booksPerPage}
                totalBooks={books.length}
                loading={loading}
                currentPage={currentPage}
                paginate={paginate}
                nextPage={nextPage}
                prevPage={prevPage}
              />
            </>
          )}
        </>
      )}
      </div>
    </>
  );
};

export default TapCategoriesBook;
