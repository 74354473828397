import React from "react";
import Radium, { StyleRoot } from 'radium';
import { Link } from "react-router-dom";
import axios from 'axios';
import { url, local } from "../Services/Url";

import "./HeroSlider.css";

class HeroSlider extends React.Component {

  constructor() {
    super();

    this.state = {
      isRady: false,
      background: '',
      caption_1: '',
      caption_2: '',
      call_to_action_text: '',
      call_to_action_url: '',

    }
  }


  componentDidMount() {
    axios.get(`${url}/api/v1/homepage/slider`)
      .then(response => {
        this.setState({
          background: response.data.file,
          caption_1: response.data.caption_1,
          caption_2: response.data.caption_2,
          call_to_action_text: response.data.call_to_action_text,
          call_to_action_url: response.data.call_to_action_url,
          isRady: true
        })
      })
  }

  render() {
    const heroSectionBackgroundImage = {
      backgroundImage: `url(${this.state.background})`,
      height: "599px",
      paddingBottom: "130px",
      paddingTop: "125px",

      '@media (max-width: 1024px)': {
        backgroundImage: "none",
        height: "300px",
        marginBottom: "50px",
        marginTop: "50px",
        paddingBottom: "0px",
        paddingTop: "0px",
      },

      '@media (max-width: 560px)': {
        backgroundImage: "none",
        height: "400px",
        marginBottom: "50px",
        marginTop: "50px",
        paddingBottom: "0px",
        paddingTop: "0px",
      },


    }

    const firstHeading = {
      fontSize: "60px",
      fontWeight: "bold",
      letterSpacing: "3px",
      lineHeight: "1.2",
      fontFamily: "myFont",
      textAlign: "center",
      marginBottom: 40,
    }

    const sliderContent = {
      // marginLeft: 200
    }

    if (this.state.isRady) {
      return (
        <StyleRoot>
          <div
            className="single-slider bg-img"
            style={heroSectionBackgroundImage}
          >
            <div className="container" >
              <div className="slider-content" style={sliderContent}>
                <h3
                  style={firstHeading}
                >
                  {this.state.caption_1}
                </h3>
                <h1
                  style={{
                    letterSpacing: "4px",
                    fontSize: "100px",
                    fontFamily: "myFont",
                    fontWeight: "bold",
                    textAlign: "center"
                  }}
                >
                  {this.state.caption_2}
                </h1>
                <div style={{ display: "flex", justifyContent: "center", }}>
                  <Link to={this.state.call_to_action_url} className="" >
                    {this.state.call_to_action_text}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </StyleRoot>
      );
    } else {
      return (
        <></>
      );
    }
  }
}

export default Radium(HeroSlider);
