import axios from 'axios';
import React from 'react'
import {Link} from "react-router-dom"
import Loader from "../../Components/Loader";
import { url, local } from "../../Services/Url";



class PostBlogComment extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			comment: '',
			name: '',
			email: '',
			web: '',
			formErrors: [],
			formLoading: false
		}
	}

	fromSubmit = (e) => {
		e.preventDefault();
		// this.props.submitFromComment(this.state);

		this.setState({
			formLoading: false,
			formErrors: []
		});

		const data = {
			comment: this.state.comment,
			name: this.state.name,
			email: this.state.email,
			web: this.state.web
		};

		axios.post(`${local}/blogs/posts/${this.props.slug}/comments`, data)
        .then(response => {
			
            let comment = response.data.comment;

			// console.log(comment);

			this.props.submitFromComment(comment);

            this.setState({
                formLoading: false,
				comment: '',
				name: '',
				email: '',
				web: ''
            });
        })
        .catch(error => {
			if(error.response.status == 422)
			{
				this.setState({
					formErrors: error.response.data.errors,
					formLoading: false
				})
			}

        })
	}

	render(){
		if(this.state.formLoading)
		{
			return (
				<div style={{ textAlign: 'center' }}>
					<Loader />
				</div>
			);
		} else {

			return (
				<>
					<form onSubmit={(e) => this.fromSubmit(e)}>
					<div className="comment-input" style={{marginTop:"40px"}}>
						<p>We will not publish your email address. Required fields are marked*</p>
						<div className="comment-input-textarea " style={{marginBottom:"30px"}}>
							<label>Comment</label>
							<textarea onChange={(e) => this.setState({ comment: e.target.value })} value={this.state.comment} name="massage" cols="30" rows="10" placeholder="Write your comment here" required />
							{(this.state.formErrors.comment) ? (<p className="text-danger">{this.state.formErrors.comment}</p>) : ''}
						</div>
						<div className="row">
							<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
								<div className="single-comment-input " style={{marginBottom:"30px"}}>
									<label>Name*</label>
									<input onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} type="text" placeholder="Name" required />
									{(this.state.formErrors.name) ? (<p className="text-danger">{this.state.formErrors.name}</p>) : ''}
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
								<div className="single-comment-input " style={{marginBottom:"30px"}}>
									<form action="#"></form>
										<label>Email*</label>
										<input onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} type="email" placeholder="Email" required />
										{(this.state.formErrors.email) ? (<p className="text-danger">{this.state.formErrors.email}</p>) : ''}
								</div>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
								<div className="single-comment-input" style={{marginBottom:"30px"}}>
									<label>Web</label>
									<input onChange={(e) => this.setState({ web: e.target.value })} value={this.state.web} type="text" placeholder="Put your web address" />								
								</div>
							</div>
						</div>
					</div>
					<div className="single-post-button">
						<button>SAVE</button>
					</div>
					</form>
				</>
			)
		}
	}
}

export default PostBlogComment
