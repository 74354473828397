import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { url, local } from "../Services/Url";
import NavMenuChildren from "./NavMenuChildren";
import { url as LinkUrl } from "../Services/LinkGenerator";

class NavMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navigation: [],
    };
  }

  componentDidMount() {
    axios.get(`${url}/api/v1/navigation/primary`).then((response) => {
      this.setState({
        navigation: response.data,
      });
    });
  }

  render() {
    const linkStyle = {
      textDecoration: "none",
    };

    return (
      <>
        <div
          className="main-menu-area d-md-none d-none d-lg-block sticky-header-1"
          id="header-sticky"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="menu-area">
                  <nav>
                    <ul style={{ paddingLeft: "0px" }}>
                      {this.state.navigation.map((item, index) => (
                        <li key={index}>
                          <Link
                            to="/"
                            to={{
                              pathname: LinkUrl({
                                type: item.type,
                                url: item.url,
                                item,
                              }),
                            }}
                            style={linkStyle}
                          >
                            {item.name}
                            {item.children.length > 0 ? (
                              <i className="fa fa-angle-down"></i>
                            ) : (
                              ""
                            )}
                          </Link>
                          {item.children.length > 0 ? (
                            <NavMenuChildren children={item.children} />
                          ) : (
                            <></>
                          )}
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default NavMenu;
