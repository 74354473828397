import React from 'react'
import {Link} from "react-router-dom"
import axios from 'axios';
import {url, local} from "../../../Services/Url"

class BlogTag extends React.Component {

	constructor() {
		super();

		this.state = {
			tags: []
		}
	}

	componentDidMount() {
		axios.get(`${local}/blogs/tags`)
		.then(response => {
			// console.log(response.data);
			this.setState({
				tags: response.data
			})
		})
	}

	render() {

		return (
				<>
					<div className="single-blog">
						<div className="blog-left-title">
							<h3>Tags</h3>
						</div>
						<div className="blog-tag">
							<ul>
								{this.state.tags.map((item, index) => (
									<li key={index}><Link to={`/blog/tag/${item.slug}`}>{item.name}</Link></li>
								))}
							</ul>
						</div>
					</div>
				</>
    		)
		}
}

export default BlogTag
