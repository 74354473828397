import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { url } from "../Services/Url";
import axios from "axios";
import Slider from "react-slick";
const Reviews = () => {
  const [testimonials, setTestimonial] = useState([]);

  useEffect(() => {
    axios
      .get(`${url}/api/v1/testimonials`)
      .then((res) => {
       
        setTestimonial(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows:false,
            autoplay:true,
          }
        }
      ]
    
  };

  return (
    <div>
      <div
        className="testimonial-area  bg"
        style={{
          padding: "100px 0",
          backgroundImage:
            "url(" + "img/Boi-Bazaar/Testimonials-Background.jpg" + ")",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
        }}
      >

        <div className="container">
          <Slider {...settings}>
            {testimonials.map((item) => (
              <div className="row" key={item.id}>
                <div className="col-lg-12">
                  <div className="single-testimonial text-center">
                    <div className="testimonial-img">
                      <Link to="#">
                        <i className="fa fa-quote-right"></i>
                      </Link>
                    </div>

                    <div className="testimonial-text">
                      <p style={{fontFamily:"Hind Siliguri"}}>
                       {item.text}
                      </p>
                      <Link to="#" style={{ textDecoration: "none" }}>
                        {item.name}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
