import React, { useState,createContext, useReducer, useEffect } from "react";
import axios from "axios"
import {url} from '../Services/Url'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

const initialState = {
  isAuthenticated:false,
  user:null,
  token:null
};

export const AuthContext = createContext(initialState);

const authReducer = (state,action)=>{
    switch (action.type) {
        case "LOGIN":
            return{
                ...state,
                isAuthenticated:true,
                user:action.payload.user,
                token:action.payload.token
            };
        case "LOGOUT":
          localStorage.removeItem('Users')
          return{
            ...state,
            isAuthenticated:false,
            user:null,
            token:null
          }
    
        default:
            return state;
    }
}

const AuthStateProvider = ({ children }) => {
 
  const [state, dispatch] = useReducer(authReducer, initialState,()=>{
    const localData = localStorage.getItem("Users");
    return localData ? JSON.parse(localData) : initialState;
  });

  const [wishList,setWishList]= useState([]);
  const [loading,setLoading]=useState(false)

  const config = {
    headers:{Authorization:`Bearer ${state.token}`}
  }

  const getWishlist = async()=>{
    setLoading(true)
    await axios.get(`${url}/api/v1/wishlists`,config)
    .then((res)=>{
    setLoading(false)
     setWishList(res.data.products)
    })
  }

  const createWishList = (id)=>{
    axios.post(`${url}/api/v1/wishlists`,{product_id:id},config)
    .then((res)=>{
      console.log(res.data);
        toast.success("Successfully Added in Wishlist",{autoClose:2000})
    })
    .then(()=>{getWishlist()})
    .catch((err)=>console.log(err.message))

  }
  
  const deleteWishList = (id) =>{
    axios.delete(`${url}/api/v1/wishlists/${id}`,config)
    .then((res)=>{
        getWishlist()
        toast.success("Successfully Deleted from Wishlist",{autoClose:2000})
    })
    .catch((err)=>console.log(err.message))

  }

  useEffect(() => {
    localStorage.setItem("Users", JSON.stringify(state));
    getWishlist();
  }, [state]);
  

  return (
    <AuthContext.Provider
      value={{
       state,dispatch,wishList,loading,createWishList,deleteWishList
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthStateProvider;
