import React from 'react'
import BlogPagination from './BlogPagination'
import SingleBlogPost from './SingleBlogPost'
import Loader from "../../../Components/Loader";
import axios from 'axios';
import { url, local } from "../../../Services/Url";

class BlogPosts extends React.Component {

    constructor() {
        super();

        this.state = {
            isReady: false,
            posts: []
        }
    }

    componentDidMount() {
        axios.get(`${local}/blogs/posts`).then(response => {
            this.setState({
                posts: response.data.data,
                isReady: true
            });
            // console.log(response);
        })
    }

    render() {
        if (this.state.isReady) {
            return (
                <>
                    <div className="col-lg-9 col-md-12 col-12 order-lg-2 order-1">
                        <div className="blog-main-wrapper">
                            {this.state.posts.map((post, index) => (
                                <SingleBlogPost post={post} key={index} />
                            ))}
                            {/* <SingleBlogPost /> */}
                            {/* <BlogPagination /> */}
                        </div>
                    </div>
                </>
            )
        }
        else {
            return <>
                <div className="col-lg-9 col-md-12 col-12 order-lg-2 order-1">
                    <div className="blog-main-wrapper" style={{ textAlign: "center", marginTop: "50px" }}>
                        <Loader />
                    </div>
                </div>
            </>
        }
    }


    // <Loader />
}

export default BlogPosts
