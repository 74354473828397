import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import TabBook from "./TabBook";
import TabBook2 from "./TabBook2";
import Breadcumbs from "../Components/Breadcumbs";
import TapAuthorBook from "./TapAuthorBook";
import { ScaleLoader } from "react-spinners";
import TapPublisherBook from "./TapPublisherBook";
import TapCategoriesBook from "./TapCategoriesBook";
import TapSearch from "../Pages/Search/TapSearch";

const BookLists = ({ data }) => {
  let { id, name, slug } = useParams();
  const location = useLocation();

  return (
    <>
      {location.pathname === `/books/authors/${id}/${name}` ? (
        <TapAuthorBook data={data} />
      ) : location.pathname === `/books/publishers/${slug}` ? (
        <TapPublisherBook data={data} />
      ) : location.pathname === `/books/categories/${slug}` ? (
        <TapCategoriesBook data={data} />
      ) : location.pathname === `/books/search/${name}` ? (
        <TapSearch />
      ) : (
        <TabBook2 />
      )}
    </>
  );
};

export default BookLists;
