import React, {useEffect, useState} from 'react';
import GlobalFooter from "../../Components/GlobalFooter";
import HeaderTopBar from "../../Components/HeaderTopBar";
import LogoBar2 from "../../Components/LogoBar2";
import MenuBar from "../../Components/MenuBar";
import "./Blog.css";
import BlogLeftSideBar from "./BlogLeftSideBar/BlogLeftSideBar";
import BlogCategoryPost from "./BlogCategoryPost"

const BlogCategory = (props) => {

    const [slug, setSlug] = useState([]);

    useEffect(() => {
        setSlug(props.match.params.slug)

        

    }, [props.match.params.slug])

    return (
        <>
        <HeaderTopBar />
        <LogoBar2 />
        <MenuBar />
        <div className="blog-main-area " style={{marginBottom:"70px"}}>
            <div className="container">
            <div className="row">
                <BlogLeftSideBar/> 
                <BlogCategoryPost slug={slug} />
            </div>
            </div>
        </div>
        <GlobalFooter/>
        </>
    );

}

export default BlogCategory;