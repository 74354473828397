import React,{useContext} from "react";
import BookLists from "../Components/BookLists";


import LeftFilterBookList from "../Components/LeftFilterBookList";

import StickyButton from "../Components/StickyButton";
import StickyWishlist from "../Components/StickyWishlist";
import { AuthContext } from "../Context/AuthState";


const BookList = () => {
  const {state} = useContext(AuthContext)
  return (
    <>
       {state.isAuthenticated ?   <StickyWishlist/> : ""}
      <StickyButton />
 
  
      <div className="shop-main-area mt-5" style={{ marginBottom: "70px" }}>
        <div className="container">
          <div className="row">
            <BookLists/>
            {/* LeftFilterBookList->BookLists->TabBook->Pagination */}
            {/* TabBook->SingleListBook */}
            {/* TabBook->SingleGridBook */}
          </div>
        </div>
      </div>
  
    </>
  );
};

export default BookList;
