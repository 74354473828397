export const cartReducer = (state, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      const updatedCart = state.cart ? [...state.cart] : "";
      const updatedIndex = updatedCart.findIndex(
        (item) => item.id === action.payload.id
      );

      if (updatedIndex < 0) {
        updatedCart.push({
          ...action.payload,
          quantity: 1,
        });
      } else {
        let updatedItem = {
          ...updatedCart[updatedIndex],
        };
        // let qty = action.quantity
        //   ? updatedItem.quantity + action.quantity
        //   : (updatedItem.quantity += 1);
        // console.log("res:", qty);

        // updatedItem.quantity = qty;
        updatedCart[updatedIndex] = updatedItem;
      }
      return { ...state, cart: updatedCart };

    case "REMOVE_TO_CART":
      const updatedRemoveCart = [...state.cart];
      const updatedRemoveItemIndex = updatedRemoveCart.findIndex(
        (item) => item.id === action.payload.id
      );
      const updatedRemoveNewItem = {
        ...updatedRemoveCart[updatedRemoveItemIndex],
      };
      updatedRemoveCart.splice(updatedRemoveItemIndex, 1);
      return { ...state, cart: updatedRemoveCart };

    case "INCREMENT_QUANTITY":
      const newCart = [...state.cart];
      const newIndex = newCart.findIndex(
        (item) => item.id === action.payload.id
      );
      let newItem = {
        ...newCart[newIndex],
      };
      newItem.quantity++;
      newCart[newIndex] = newItem;

      return { ...state, cart: newCart };

    case "DECREMENT_QUANTITY":
      const newDecCart = [...state.cart];
      const newDecIndex = newDecCart.findIndex(
        (item) => item.id === action.payload.id
      );
      let newDecItem = {
        ...newDecCart[newDecIndex],
      };
      newDecItem.quantity -= 1;
      newDecCart[newDecIndex] = newDecItem;

      return { ...state, cart: newDecCart };

    case "UPDATED_QUANTITY":
      const updatedCartQty = [...state.cart];
      const updatedNewIndex = updatedCartQty.findIndex(
        (item) => item.id === action.payload.id
      );
      let newCartItem = {
        ...updatedCartQty[updatedNewIndex],
      };
      newCartItem.quantity = action.quantity;
      updatedCartQty[updatedNewIndex] = newCartItem;
      return { ...state, cart: updatedCartQty };
    default:
      return state;
  }
};
