import axios from 'axios';
import React from 'react'
import {Link} from "react-router-dom"
import { url, local } from "../../../Services/Url";


class BlogCategories extends React.Component {

	constructor(){
		super();
		this.state = {
			categories: [],
			isLoading: false
		}
	}

	componentDidMount(){
		axios.get(`${local}/blogs/categories`)
		.then(response => {
			
			this.setState({
				categories: response.data
			})
		})
		.catch(error => {
			console.log(error.response);
		})
	}

	render() {
		return (
			<>
				<div className="single-blog " style={{marginBottom:"50px"}}>
					<div className="blog-left-title">
						<h3>Categories</h3>
					</div>
					<div className="blog-side-menu">
						<ul>
							
							{this.state.categories.map((item, index) => (<li key={index}><Link to={`/blog/category/${item.slug}`}>{item.name} ({item.posts_count})</Link></li>))}
						</ul>
					</div>
				</div>
			</>
		)
	}
    
}

export default BlogCategories
