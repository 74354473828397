import React, {useState, useEffect} from 'react'
import Loader from "../../Components/Loader";
import SingleBlogPost from './BlogPosts/SingleBlogPost';
import BlogPagination from './BlogPosts/BlogPagination';
import axios from 'axios';
import {url, local} from "../../Services/Url"

// axios.get(`${local}/blogs/categories/${this.props.slug}`).then(response => {
//     this.setState({
//         posts: response.data.posts.data,
//         isReady: true
//     });
//     console.log(response.data.posts.data);
// })

const BlogCateogryPost = (props) => {

    const [loading, setLoading] = useState(true)
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        setLoading(true);
        setPosts(null);

        axios.get(`${local}/blogs/categories/${props.slug}`).then(response => {
            setPosts(response.data.posts.data)
            setLoading(false)
            // console.log(response.data.category.name);
            document.title = `${response.data.category.name} - Boi Bazaar`;
        })
    }, [props.slug]);
    
    if (loading) {
        return (  
            <>
                <div className="col-lg-9 col-md-12 col-12 order-lg-2 order-1">
                    <div className="blog-main-wrapper" style={{ textAlign: "center", marginTop: "50px" }}>
                        <Loader />
                    </div>
                </div>
            </>
        )
    }
    else {
        return (
            <>
                <div className="col-lg-9 col-md-12 col-12 order-lg-2 order-1">
                    <div className="blog-main-wrapper">
                        {posts.map((post, index) => (
                            <SingleBlogPost post={post} key={index} />
                        ))}
                        {/* <SingleBlogPost /> */}
                        <BlogPagination />
                    </div>
                </div>
            </>
        )        
    }
    


    // <Loader />
}

export default BlogCateogryPost
