import React, { useState, useEffect, useContext, Fragment } from "react";
import { CartContext } from "../../Context/GlobalContext/GlobalState";
import axios from "axios";
import { url } from "../../Services/Url";
import { AuthContext } from "../../Context/AuthState";
import CheckoutShipping from "./CheckoutShipping";
import { toast } from "react-toastify";
const CheckOutArea = ({discount,totalAmount,setDiscount}) => {
  const { cart } = useContext(CartContext);
  const { state } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${state.token}` },
  };
  const [checked, setChecked] = useState(false);
  const [paymentChecked, setPaymentChecked] = useState(false);
  const [addressChecked, setAddressChecked] = useState(false);
  const [shippings, setShippings] = useState([]);
  const [payments, setPayments] = useState([]);
  const [address, setUserAddress] = useState([]);
  const [shippingCost, setShippingCost] = useState(0);
  const [shippingMethod, setShippingMethod] = useState("");

  const [data, setData] = useState({
    billing_first_name: "",
    billing_last_name: "",
    billing_address_1: 1,
    billing_city: "",
    billing_zip: "",
    customer_email: "",
    customer_phone: "",
    billing_country: "",
    products: [{ product_id: "", qty: "", unit_price: "", line_total: "" }],
    sub_total: "",
    discount: "",
    payment_method: "",
  });
  const [paymentMethod, setPaymentMethod] = useState("");
  useEffect(() => {
    axios
      .post(
        `${url}/api/v1/shipping-methods`,
        { cart_total: totalAmount },
        config
      )
      .then((res) => {
        setShippings(res.data);
      })
      .catch((err) => console.log(err.message));

    axios
      .get(`${url}/api/v1/user/addresses`, config)
      .then((res) => {
        setUserAddress(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });

    axios
      .post(`${url}/api/v1/payment-methods`, [], config)
      .then((res) => {
        setPayments(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });

    setData({
      ...data,
      products: cart.map((item) => {
        return {
          product_id: item.id,
          qty: item.quantity,
          unit_price: parseInt(item.selling_price.amount),
          line_total: parseInt(item.selling_price.amount) * item.quantity,
        };
      }),
    });
  }, [cart]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const myData = {
      billing_first_name: data.billing_first_name,
      billing_last_name: data.billing_last_name,
      billing_address_1: parseInt(data.billing_address_1),
      billing_city: data.billing_city,
      billing_zip: data.billing_zip,
      billing_country: data.billing_country,
      customer_email: data.customer_email,
      customer_phone: data.customer_phone,
      products: data.products,
      payment_method: paymentMethod,
      sub_total: totalAmount,
      discount: discount,
      shipping_method: shippingMethod,
      shipping_cost: shippingCost,
      shipping_address_id: parseInt(data.billing_address_1),
      total:
        parseInt(totalAmount) + parseInt(shippingCost) - parseInt(discount),
    };

    axios
      .post(`${url}/api/v1/orders`, myData, config)
      .then((res) => {
        e.target.reset();
        toast.success("Order created Successfully", { autoClose: 2000 });
        setChecked(false);
        setPaymentChecked(false);
        setAddressChecked(false);
        setShippingCost(0);
        setPaymentMethod("");
        setDiscount(0)
      })
      .catch((err) => {
        console.log(err.message);
        toast.error("Fill up all credentials that are required", { autoClose: 2000 });
      });
  };

  return (
    <>
      <div className="checkout-area " style={{ marginBottom: "70px" }}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <form onSubmit={handleFormSubmit}>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-12">
                    <div className="checkbox-form">
                      <h3>Billing Details</h3>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 ">
                          <div className="checkout-form-list">
                            <label>
                              <strong>First Name</strong>{" "}
                              <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              placeholder=""
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  billing_first_name: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="checkout-form-list">
                            <label>
                              <strong>Last Name </strong>
                              <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              placeholder=""
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  billing_last_name: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-12">
                          <div className="checkout-form-list">
                            <div className="country-select">
                              <label>
                                <strong>Address</strong>{" "}
                                <span className="required">*</span>
                              </label>
                          
                             
                              {address.map((item) => (
                                <div className="form-check" key={item.id}>
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault1"
                                    style={{ height: "15px" }}
                                    defaultChecked={
                                      addressChecked ? "checked" : ""
                                    }
                                    value={item.id}
                                    onClick={(e) => {
                                      setAddressChecked(true);
                                      setData({
                                        ...data,
                                        billing_address_1: e.target.value,
                                      });
                                      
                                    }}
                                    
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadioDefault1"
                                  >
                                    {item.address_1},{item.city},{item.zip}
                                  </label>
                                </div>
                              ))}

                              
                            </div>
                          </div>
                        </div>

                      
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="checkout-form-list">
                            <label>
                              <strong>Email Address </strong>
                              <span className="required">*</span>
                            </label>
                            <input
                              type="email"
                              placeholder=""
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  customer_email: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <div className="checkout-form-list">
                            <label>
                              <strong>Phone</strong>{" "}
                              <span className="required">*</span>
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  customer_phone: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12">
                    <div className="your-order">
                      <h3>Your order</h3>
                      <div className="your-order-table table-responsive">
                        <table>
                          <thead>
                            <tr>
                              <th className="product-name">Product</th>
                              <th className="product-total">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cart ? (
                              <>
                                {cart.map((item, index) => (
                                  <tr className="cart_item" key={index}>
                                    <td className="product-name">
                                      {item.name}{" "}
                                      <strong className="product-quantity">
                                        {" "}
                                        × {item.quantity}
                                      </strong>
                                    </td>
                                    <td className="product-total">
                                      <span className="amount">
                                        Tk.{" "}
                                        {parseFloat(
                                          item.quantity *
                                            item.special_price.amount
                                        ).toFixed(2)}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </>
                            ) : (
                              ""
                            )}
                          </tbody>
                          <tfoot>
                            <tr className="cart-subtotal">
                              <th>Cart Subtotal</th>
                              <td>
                                <span className="amount">Tk {totalAmount}</span>
                              </td>
                            </tr>
                            <tr className="cart-subtotal">
                              <th>Discount</th>
                              <td>
                                <span className="amount">Tk {discount}</span>
                              </td>
                            </tr>
                            <tr className="shipping">
                              <th>Shipping <span style={{color:"red"}}>*</span></th>
                              <td>
                                <ul>
                                  
                                  {shippings.map((item, index) => (
                                    <li key={index}>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault2"
                                        id="flexRadioDefault12"
                                        defaultChecked={checked?"checked":""}
                                        onClick={
                                          !checked
                                            ? () => {
                                                setShippingCost(
                                                  item.shipping_cost
                                                );
                                                setShippingMethod(
                                                  item.shipping_method
                                                );
                                              }
                                            : () => {
                                                setShippingCost(0);
                                                setShippingMethod("");
                                              }
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="flexRadioDefault1"
                                      >
                                         {item.name}:{" "}
                                        <span className="amount">
                                          TK. {item.shipping_cost}
                                        </span>
                                      </label>
                                    </div>
                                    </li>
                                  ))}

                                </ul>
                              </td>
                            </tr>
                            <tr className="order-total">
                              <th>Order Total</th>
                              <td>
                                <strong>
                                  <span className="amount">
                                    TK{" "}
                                    {shippingCost || discount
                                      ? parseInt(totalAmount) +
                                        parseInt(shippingCost) -
                                        parseInt(discount)
                                      : parseInt(totalAmount)}
                                  </span>
                                </strong>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                      <div className="payment-method">
                        <div className="payment-accordion">
                          <div className="collapses-group">
                            <div
                              className="panel-group"
                              id="accordion"
                              role="tablist"
                              aria-multiselectable="true"
                            >
                              {payments.map((item, index) => (
                                <div
                                  className="panel panel-default"
                                  key={index}
                                >
                                  <div
                                    className="panel-heading"
                                    role="tab"
                                    id="headingOne"
                                  >
                                    <h4 className="panel-title">
                                      <input
                                        type="checkbox"
                                        defaultChecked={paymentChecked}
                                        onChange={
                                          !paymentChecked
                                            ? () => setPaymentChecked(true)
                                            : () => setPaymentChecked(false)
                                        }
                                        onClick={
                                          !paymentChecked
                                            ? () =>
                                                setPaymentMethod(
                                                  item.payment_method
                                                )
                                            : () => setPaymentMethod("")
                                        }
                                      />
                                      <a
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        href="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                      >
                                        {item.name}
                                      </a>
                                    </h4>
                                  </div>
                                  <div
                                    id="collapseOne"
                                    className="panel-collapse collapse in"
                                    role="tabpanel"
                                    aria-labelledby="headingOne"
                                  >
                                    <div className="panel-body">
                                      <p>{item.description}</p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="order-button-payment">
                          <input type="submit" value="Place order" disabled={!paymentMethod && !shippingCost ?"disabled":""} style={{color:!paymentMethod && !shippingCost?"black":"#fff",background:!paymentMethod && !shippingCost?"white":"#259030"}}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <CheckoutShipping setUserAddress={setUserAddress} />
        </div>
      </div>
    </>
  );
};

export default CheckOutArea;
