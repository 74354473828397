import React, { useState, useEffect, Fragment } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import Pagination from "./Pagination";
import SingleGridBook from "./SingleGridBook";
import { ScaleLoader } from "react-spinners";
import { url } from "../Services/Url";
import Pagination2 from "./Pagination2";
import Breadcumbs from "./Breadcumbs";
const TabBook2 = () => {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [Allbooks, SetAllBooks] = useState([]);

  const loaderStyle = { textAlign: "center" };
  const fetchBooks = async (currentPage) => {
    setLoading(true);
    await axios
      .get(`${url}/api/v1/products?page=${currentPage}`)
      .then((res) => {
        console.log(res.data.products);
        setBooks(res.data.products);
        SetAllBooks(res.data);
        // window.scrollTo(0, 0);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    fetchBooks(currentPage);
  }, [currentPage]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const prevPage = () => setCurrentPage(currentPage - 1);
  const nextPage = () => setCurrentPage(currentPage + 1);

  return (
    <>
      <div className="col-lg-9 col-md-12 col-12 order-lg-2 order-1">
        <div className="category-image" style={{ marginBottom: "30px" }}>
          <Link to="#">
            <img
              src="/img/Boi-Bazaar/Header-for-book-list-page.jpg"
              alt="banner"
              style={{ width: "100%" }}
            />
          </Link>
        </div>
        <div className="section-title-5" style={{ marginBottom: "30px" }}>
          <h2>Book</h2>
        </div>
        <Breadcumbs />
        {loading ? (
          <div style={loaderStyle}>
            <ScaleLoader color="#24902f" />
          </div>
        ) : (
          <>
            <div className="row">
              {books.map((item, index) => (
                <Fragment key={index}>
                  <SingleGridBook key={index} book={item} loading={loading} />
                </Fragment>
              ))}
            </div>
            <Pagination2
              firstPage={Allbooks.first_page}
              lastPage={Allbooks.last_page}
              loading={loading}
              currentPage={currentPage}
              paginate={paginate}
              nextPage={nextPage}
              prevPage={prevPage}
            />
          </>
        )}
      </div>
    </>
  );
};

export default TabBook2;
