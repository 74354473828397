import React, { Fragment, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../Context/AuthState";
// import BookSlide from "./BookSlide";
import { CartContext } from "../Context/GlobalContext/GlobalState";
const SingleGridBook = (props) => {
  const { cart, addToCart } = useContext(CartContext);
  const { createWishList, state } = useContext(AuthContext);
  const history = useHistory();

  const redirect = () => {
    history.push("/login");
  };

  return (
    <>
      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
        {/* single-product-start */}
        <div className="product-wrapper " style={{ marginBottom: "40px" }}>
          <div
            className="product-img"
            style={{ marginLeft: 42, marginRight: 5, position: "relative" }}
          >
            <div className="carousel-image">
              <Link to={{ pathname: `/product-details/${props.book.slug}` }}>
                <img
                  src={
                    props.book.files.length
                      ? props.book.base_image.path
                      : "/img/product/1.jpg"
                  }
                  alt="book"
                  className="primary new-properties"
                />
              </Link>
            </div>
            <div className="quick-view">
              <Link
                className="action-view"
                to="#"
                data-target="#productModal"
                data-toggle="modal"
                title="Quick View"
              >
                <i className="fal fa-eye"></i>
              </Link>
            </div>
            <div className="quick-view2">
              <Link
                className="action-view"
                to="#"
                data-target="#productModal"
                data-toggle="modal"
                title="WishList"
                onClick={
                  state.isAuthenticated
                    ? () => createWishList(props.book.id)
                    : () => redirect()
                }
              >
                <i className="fal fa-heart"></i>
              </Link>
            </div>

            <div className="quick-view3">
              <Link
                className="action-view"
                to="#"
                data-target="#productModal"
                data-toggle="modal"
                title="Add To Cart"
                onClick={() => addToCart(props.book)}
              >
                <i className="fal fa-shopping-cart"></i>
              </Link>
            </div>

            <div className="product-flag">
              <ul>
                <li>
                  <span className="sale">new</span>
                </li>
                <li>
                  <span className="discount-percentage">-5%</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="product-details " style={{ marginLeft: 42 }}>
            <h4>
              <Link to={{ pathname: `/product-details/${props.book.slug}` }}>
                {props.book.name}
              </Link>
            </h4>
            {props.book.writers.length > 1 ? (
              props.book.writers.map((item, index) => (
                <Fragment key={index}>
                  <span
                    style={{
                      fontWeight: "500",
                      display: "inline",
                    }}
                    key={index}
                  >
                    <Link
                      to="#"
                      style={{ textDecoration: "none", color: "#333" }}
                      className="book-author-link"
                    >
                      {" "}
                      {item.name}
                    </Link>
                  </span>
                </Fragment>
              ))
            ) : (
              <span
                style={{ fontWeight: "500", display: "inline", color: "#333" }}
              >
                {props.book.writers[0].name}
              </span>
            )}

            <div className="product-price">
              <ul>
                <li>
                  TK. {parseFloat(props.book.special_price.amount).toFixed(2)}{" "}
                </li>
                <li className="old-price">
                  Tk. {parseFloat(props.book.price.amount).toFixed(2)}{" "}
                </li>
              </ul>
            </div>
          </div>
          <div className="product-link">
            <div className="product-button">
              <Link
                to="#"
                title="Add to cart"
                style={{ textDecoration: "none" }}
                onClick={() => addToCart(props.book)}
              >
                <i className="fa fa-shopping-cart"></i>Add to cart
              </Link>
            </div>
            <div className="add-to-link">
              <ul>
                <li>
                  <Link to="#">
                    <i className="fas fa-external-link-alt"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* single-product-end */}
      </div>
    </>
  );
};

export default SingleGridBook;
