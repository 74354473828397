import React,{useState,useEffect,useContext, Fragment} from 'react'
import {Link} from "react-router-dom"
import {url} from "../../Services/Url"
import axios from "axios"
import { ScaleLoader } from "react-spinners";
import { AuthContext } from '../../Context/AuthState'

import "./Profile.css"
const ProfileOrderDetails = ({id,setShowOrders,setClicking}) => {
  const [SingleOrder,setSingleOrder]=useState({order:[],products:[]})
  const [loading,setLoading]=useState(false)
  const {state} = useContext(AuthContext)
  const back = ()=>{
    setShowOrders(false)
    setClicking(false)
  }
  const loaderStyle = { textAlign: "center" };
  const config = {
    headers:{Authorization:`Bearer ${state.token}`}
  }
  useEffect(()=>{
    setLoading(true)
      axios.get(`${url}/api/v1/orders/${id}`,config)
      .then((res)=>
          setSingleOrder({order:res.data.order,products:res.data.products.data}),
          setLoading(false)
      )
  },[])



    return (
      <>
        <div className="row">
          <div className="col-sm-4">
            <div className="card" style={{ width: "14rem" }}>
              <div className="card-body">
                <h5 className="card-title">Delivery Address</h5>
                <h6 className="card-subtitle mb-2">
                  <strong>Home:</strong>
                </h6>
                <p
                  className="card-text"
                  style={{ fontSize: "14px", display: "block" }}
                >
                  {SingleOrder.order.shipping_address_1}
                </p>
                <p className="card-text" style={{ fontSize: "14px" }}>
                  {SingleOrder.order.shipping_city},
                  {SingleOrder.order.shipping_zip}
                </p>
                <span style={{ fontSize: "14px", lineHeight: "1rem" }}>
                  <strong>Phone: </strong>
                  {SingleOrder.order.customer_phone}
                </span>
              </div>
            </div>
          </div>
          <div className="col-sm-8">
            <div className="card" style={{ width: "30rem" }}>
              <div className="card-body">
                <h5 className="card-title">Order Summary</h5>
                <table className="table">
                  <tbody>
                    {loading ? (
                      <>
                        <div style={loaderStyle}>
                          <ScaleLoader color="#24902f" />
                        </div>
                      </>
                    ) : (
                      <>
                        {/* {SingleOrder.products.map((data)=><p>{data.product.id}</p>)} */}
                        {SingleOrder.products.map((data, index) => (
                          <>
                            <tr key={index}>
                              <td style={{ borderTop: "none" }}>
                                <img
                                  src="/img/product/1.jpg"
                                  height="80px"
                                  alt=""
                                />
                              </td>
                              <td style={{ borderTop: "none" }}>
                                {data.product.name}
                              </td>
                              <td
                                style={{
                                  borderTop: "none",
                                  textAlign: "right",
                                }}
                              >
                                <p>
                                  Qty:<strong> {data.qty}</strong>
                                </p>
                              </td>
                              <td
                                style={{
                                  borderTop: "none",
                                  textAlign: "right",
                                }}
                              >
                                <p>
                                  Price:
                                  <strong>
                                    {" "}
                                    {parseFloat(
                                      data.product.special_price.amount
                                    )}{" "}
                                    Tk.
                                  </strong>
                                </p>
                              </td>
                            </tr>
                          </>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
                <hr />

                <div className="row">
                  <div className="col-sm-3"></div>
                  <div className="col-sm-9">
                    <table className="table mytable">
                      <tbody>
                        <tr style={{ lineHeight: "0px" }}>
                          <th>Total Items</th>
                          <td style={{ textAlign: "right" }}>
                            {SingleOrder.products.reduce(
                              (n, { qty }) => n + qty,
                              0
                            )}
                          </td>
                        </tr>
                        <tr style={{ lineHeight: "0px" }}>
                          <th>Sub Total</th>
                          <td style={{ textAlign: "right" }}>
                            Tk.{" "}
                            {SingleOrder.order.sub_total
                              ? parseFloat(
                                  SingleOrder.order.sub_total.amount
                                ).toFixed(2)
                              : ""}
                          </td>
                        </tr>
                        <tr style={{ lineHeight: "0px" }}>
                          <th>Shipping</th>
                          <td style={{ textAlign: "right" }}>
                            Tk.{" "}
                            {SingleOrder.order.shipping_cost
                              ? parseFloat(
                                  SingleOrder.order.shipping_cost.amount
                                ).toFixed(2)
                              : ""}
                          </td>
                        </tr>
                        <tr style={{ lineHeight: "0px" }}>
                          <th>Discount</th>
                          <td style={{ textAlign: "right" }}>
                            Tk.{" "}
                            {SingleOrder.order.discount
                              ? parseFloat(
                                  SingleOrder.order.discount.amount
                                ).toFixed(2)
                              : ""}
                          </td>
                        </tr>
                        <hr />
                        <tr style={{ lineHeight: "0px" }}>
                          <th>Payable Amount</th>
                          <td style={{ textAlign: "right" }}>
                            Tk.{" "}
                            {SingleOrder.order.sub_total
                              ? parseInt(SingleOrder.order.sub_total.amount) +
                              parseInt(SingleOrder.order.shipping_cost.amount)-
                              parseInt( SingleOrder.order.discount.amount)
                              : ""}
                          </td>
                        </tr>

                        <tr>
                          {" "}
                          <td className="text-secondary">
                            Paid by{" "}
                            <span
                              style={{
                                fontSize: "10px",
                                textTransform: "uppercase",
                              }}
                            >
                              {SingleOrder.order.payment_method
                                ? SingleOrder.order.payment_method
                                : ""}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* ))} */}
              </div>
            </div>
          </div>
        </div>
        <Link to="#" className="btn btn-sqr mt-2" onClick={() => back()}>
          Back
        </Link>
      </>
    );
}

export default ProfileOrderDetails
