import axios from 'axios';
import React from 'react'
import {Link} from "react-router-dom"
import {url, local} from "../../../Services/Url"

class BlogRecentPosts extends React.Component {

	constructor(){
		super();
		this.state = {
			tags: []
		}
	}

	componentDidMount() {
		axios.get(`${local}/blogs/sidebar/recents`)
		.then(response => {
			this.setState({
				tags: response.data
			})
		})
	}

	render() {
		return (
			<>
				<div className="single-blog" style={{marginBottom:"50px"}}>
					<div className="blog-left-title">
						<h3>Recent Posts</h3>
					</div>
					<div className="blog-side-menu">
						<ul>
							{this.state.tags.map((item, index) => (
								<li key={index}><Link to={`/blog-details/${item.slug}`}>{item.name}</Link></li>
							))}
						</ul>
					</div>
				</div>
			</>
		)
	}
}

export default BlogRecentPosts
