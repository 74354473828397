import React, { Children } from 'react';
import { Link } from "react-router-dom";
import { url } from '../Services/LinkGenerator';


const NavMenuChildren = props => {

    return (
        <>
            <div className="mega-menu mega-menu-2" style={{zIndex: 999999}}>
                {props.children.map((item, index) => (
                    <span key={index}>
                        <Link
                            key={item.id}
                            to={{
                                pathname: url({type: item.type, url: item.url, item}),
                            }}
                           
                        >
                            {item.name}
                        </Link>
                    </span>
                ))}
            </div>
        </>
    );
}

export default NavMenuChildren;
