import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
const LatestBlog = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows:false,
          autoplay:true,
          // initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows:false,
          autoplay:true,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows:false,
          autoplay:true,
        },
      },
    ],
  };
  return (
    <>
      <div
        className="recent-post-area"
        style={{ paddingTop: "95px", paddingBottom: "100px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center  section-title-res"
                style={{ marginBottom: "30px" }}
              >
                <h2>Latest from our blog</h2>
              </div>
            </div>

            <div className="col-lg-12">
              <Slider {...settings}>
                <div>
                  <div className="single-post">
                    <div className="post-img">
                      <Link style={{ textDecoration: "none" }} to="#">
                        <img src="img/Boi-Bazaar/Blog_Image_1.jpg" alt="post" />
                      </Link>
                      <div className="blog-date-time">
                        <span className="day-time">06</span>
                        <span className="moth-time">Dec</span>
                      </div>
                    </div>
                    <div className="post-content">
                      <h3>
                        <Link style={{ textDecoration: "none" }} to="#">
                          ‘বঙ্গবন্ধুর শিক্ষা ভাবনা আগামী প্রজন্মকে দিকনির্দেশনা
                          দেবে’{" "}
                        </Link>
                      </h3>
                      <span className="meta-author"> আনিসুর রহমান</span>
                      <p>
                        বঙ্গবন্ধুর শিক্ষা ভাবনা নিয়ে কাজ করলেই কেবল আগামী
                        প্রজন্ম সঠিক দিক নির্দেশনা পাবে বলে উল্লেখ্য করেছেন ঢাকা
                        বিশ্ববিদ্যালয় (ঢাবি) উপ-উপাচার্য (প্রশাসন) ড. মুহাম্মদ
                        সামাদ।
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="single-post">
                    <div className="post-img">
                      <Link style={{ textDecoration: "none" }} to="#">
                        <img src="img/Boi-Bazaar/Blog_Image_2.jpg" alt="post" />
                      </Link>
                      <div className="blog-date-time">
                        <span className="day-time">06</span>
                        <span className="moth-time">Dec</span>
                      </div>
                    </div>
                    <div className="post-content">
                      <h3>
                        <Link style={{ textDecoration: "none" }} to="#">
                          ১ সেপ্টেম্বর থেকে সারা দেশে একই দামে ইন্টারনেট
                        </Link>
                      </h3>
                      <span className="meta-author"> নজরুল ইসলাম </span>
                      <p>
                        আগামী ১ সেপ্টেম্বর থেকে সারা দেশে ইন্টারনেট সেবা ‘এক দেশ
                        এক রেট’ চালু হবে। পুরো দেশে একই দামে ব্রডব্যান্ডের
                        ইন্টারনেট পাওয়া যাবে। শহর-গ্রাম সব জায়গায়ই এক রেট
                        নির্ধারণ করা হয়েছে।
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="single-post">
                    <div className="post-img">
                      <Link style={{ textDecoration: "none" }} to="#">
                        <img src="img/Boi-Bazaar/Blog_Image_3.jpg" alt="post" />
                      </Link>
                      <div className="blog-date-time">
                        <span className="day-time">07</span>
                        <span className="moth-time">Dec</span>
                      </div>
                    </div>
                    <div className="post-content">
                      <h3>
                        <Link style={{ textDecoration: "none" }} to="#">
                          কৃতজ্ঞতা সমাচার আমাদের সংস্কৃতির বিবেচ্য বিষয়
                        </Link>
                      </h3>
                      <span className="meta-author"> শোভন সাহা </span>
                      <p>
                        "কৃতজ্ঞতা স্বীকার"- এই সহজ বিষয়টি এখনো আমাদের সংস্কৃতির
                        বিবেচ্য বিষয় হয়ে উঠে নি। অদৃশ্য কোন সৃষ্টিকর্তার কাছে কত
                        উপায়েই না আমরা কৃতজ্ঞতা স্বীকার করি অথচ দৃশ্যমান মানব
                        সমাজে পারস্পরিক কৃতজ্ঞতার প্রকাশ প্রায়ই অনুপস্থিত।
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="single-post">
                    <div className="post-img">
                      <Link style={{ textDecoration: "none" }} to="#">
                        <img src="img/Boi-Bazaar/Blog_Image_2.jpg" alt="post" />
                      </Link>
                      <div className="blog-date-time">
                        <span className="day-time">08</span>
                        <span className="moth-time">Dec</span>
                      </div>
                    </div>
                    <div className="post-content">
                      <h3>
                        <Link style={{ textDecoration: "none" }} to="#">
                          মাটি- মহাকাশের সঙ্গে জীবনের যোগ খুজতে
                        </Link>
                      </h3>
                      <span className="meta-author"> জামাল নজরুল ইসলাম </span>
                      <p>
                        ১৯৮৮ সালের কথা। কৃষ্ণবিবর বা ব্লাকহোল নিয়ে ভীষণ আগ্রহ। এ
                        সম্পর্কে টুকরো টুকরো লেখা পড়েছি। দ্রুত কোনো কিছু পাওয়ার
                        জন্য ইন্টারনেট ব্যবস্থা ছিল না তখন। ব্রিটিশ কাউন্সিল,
                        জার্মান সাংস্কৃতিক কেন্দ্র ....
                      </p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LatestBlog;
