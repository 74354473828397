import React from "react";

const HighlightedBooksTab = ({data}) => {
    const newData1 = data? data.tab_one:"";
    const newData2 = data? data.tab_two:"";
    const newData3 = data? data.tab_three:"";
    const title1 = newData1?newData1.title:"";
    const title2 = newData2?newData2.title:"";
    const title3 = newData3?newData3.title:"";
   
  return (
    <>
      <div className="col-lg-12">
        {/* tab-menu-start */}
        <div className="tab-menu text-center" style={{ marginBottom: "40px" }}>
          <ul className="nav justify-content-center">
            <li>
              <a className="active" href="#Audiobooks" data-toggle="tab">
                {title1}{" "}
              </a>
            </li>
            <li>
              <a href="#books" data-toggle="tab">
              {title2}{" "}
              </a>
            </li>
            <li>
              <a href="#bussiness" data-toggle="tab">
              {title3}{" "}
              </a>
            </li>
          </ul>
        </div>
        {/*  tab-menu-end */}
      </div>
    </>
  );
};

export default HighlightedBooksTab;
