import React from "react";
import { Link } from "react-router-dom";

const HeaderTopBar = () => {
  return (
    <>
      <div className="header-top-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12"></div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="account-area">
                <ul style={{ textAlign: "right" }}>
                  <li>
                    <Link to="/profile">My Account</Link>
                  </li>
                  <li>
                    <Link to="/checkout">Checkout</Link>
                  </li>
                  <li>
                    <Link to="/login">Sign in</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderTopBar;
