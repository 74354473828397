import React, { Fragment, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CartContext } from "../Context/GlobalContext/GlobalState";
import { AuthContext } from "../Context/AuthState";


const Test = ({ data }) => {
  
  const {  addToCart } = useContext(CartContext);
  const { createWishList, state } = useContext(AuthContext);


  const history = useHistory();
  const redirect = () => {
    history.push('/login')
  }


const title = data ? data.title:""
const products = data ? data.products: []

  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrow: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>

      <div className="single-category-slider mb-5 ">
        <div className="container">
          <div className="row" style={{ marginBottom: 40 }}>
            <h5 style={{ fontWeight: "bold", borderBottom: "1px solid #333" }} className="ml-4">
            {title ? title : ""}
            </h5>
          </div>
         
          {products.length && (<Slider {...settings}>
              {products.map((item,index) => (
               
                  <div className="product-wrapper" key={index}>
                    <div className="product-img" style={{ marginLeft: 42, marginRight: 42, position: "relative", }}>
                      <div className="carousel-image">
                        <Link to={{ pathname: `/product-details/${item.slug}` }}
                         

                        >
                          <img src={
                            item.files.length
                              ? item.base_image.path
                              : "/img/product/1.jpg"
                          } alt="book" className="primary new-properties" />
                        </Link>

                      </div>
                      <div className="quick-view">
                        <Link
                          className="action-view"
                          to="#"
                          data-target="#productModal"
                          data-toggle="modal"
                          title="Quick View"
                        >
                          <i className="fal fa-eye"></i>
                        </Link>
                      </div>
                      <div className="quick-view2">
                        <Link
                          className="action-view"
                          to="#"
                          data-target="#productModal"
                          data-toggle="modal"
                          title="WishList"
                          onClick={state.isAuthenticated ? () => createWishList(item.id) : () => redirect()}
                        >
                          <i className="fal fa-heart"></i>
                        </Link>
                      </div>

                      <div className="quick-view3">
                        <Link
                          className="action-view"
                          to="#"
                          data-target="#productModal"
                          data-toggle="modal"
                          title="Add To Cart"
                          onClick={() => addToCart(item)}
                        >
                          <i className="fal fa-shopping-cart"></i>
                        </Link>
                      </div>
                      <div className="product-flag" style={{ position: "absolute" }}>
                        <ul>
                          <li>
                            <span className="sale">new</span>
                          </li>
                          <li>
                            <span className="discount-percentage">-5%</span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="product-details" style={{ marginLeft: 42 }}>
                      <h4 className=""><Link to={{ pathname: `/product-details/${item.slug}` }}
                       >{item.name}</Link>
                      </h4>
                      {item.writers.length > 1 ? (
                        item.writers.map((item, index) => (
                          <Fragment key={index}>
                            <span
                              style={{
                                fontWeight: "500",
                                display: "inline",
                              }}
                              key={item.id}
                            >
                              <Link
                                to="#"
                                style={{ textDecoration: "none", color: "#333" }}
                                className="book-author-link"
                              >
                                {" "}
                                {item.name}
                              </Link>
                            </span>
                          </Fragment>
                        ))
                      ) : (
                        <span
                          style={{ fontWeight: "500", display: "inline", color: "#333" }}
                          className=""
                        >
                          {item.writers[0].name}
                        </span>
                      )}

                      <div className="product-price">
                        <ul>
                          <li>
                            TK. {parseFloat(item.special_price.amount).toFixed(2)}{" "}
                          </li>
                          <li className="old-price">
                            Tk. {parseFloat(item.price.amount).toFixed(2)}{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
               
              ))}

            </Slider>
            )}
        </div>
      </div>




    </>

  );
};

export default Test;

