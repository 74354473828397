import React,{useState,useEffect,useContext} from "react";

const ProfileDownloads = () => {
  return (
    <>
      <div className="tab-pane fade" id="download" role="tabpanel">
        <div className="myaccount-content">
          <h5>Downloads</h5>
          {/* <div className="myaccount-table table-responsive text-center">
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th>Product</th>
                  <th>Date</th>
                  <th>Expire</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Haven - Free Real Estate PSD Template</td>
                  <td>Aug 22, 2018</td>
                  <td>Yes</td>
                  <td>
                    <a href="#" className="btn btn-sqr">
                      <i className="fa fa-cloud-download"></i>
                      Download File
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>HasTech - Profolio Business Template</td>
                  <td>Sep 12, 2018</td>
                  <td>Never</td>
                  <td>
                    <a href="#" className="btn btn-sqr">
                      <i className="fa fa-cloud-download"></i>
                      Download File
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}
          <div className="card">
            <div className="card-body">
                No downloads are available
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileDownloads;
