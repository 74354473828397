import React from "react";
import Breadcumbs from "../Components/Breadcumbs";

import axios from "axios";
import { local } from "../Services/Url";


class Contact extends React.Component {
  
  constructor() {
    super();

    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
      errors: {},
      isLoading: false,
      isDone: false
    }
  }


  submitForm = (e) => {


    this.setState({
      isLoading: true
    })

    e.preventDefault();
    let data = {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      phone: this.state.phone,
      subject: this.state.subject,
      message: this.state.message,
    };
    axios.post(`${local}/contact`, data)
    .then(response => {
      console.log(response);
      this.setState({
        isLoading: false,
        isDone: true,
      })
  
    })
    .catch(error => {
      if(error.response.status === 422){
        this.setState({
          errors: error.response.data.errors,
          isLoading: false
        })
      }
    })
  }

  render() {

    return (
      <>
  
        <Breadcumbs />
        <div
          className="user-login-area mb-70 mt-5"
          style={{ marginBottom: "70px" }}
        >
          <div className="container">
            {(this.state.isDone) ? (
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="login-title text-center mb-30"
                    style={{ marginBottom: "30px" }}
                  >
                    <h2>{`Thank you for contact with us.`}</h2>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
              <div className="col-lg-12">
                <div
                  className="login-title text-center mb-30"
                  style={{ marginBottom: "30px" }}
                >
                  <h2>Contact Us</h2>
                  <p>
                    <i>
                      <span
                        style={{ fontSize: "14px" }}
                        className="text-secondary"
                      >
                        For all enqueries, please email us using the form below
                      </span>
                    </i>
                  </p>
                </div>
              </div>
              <div className="offset-lg-2 col-lg-8 col-md-12 col-12">
              <form onSubmit={(e) => this.submitForm(e)}>
                <div className="billing-fields">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="single-register">
                          <label>First Name</label>
                          <input onChange={(e) => this.setState({ firstname: e.target.value })} value={this.state.firstname} type="text" required/>
                          <p className="text-danger">{this.state.errors.firstname}</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="single-register">
                          <label>Last Name</label>
                          <input onChange={(e) => this.setState({ lastname: e.target.value })} value={this.state.lastname} type="text" required/>
                          <p className="text-danger">{this.state.errors.lastname}</p>
                      </div>
                    </div>
                  </div>
  
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="single-register">
                          <label>Email Address</label>
                          <input onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} type="email" required/>
                          <p className="text-danger">{this.state.errors.email}</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="single-register">
                          <label>Phone</label>
                          <input onChange={(e) => this.setState({ phone: e.target.value })} value={this.state.phone} type="text" required/>
                          <p className="text-danger">{this.state.errors.phone}</p>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="single-register">
                          <label>Subject</label>
                          <input onChange={(e) => this.setState({ subject: e.target.value })} value={this.state.subject} type="text" required/>
                          <p className="text-danger">{this.state.errors.subject}</p>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="single-register">
                          <label>Message</label>
                          <textarea
                            class="form-control"
                            id="exampleFormControlTextarea3"
                            rows="7"
                            onChange={(e) => this.setState({ message: e.target.value })}
                            required
                          >{this.state.message}</textarea>
                          <p className="text-danger">{this.state.errors.message}</p>
                      </div>
                    </div>
                  </div>
  
                  <div className="single-post-button">
                    {(this.state.isLoading) ? (
                      <button type="submit" disabled>SAVE</button>
                    ) : (
                      <button type="submit">SAVE</button>
                    )}
                  </div>
                </div>
              </form>
              </div>
            </div>
            )}
            
          </div>
        </div>

      </>
    );
  }

};

export default Contact;
