import React from "react";
import { Link } from "react-router-dom";
import BookLists from "../Components/BookLists";
import "./LeftFilterBookList.css";
import { Data } from "../Services/Data";
const LeftFilterBookList = () => {
  const divStyle = {
    overflowY: "scroll",
    height: "315px",
    position: "relative",
    fontFamily: "Hind Siliguri",
  };
  const decorationnone = {
    textDecoration: "none",
    fontFamily: "Hind Siliguri",
  };

  const relatedProducts = Data.slice(23, 26).map((item, index) => item);
  const relatedProducts2 = Data.slice(26, 29).map((item, index) => item);
  return (
    <>
      <div className="col-lg-3 col-md-12 col-12 order-lg-1 order-2 mt-sm-50 mt-xs-40">
        <div className="shop-left">
          <div className="card" style={{ borderRadius: "0%" }}>
            <div
              className="card-body justify-content-between"
              style={{
                padding: "0.7rem",
                borderBottom: "1px solid #b3b3b3",
              }}
            >
              <h4>
                {" "}
                <i className="fas fa-sort"> </i> Sort
              </h4>
            </div>
          </div>
          <div className="card" style={{ marginBottom: "20px" }}>
            <div className="card-body">
              {/* radio buttons*/}
              <div className="form-check">
                <input
                  className="form-check-input "
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  style={{ cursor: "pointer" }}
                />
                <label
                  className="form-check-label"
                  for="flexRadioDefault1"
                  style={{ cursor: "pointer" }}
                >
                  Best Seller
                </label>
              </div>
              <div className="form-check mt-1">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                />
                <label
                  className="form-check-label"
                  for="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                >
                  New Released
                </label>
              </div>
              <div className="form-check mt-1">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                />
                <label
                  className="form-check-label"
                  for="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                >
                  Price - Low to High
                </label>
              </div>
              <div className="form-check mt-1">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                />
                <label
                  className="form-check-label"
                  for="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                >
                  Price - High to Low
                </label>
              </div>
              <div className="form-check mt-1">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                />
                <label
                  className="form-check-label"
                  for="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                >
                  Discount- Low to High
                </label>
              </div>
              <div className="form-check mt-1">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                />
                <label
                  className="form-check-label"
                  for="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                >
                  Discount - High to Low
                </label>
              </div>
              {/* radio buttons end*/}
            </div>
          </div>
          <div className="card" style={{ borderRadius: "0%" }}>
            <div
              className="card-body justify-content-between"
              style={{
                padding: "0.7rem",
                borderBottom: "1px solid #b3b3b3",
              }}
            >
              <div className="left-title">
                {" "}
                <h4>
                  <i className="fas fa-filter"> </i> Filter
                </h4>
              </div>
            </div>
          </div>
          <div className="card" style={{ borderRadius: "0%" }}>
            <div
              className="card-body justify-content-between"
              style={{
                padding: "0.7rem",
                borderBottom: "1px solid #b3b3b3",
              }}
            >
              <div className="left-title" style={{ marginBottom: "20px" }}>
                <h4>
                  Category <i className="fas fa-arrow-down"></i>
                </h4>
              </div>
              <div className="left-menu" style={divStyle}>
                <ul style={{ paddingLeft: "30px" }}>
                  <li style={{ listStyle: "circle" }}>
                    <Link to="#" style={decorationnone}>
                      সমকালীন উপন্যাস
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      তুর্কি ক্যাপ
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      উপন্যাস সমগ্র
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      উপন্যাস সংকলন ও সমগ্র
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      রচনা সংকলন ও সমগ্র
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      সাহিত্যিক, শিল্প ও সংগীত ব্যক্তিত্ব
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    <Link to="#" style={decorationnone}>
                      রোমান্টিক উপন্যাস
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      অতিপ্রাকৃত ও ভৌতিক উপন্যাস
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      শিশু-কিশোর গল্প
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      সায়েন্স ফিকশন
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      শিশু-কিশোর উপন্যাস
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      গল্প সংকলন ও সমগ্র
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      নানাদেশ ও ভ্রমণ
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link style={decorationnone} to="#">
                      সায়েন্স ফিকশন সমগ্র/সংকলন
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      মুক্তিযুদ্ধের উপন্যাস
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      রাজনৈতিক ও মুক্তিযুদ্ধভিত্তিক উপন্যাস
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      অতিপ্রাকৃত ও ভৌতিক
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      শিশু-কিশোর সাহিত্য/রচনা সমগ্র
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      সমকালীন গল্প
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Publishers */}
          <div
            className="card"
            style={{ borderRadius: "0%", marginTop: "5px" }}
          >
            <div
              className="card-body justify-content-between"
              style={{
                padding: "0.7rem",
                borderBottom: "1px solid #b3b3b3",
              }}
            >
              <div className="left-title" style={{ marginBottom: "20px" }}>
                <h4>Publishers</h4>
              </div>
              <div className="left-menu" style={divStyle}>
                <ul style={{ paddingLeft: "30px" }}>
                  <li style={{ listStyle: "circle" }}>
                    <Link to="#" style={decorationnone}>
                      অন্যপ্রকাশ
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      প্রতীক প্রকাশনা সংস্থা
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      অনন্যা
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      কাকলী প্রকাশনী
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      রকমারি কালেকশন
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      সময় প্রকাশন
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    <Link to="#" style={decorationnone}>
                      অন্বেষা প্রকাশন
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      জ্ঞানকোষ প্রকাশনী
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      লেজার ভিশন
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      অনুপম প্রকাশনী
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      অবসর প্রকাশনা সংস্থা
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      আফসার ব্রাদার্স
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      অবসর প্রকাশনা সংস্থা
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link style={decorationnone} to="#">
                      আফসার ব্রাদার্স
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      অবসর প্রকাশনা সংস্থা
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      অবসর প্রকাশনা সংস্থা
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      অবসর প্রকাশনা সংস্থা
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      অবসর প্রকাশনা সংস্থা
                    </Link>
                  </li>
                  <li style={{ listStyle: "circle" }}>
                    {" "}
                    <Link to="#" style={decorationnone}>
                      অবসর প্রকাশনা সংস্থা
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Publishers end */}
          {/* Authors start */}
          <div
            className="card"
            style={{ borderRadius: "0%", marginTop: "5px" }}
          >
            <div
              className="card-body justify-content-between"
              style={{
                padding: "0.7rem",
                borderBottom: "1px solid #b3b3b3",
              }}
            >
              <div className="left-title" style={{ marginBottom: "20px" }}>
                <h4>Authors</h4>
              </div>
              <div className="left-menu" style={divStyle}>
                <div className="form-check ">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    হুমায়ূন আহমেদ
                  </label>
                </div>

                <div className="form-check mt-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    সৈয়দ শামসুল হক
                  </label>
                </div>
                <div className="form-check mt-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    ইমদাদুল হক মিলন
                  </label>
                </div>
                <div className="form-check mt-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    নাসরীন জাহান
                  </label>
                </div>
                <div className="form-check mt-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    রাবেয়া খাতুন
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    মহাদেব সাহা
                  </label>
                </div>
                <div className="form-check mt-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    ফরিদুর রেজা সাগর
                  </label>
                </div>
                <div className="form-check mt-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    শাহাবুদ্দীন নাগরী
                  </label>
                </div>
                <div className="form-check mt-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    সেলিনা হোসেন
                  </label>
                </div>
                <div className="form-check mt-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    ডা. সজল আশফাক
                  </label>
                </div>
                <div className="form-check mt-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    আবদুল্লাহ আবু সায়ীদ
                  </label>
                </div>
                <div className="form-check mt-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    সুমন্ত আসলাম
                  </label>
                </div>
                <div className="form-check mt-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    আনিসুজ্জামান
                  </label>
                </div>
                <div className="form-check mt-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    মাজহারুল ইসলাম
                  </label>
                </div>
                <div className="form-check mt-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    সৈয়দ মনজুরুল ইসলাম
                  </label>
                </div>
                <div className="form-check mt-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    আহমদ রফিক
                  </label>
                </div>
                <div className="form-check mt-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    কণিকা রশীদ
                  </label>
                </div>
                <div className="form-check mt-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    আনোয়ারা সৈয়দ হক
                  </label>
                </div>
                <div className="form-check mt-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    আরিফ মঈনুদ্দীন
                  </label>
                </div>
              </div>
            </div>
          </div>
          {/* Authors End */}
          {/* Language start */}
          <div
            className="card"
            style={{ marginBottom: "20px", marginTop: "5px" }}
          >
            <div className="card-body">
              <div className="left-title" style={{ marginBottom: "20px" }}>
                <h4>Languages</h4>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input "
                  type="checkbox"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  style={{ cursor: "pointer" }}
                />
                <label
                  className="form-check-label"
                  for="flexRadioDefault1"
                  style={{ cursor: "pointer" }}
                >
                  বাংলা
                </label>
              </div>
              <div className="form-check mt-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                />
                <label
                  className="form-check-label"
                  for="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                >
                  English
                </label>
              </div>
            </div>
          </div>
          {/* Language end */}

          {/* price start */}
          <div
            className="card"
            style={{ marginBottom: "20px", marginTop: "5px" }}
          >
            <div className="card-body">
              <div className="left-title" style={{ marginBottom: "20px" }}>
                <h4>Prices</h4>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input "
                  type="checkbox"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  style={{ cursor: "pointer" }}
                />
                <label
                  className="form-check-label"
                  for="flexRadioDefault1"
                  style={{ cursor: "pointer" }}
                >
                  50 TK-150 Tk
                </label>
              </div>
              <div className="form-check mt-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                />
                <label
                  className="form-check-label"
                  for="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                >
                  150 TK-400 Tk
                </label>
              </div>
              <div className="form-check mt-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                />
                <label
                  className="form-check-label"
                  for="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                >
                  450 TK-850 Tk
                </label>
              </div>
              <div className="form-check mt-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                />
                <label
                  className="form-check-label"
                  for="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                >
                  850 TK-999 Tk
                </label>
              </div>
              <div className="form-check mt-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                />
                <label
                  className="form-check-label"
                  for="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                >
                  1000 TK-1250 Tk
                </label>
              </div>
            </div>
          </div>

          {/* price end*/}

          {/* ratings start */}
          <div
            className="card"
            style={{ marginBottom: "20px", marginTop: "5px" }}
          >
            <div className="card-body">
              <div className="left-title" style={{ marginBottom: "20px" }}>
                <h4>Ratings</h4>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input "
                  type="checkbox"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  style={{ cursor: "pointer" }}
                />
                <label
                  className="form-check-label"
                  for="flexRadioDefault1"
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-star" style={{ color: "green" }}></i>
                </label>
              </div>
              <div className="form-check mt-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                />
                <label
                  className="form-check-label"
                  for="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-star" style={{ color: "green" }}></i>
                  <i className="fa fa-star" style={{ color: "green" }}></i>
                </label>
              </div>
              <div className="form-check mt-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                />
                <label
                  className="form-check-label"
                  for="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-star" style={{ color: "green" }}></i>
                  <i className="fa fa-star" style={{ color: "green" }}></i>
                  <i className="fa fa-star" style={{ color: "green" }}></i>
                </label>
              </div>
              <div className="form-check mt-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                />
                <label
                  className="form-check-label"
                  for="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-star" style={{ color: "green" }}></i>
                  <i className="fa fa-star" style={{ color: "green" }}></i>
                  <i className="fa fa-star" style={{ color: "green" }}></i>
                  <i className="fa fa-star" style={{ color: "green" }}></i>
                </label>
              </div>
              <div className="form-check mt-1">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                />
                <label
                  className="form-check-label"
                  for="flexRadioDefault2"
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-star" style={{ color: "green" }}></i>
                  <i className="fa fa-star" style={{ color: "green" }}></i>
                  <i className="fa fa-star" style={{ color: "green" }}></i>
                  <i className="fa fa-star" style={{ color: "green" }}></i>
                  <i className="fa fa-star" style={{ color: "green" }}></i>{" "}
                  <i className="fa fa-star" style={{ color: "green" }}></i>
                </label>
              </div>
            </div>
          </div>
          {/* ratings end */}
          {/* Random start */}
          <div className="left-title " style={{ marginBottom: "20px" }}>
            <h4>Random</h4>
          </div>
          <div className="random-area " style={{ marginBottom: "30px" }}>
            <div className="product-active-2 owl-carousel">
              <div className="product-total-2">
                {relatedProducts.map((item, index) => (
                  <div
                    className="single-most-product bd "
                    style={{ marginBottom: "18px" }}
                  >
                    <div className="most-product-img">
                      <Link to="#">
                        <img src={item.image} alt="book" />
                      </Link>
                    </div>
                    <div className="most-product-content">
                      <h4>
                        <Link
                          to={{
                            pathname: `/product-details/${item.book.replace(
                              / /g,
                              "-"
                            )}`,
                            // state: { book: props.book },
                          }}
                          onClick={() =>
                          (window.location.href = `/product-details/${item.book.replace(
                            / /g,
                            "-"
                          )}`)
                          }
                        >
                          {item.book}
                        </Link>
                      </h4>
                      <p>
                        <span style={{ fontWeight: "500" }}>
                          {" "}
                          {item.author}
                        </span>
                      </p>
                      <div className="product-price">
                        <ul>
                          <li>TK. {item.sell_price}</li>
                          <li className="old-price">
                            Tk. {item.orginal_price}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="product-total-2">
                {relatedProducts.map((item, index) => (
                  <div
                    className="single-most-product bd "
                    style={{ marginBottom: "18px" }}
                  >
                    <div className="most-product-img">
                      <Link to="#">
                        <img src={item.image} alt="book" />
                      </Link>
                    </div>
                    <div className="most-product-content">
                      <h4>
                        <Link
                          to={{
                            pathname: `/product-details/${item.book.replace(
                              / /g,
                              "-"
                            )}`,
                            // state: { book: props.book },
                          }}
                          onClick={() =>
                          (window.location.href = `/product-details/${item.book.replace(
                            / /g,
                            "-"
                          )}`)
                          }
                        >
                          {item.book}
                        </Link>
                      </h4>
                      <p>
                        <span style={{ fontWeight: "500" }}>
                          {" "}
                          {item.author}
                        </span>
                      </p>
                      <div className="product-price">
                        <ul>
                          <li>TK. {item.sell_price}</li>
                          <li className="old-price">
                            Tk. {item.orginal_price}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Random end */}
          <div className="banner-area " style={{ marginBottom: "30px" }}>
            <div className="banner-img-2">
              <Link to="#">
                <img src="/img/Boi-Bazaar/My-wish-list.jpg" alt="banner" />
              </Link>
            </div>
          </div>
          <div className="left-title-2">
            <h2>My Wish List</h2>
            <p>You have no items in your wish list.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeftFilterBookList;