import React from "react";
import { Link } from "react-router-dom";
// import { Data } from "../Services/Data";
import MultirowSlickSlider from "./MultirowSlickSlider";
const BestSeller = () => {
  // const bestSeller1 = Data.slice(6, 8).map((item, index) => item);
  // const bestSeller2 = Data.slice(8, 10).map((item, index) => item);
  // const bestSeller3 = Data.slice(4, 6).map((item, index) => item);
  return (
    <>
      <div className="bestseller-area" style={{ paddingBottom: "50px", paddingTop: 50 }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 col-12 mb-4">
              <div className="row">
                <div className="bestseller-content col-12 col-md-6">
                  <h1 style={{ fontWeight: "bold" }}>Author best selling</h1>
                  <h2>
                    Humayun
                    <br />
                    Ahmed
                  </h2>
                  {/* <p className="categories">
                    categories:
                    <Link to="#" style={{ textDecoration: "none" }}>
                      Books
                    </Link>{" "}
                    ,{" "}
                    <Link to="#" style={{ textDecoration: "none" }}>
                      Authors
                    </Link>
                  </p> */}
                  {/* <div style={{ height: 300, overflow: "hidden", whiteSpace: "nowrap" }}> */}
                  <p>
                    Vestibulum porttitor iaculis gravida. Praesent vestibulum
                    varius placerat. Cras tempor congue neque, id aliquam orci
                    finibus sit amet. Fusce at facilisis arcu. Donec aliquet nulla
                    id turpis semper, a bibendum metus vulputate. Suspendisse
                    potenti.hello therer again hello therer again hello therer again hello therer again hello therer again hello therer again hello therer again hello therer again hello therer again hello therer again hello therer again hello therer again hello therer again hello {" "}
                  </p>
                  {/* </div> */}
                </div>
                <div className="banner-img-2 col-12 col-md-6">
                  <Link to="#">
                    <img src="img/Boi-Bazaar/Humayun_Ahmed.jpg" alt="banner" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-12 mb-4">
              <MultirowSlickSlider start="0" end="6" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BestSeller;
