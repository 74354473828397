import React, { useState } from "react";
import { ScaleLoader } from "react-spinners";
import dateFormat from "dateformat";
import ReactStars from "react-rating-stars-component";
const ReviewComments = ({ reviews, loading ,getReview}) => {

  
  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    
    
    return (
      <p className="text">
        {isReadMore ? text.slice(0, 150) : text}
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? (
            <span
              style={{
                color: "#24902f",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Read more
            </span>
          ) : (
            <span style={{ color: "#2790aa", cursor: "pointer" }}>
              Show Less
            </span>
          )}
        </span>
      </p>
    );
  };
  const loaderStyle = { textAlign: "center" };


  return (
    <>
      {loading ? (
        <div style={loaderStyle}>
          <ScaleLoader color="#24902f" />
        </div>
      ) : (
        <div className="container mb-3">
          {reviews.length
            ? reviews.map((item) => (
                <div
                  className="row"
                  style={{ borderBottom: "1px solid #e5e5e5" }}
                  key={item.id}
                >
                  <div className="col-lg=3">
                    <img
                      src="/img/men.jpeg"
                      alt=""
                      style={{
                        borderRadius: "50%",
                        height: "48px",
                        width: "48px",
                        display: "block",
                      }}
                    />
                    <span
                      className="text-secondary mt-1 lh-1"
                      style={{ display: "block" }}
                    >
                      {item.reviewer_name}
                    </span>
                    <span
                      className="text-secondary"
                      style={{ display: "block" }}
                    >
                      {dateFormat(item.created_at, "mmmm dS, yyyy")}{" "}
                      <ReactStars
                        value={item.rating}
                        activeColor="#24902f"
                        size="30"
                        edit={false}
                      />
                    </span>
                  
                  </div>
                  <div className="col-lg-8 ml-5 mt-2">
                    {item.comment.length < 150 ? (
                      item.comment
                    ) : (
                      <ReadMore>{item.comment}</ReadMore>
                    )}
                  </div>
                </div>
              ))
            : ""}
        </div>
      )}
    </>
  );
};

export default ReviewComments;
