import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'


class BlogComment extends React.Component {

	// const comments = props.comments;

	constructor(props) {
		super(props);
		this.state = {
			comments: []
		}
	}

	componentDidMount() {
		this.setState({
			comments: this.props.comments
		})
	}

	// Format API response date to human readable.
	formatDateTime = (dateString) => {
		const options = { year: "numeric", month: "long", day: "numeric", hour: 'numeric', minute: 'numeric' }
		return new Date(dateString).toLocaleDateString(undefined, options)
	}

	render() {
		return (
			<>
				   <div className="comment-reply-wrap " style={{marginTop:"50px"}}>
					<ul>
	
						{this.state.comments.map((item, index) => (
							<li key={index}>
								<div className="public-comment">
									<div className="public-text">
										<div className="single-comm-top">
											{/* <Link to={item.web}>{item.name}</Link> */}
											<a href={item.web}>{item.name}</a>
											<p>{ this.formatDateTime(item.created_at) }</p>
										</div>
										<p>{item.comment}</p>
										
										{/* <p className="text-danger"><b><i>This is message</i></b></p> */}
									</div>
								</div>
							</li>
						))}
	
					</ul>
				</div>
	 
			</>
		)
	}
}

export default BlogComment
