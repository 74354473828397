import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { url as LinkUrl } from "../../Services/LinkGenerator";

import { AuthContext } from "../../Context/AuthState";
import { CartContext } from "../../Context/GlobalContext/GlobalState";
import { url } from "../../Services/Url";

const linkStyle = {
  color: "black",
  textDecoration: "none",
};

class Logo extends React.Component {
  constructor() {
    super();
    this.state = {
      logo: "/img/logo/BoiBazaar.png",
    };
  }

  componentDidMount() {
    axios.get(`${url}/api/v1/assets/logo`).then((response) => {
      if (response.status === 200) {
        this.setState({
          logo: response.data.logo.path,
        });
      }
    });
  }

  render() {
    return (
      <>
        <img src={this.state.logo} alt="logo" width="226" />
      </>
    );
  }
}

const Navbar = () => {
  const { state, dispatch } = useContext(AuthContext);
  const { wishList } = useContext(AuthContext);
  const { cart } = useContext(CartContext);

  const [navigation, setNavigation] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    axios
      .get(`${url}/api/v1/navigation/primary`)
      .then((res) => {
        setNavigation(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  // useEffect(() => {
  //   const data = { search: search };

  //   axios
  //     .post(`${url}/api/v1/products/search`, data)
  //     .then((res) => {
  //       console.log("🎇", res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // }, [search]);

  const logout = (e) => {
    e.preventDefault();
    dispatch({
      type: "LOGOUT",
    });
  };

  return (
    <div
      style={{
        backgroundImage: "url(" + "/img/banner/27.jpg" + ")",
        backgroundPosition: "center",
        backgroundAttachment: "scroll",
        backgroundRepeat: "repeat",
      }}
    >
      <div className="container logobar2">
        <nav className="navbar navbar-expand-lg navbar-light nav-container">
          <Link className="navbar-brand responsive-image" to="/">
            <Logo />
          </Link>

          <div className="header-search">
            <form id="navSearchField">
              <input
                type="text"
                placeholder="অনুসন্ধান করুন..."
                style={{ outline: "none", width: "480px" }}
                className="input-search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {search === "" ? (
                <Link
                  to="#"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "none",
                  }}
                  onClick={() => setSearch("")}
                >
                  <i className="fa fa-search"></i>
                </Link>
              ) : (
                <Link
                  to={{
                    pathname: `/books/search/${search.replace(/\s+/g, "-")}`,
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textDecoration: "none",
                  }}
                  onClick={() => setSearch("")}
                >
                  <i className="fa fa-search"></i>
                </Link>
              )}
            </form>
          </div>

          <div style={{ display: "flex" }}>
            <ul className="disappeare" id="wishlistResponsiveScreen">
              <li className="nav-item">
                <div className="my-cart nav-link">
                  <ul>
                    <li>
                      <Link to="/wishlist" className="">
                        <img
                          src="/img/Boi-Bazaar/wishlist.png"
                          alt=""
                          width="26px"
                        />
                      </Link>

                      <span>
                        {state.isAuthenticated ? wishList.length : "0"}
                      </span>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <ul
              className="disappeare"
              id="cartResponsiveScreen"
              style={{ marginRight: 5 }}
            >
              <li className="nav-item">
                <div className="my-cart nav-link">
                  <ul>
                    <li>
                      <Link to="/cart" className="">
                        <img
                          src="/img/Boi-Bazaar/cart.png"
                          alt=""
                          width="28px"
                        />
                      </Link>
                      <span>{cart ? cart.length : 0}</span>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item hotline" id="hotlineRemove">
                <Link className="nav-link" to="#">
                  <img src="/img/Boi-Bazaar/hotline.png" alt="" width="26px" />
                </Link>
                <Link className="nav-link" to="#">
                  <span style={{ color: "#222222" }}>হটলাইন</span>
                  <span style={{ display: "block", color: "#263238" }}>
                    012636544
                  </span>
                </Link>
              </li>

              <li
                className="nav-item"
                id="wishlistRemove"
                style={{ marginRight: 20 }}
              >
                <div className="my-cart nav-link">
                  <ul>
                    <li>
                      <Link to="/wishlist" className="">
                        <img
                          src="/img/Boi-Bazaar/wishlist.png"
                          alt=""
                          width="26px"
                        />
                      </Link>

                      <span>
                        {state.isAuthenticated ? wishList.length : "0"}
                      </span>
                    </li>
                  </ul>
                </div>
              </li>

              <li
                className="nav-item"
                id="cartRemove"
                style={{ marginRight: 20 }}
              >
                <div className="my-cart nav-link">
                  <ul>
                    <li>
                      <Link to="/cart" className="">
                        <img
                          src="/img/Boi-Bazaar/cart.png"
                          alt=""
                          width="28px"
                        />
                      </Link>
                      <span>{cart ? cart.length : 0}</span>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="nav-item" id="loginRemove">
                {state.isAuthenticated && state.isAuthenticated === true ? (
                  <>
                    <div className="nav-item dropdown mt-2 ml-5">
                      <Link
                        className="nav-link dropdown-toggle"
                        to="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="/img/Boi-Bazaar/user.png"
                          alt=""
                          width="26px"
                        />
                      </Link>
                      <span className="">
                        {state.user ? state.user.first_name : ""}
                      </span>
                      <div
                        className="dropdown-menu "
                        aria-labelledby="navbarDropdown"
                      >
                        <Link style={{ textDecoration: "none" }} to="/profile">
                          <span className="dropdown-item mydrop">Profile</span>
                        </Link>
                        <span className="dropdown-item mydrop" onClick={logout}>
                          Logout
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="nav-item mt-2">
                      <Link className="nav-link" to="#">
                        <img
                          src="/img/Boi-Bazaar/user.png"
                          alt=""
                          width="26px"
                        />
                      </Link>
                    </div>
                    <div className="nav-item logo-login text-left mt-2">
                      <Link className="" to="/login">
                        <span style={{ lineHeight: "none" }}>লগইন</span>
                      </Link>
                      <Link className="" to="/register">
                        <span style={{ display: "block" }}>রেজিস্টার</span>
                      </Link>
                    </div>
                  </div>
                )}
              </li>

              {navigation.map((item, index) => (
                <li
                  li
                  className="home mobile-view-nav-content"
                  id="homeRemove"
                  key={index}
                >
                  <Link
                    to={{
                      pathname: LinkUrl({
                        type: item.type,
                        url: item.url,
                        item,
                      }),
                    }}
                    style={linkStyle}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </nav>
        <div
          className="header-search"
          id="responsiveSearchField"
          style={{ paddingBottom: 10, paddingTop: 5 }}
        >
          <form action="#">
            <input
              type="text"
              placeholder="অনুসন্ধান করুন..."
              style={{ outline: "none" }}
              className="input-search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {search === "" ? (
              <Link
                to="#"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textDecoration: "none",
                }}
                onClick={() => setSearch("")}
              >
                <i className="fa fa-search"></i>
              </Link>
            ) : (
              <Link
                to={{
                  pathname: `/books/search/${search.replace(/\s+/g, "-")}`,
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textDecoration: "none",
                }}
                onClick={() => setSearch("")}
              >
                <i className="fa fa-search"></i>
              </Link>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
