import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { AuthContext } from "../Context/AuthState";
import { CartContext } from "../Context/GlobalContext/GlobalState";
import StickyButton from "./StickyButton";
import { url } from "../Services/Url";

class Logo extends React.Component {

  constructor()
  {
    super();
    this.state = {
      logo: '/img/logo/BoiBazaar.png'
    }
  }

  componentDidMount(){
    axios.get(`${url}/api/v1/assets/logo`)
    .then(response => {
      if(response.status == 200)
      {
        this.setState({
          logo: response.data.logo.path
        });
      }
    });
  }

  render(){
    return (
      <>
        <img src={this.state.logo} alt="logo" width="226" />
      </>
    );
  }
}

const LogoBar2 = () => {
  const { cart, addToCart } = useContext(CartContext);
  const {state,dispatch} = useContext(AuthContext)
  const {wishList,loading} = useContext(AuthContext)
  const [myCart, setCart] = useState([]);
  const [totalAmount, setTotal] = useState(0);

  useEffect(() => {
    let res = cart
      ? cart.reduce(function (accumulator, currentValue) {
          return (
            accumulator +
            currentValue.special_price.amount * currentValue.quantity
          );
        }, 0)
      : "";
    setTotal(res);
  }, [cart]);

  const logout = (e)=>{
    e.preventDefault();
    dispatch({
      type:"LOGOUT"
    })
  }
  return (
    <>
     
      <div
        className="logobar2"
        style={{
          // padding: "10px",
          backgroundImage: "url(" + "/img/banner/27.jpg" + ")",
          backgroundPosition: "center",
          backgroundAttachment: "scroll",
          backgroundRepeat: "repeat",
        }}
      >
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <div className="row logo-nav justify-content-between">
              <div className="col-lg-3 col-md-3 col-6 logo-img">
                <Link
                  className="navbar-brand"
                  to="#"
                  // onClick={() => (window.location.href = `/`)}
                >
                  <Logo />
                </Link>
              </div>
              <button
                className="navbar-toggler mx-3"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse my-collapse"
                id="navbarSupportedContent"
              >
                <div className="col-lg-5 col-md-5 col-12 logo-search">
                  <ul className="navbar-nav  mb-1">
                    <li>
                      <div className="header-search">
                        <form action="#">
                          <input
                            type="text"
                            placeholder="অনুসন্ধান করুন..."
                            style={{ outline: "none", width: "300px" }}
                            className="input-search"
                          />
                          <Link to="#">
                            <i className="fa fa-search"></i>
                          </Link>
                        </form>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 col-md-4 col-12 logo-icon  mr-auto  ">
                  <ul className="navbar-nav ">
                    <li className="nav-item mt-2 ">
                      <Link className="nav-link phone" to="#">
                        <img
                          src="/img/Boi-Bazaar/hotline.png"
                          alt=""
                          width="26px"
                        />
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="#">
                        <span style={{ color: "#222222" }}>হটলাইন</span>
                        <span style={{ display: "block", color: "#263238" }}>
                          012636544
                        </span>
                      </Link>
                    </li>
                    {/* <li className="nav-item mt-2 ml-2">
                      <Link className="nav-link" to="#">
                        <img
                          src="/img/Boi-Bazaar/wishlist.png"
                          alt=""
                          width="26px"
                        />
                      </Link>
                    </li> */}
                   <li className="nav-item active ml-4">
                      <div className="my-cart">
                        <ul>
                          <li>
                            <Link to="/wishlist" className="">
                              <img
                                src="/img/Boi-Bazaar/wishlist.png"
                                alt=""
                                width="26px"
                              />
                            </Link>
                           
                           <span>{state.isAuthenticated?wishList.length:"0"}</span>
                           
                          </li>
                        </ul>
                      </div>
                    </li>
                   
                    <li className="nav-item active ml-5">
                      <div className="my-cart">
                        <ul>
                          <li>
                            <Link to="/cart" className="">
                              <img
                                src="/img/Boi-Bazaar/cart.png"
                                alt=""
                                width="28px"
                              />
                            </Link>
                            <span>{cart ? cart.length : 0}</span>
                          </li>
                        </ul>
                      </div>
                    </li>
                    {state.isAuthenticated && state.isAuthenticated===true?
                    <> 
                      <li className="nav-item dropdown mt-2 ml-5">
                        <Link className="nav-link dropdown-toggle"
                              to="#" id="navbarDropdown" 
                              role="button"
                              data-toggle="dropdown" 
                              aria-haspopup="true" 
                              aria-expanded="false">
                        <img
                            src="/img/Boi-Bazaar/user.png"
                            alt=""
                            width="26px"
                        />
                      </Link>
                      <span className="">{state.user?state.user.first_name:""}</span>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <Link style={{textDecoration:"none"}} to="/profile"><span className="dropdown-item mydropdownitem" >Profile</span></Link>
                            <span className="dropdown-item" onClick={logout}>Logout</span>
                        </div>
                      </li>
                    </>
                    :
                    <>
                      <li className="nav-item mt-2 ml-5">
                        <Link className="nav-link" to="#">
                          <img
                            src="/img/Boi-Bazaar/user.png"
                            alt=""
                            width="26px"
                          />
                        </Link> 
                      </li>
                      <li className="nav-item logo-login text-left mt-2">
                        <Link className="" to="/login">
                          <span style={{ lineHeight: "none" }}>লগইন</span>
                        </Link>
                        <Link className="" to="/register">
                          <span style={{ display: "block" }}>রেজিস্টার</span>
                        </Link>
                      </li>
                    </>
                    }
                    
                   
                    {/* {state.isAuthenticated && state.isAuthenticated===true ? <button onClick={logout}>Logout</button>:""} */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default LogoBar2;
