import React,{useState,useContext} from "react";
import Breadcumbs from "../../Components/Breadcumbs";

import { Link, useHistory  } from "react-router-dom";
import "./Login.css";

import { AuthContext } from "../../Context/AuthState";
import axios from "axios";
import {url} from '../../Services/Url'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();
const Login = () => {
  const [email,setEmail]=useState("");
  const [password,setPassword]=useState("");
  const [errors,setError]= useState([]);
  const {state,dispatch,} = useContext(AuthContext)
 
  let history = useHistory();
  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(`${url}/api/v1/user`,{email:email,password:password},{headers:{"Content-Type":"application/json"}})
        .then((res) => {
            
            dispatch({
              type:"LOGIN",
              payload:res.data
            })
            window.location.href="/"
            // toast.success("Successfully Login",{autoClose:2000})
            console.log("result:",res.data);
        })
        .catch((error) => {
            if (error.response.status === 422)
              setError(error.response.data)
            if (error.response.status === 401)
                toast.error(error.response.data.message)

        });   
        setEmail("");
        setPassword("");
  };

  return (
    <>
  
      <Breadcumbs />
      <div className="user-login-area mb-70 mt-3" style={{ marginBottom: "50px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="login-title text-center mb-30"
                style={{ marginBottom: "30px" }}
              >
                <h2>Login</h2>
              
               
              </div>
            </div>
            <div className="offset-lg-3 col-lg-6 col-md-12 col-12">
           
              <div className="login-form">
              
              <form onSubmit={handleSubmit}>
                <div className="single-login">
                  <label>
                    Username or email<span>*</span>
                  </label>
                  <input type="email" placeholder="Enter email" name="email" id="email" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                  {errors['email'] ?<span style={{color:"red"}}>{errors['email'][0]}</span>:""}
                </div>
                <div className="single-login">
                  <label>
                    Passwords <span>*</span>
                  </label>
                  <input type="password" placeholder="Enter password" name="password" id="password" value={password} onChange={(e)=>setPassword(e.target.value)}/>
                  {errors['password'] ?<span style={{color:"red"}}>{errors['password'][0]}</span>:""}
                </div>
                <div className="single-login single-login-2">
                  <Link to="#" type="submit" onClick={handleSubmit}>login</Link>
            
                </div>
              </form>
                <Link to="#">Forget password?</Link>
              </div>
              <p className="mt-2">
                Don't have an account?{" "}
                <span>
                  <strong>
                    <Link
                      to="/register"
                      style={{ textDecoration: "none", color: "#259030" }}
                    >
                      Sign Up Now!
                    </Link>
                  </strong>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <GlobalFooter /> */}
    </>
  );
};

export default Login;
