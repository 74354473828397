import React from "react";
const AuthorBanner = () => {
  return (
    <>
      <div className="image-container">
        <div
          className="single-slider "
          style={{
            backgroundImage: "url(" + "img/Boi-Bazaar/Background.jpg" + ")",
            height: "299px",
            display: "cover",
          }}
        ></div>
        <div className="overlay-img">
          <div className="container">
            <div className="row">
              <div className="col-sm-3"></div>
              <div className="col-sm-6 mt-5 search-author">
                <div className="input-group mb-3 mt-5">
                  <input
                    type="text"
                    className="form-control mt-4"
                    placeholder="Search your Favourite author"
                    aria-label="Search your Favourite author"
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append">
                    <input
                      className="btn btn-success mt-4"
                      type="button"
                      value="Search Author"
                      id="basic-addon2"
                    ></input>
                  </div>
                </div>
              </div>

              <div className="col-sm-3"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthorBanner;
