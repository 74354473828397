import React, {useState, useEffect} from 'react'
import GlobalFooter from "../../Components/GlobalFooter";
import HeaderTopBar from "../../Components/HeaderTopBar";
import LogoBar2 from "../../Components/LogoBar2";
import MenuBar from "../../Components/MenuBar";
import "./Blog.css";
import BlogLeftSideBar from "./BlogLeftSideBar/BlogLeftSideBar";
import BlogTagPost from "./BlogTagPost"

const BlogTag = (props) => {    

    return (
        <>
        <HeaderTopBar />
        <LogoBar2 />
        <MenuBar />
        <div className="blog-main-area " style={{marginBottom:"70px"}}>
            <div className="container">
            <div className="row">
                <BlogLeftSideBar/> 
                <BlogTagPost slug={props.match.params.slug} />
            </div>
            </div>
        </div>
        <GlobalFooter/>
        </>
    );
}

export default BlogTag;