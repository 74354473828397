export const Data = [
  {
    category: "author",
    id: 1,
    slug: "jochna-oo-jnneer-glp",
    author: "হুমায়ূন আহমেদ",
    book: "জোছনা ও জননীর গল্প",
    sell_price: 680,
    orginal_price: 800,
    country: "bangladesh",
    language: "Bangla",
    publisher: "অন্যপ্রকাশ",
    number_of_pages: 120,
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/36dd62638_968.jpg",
  },
  {
    category: "author",
    id: 2,
    slug: "deyal",
    author: "হুমায়ূন আহমেদ",
    book: "দেয়াল",
    sell_price: 383,
    orginal_price: 450,
    country: "bangladesh",
    language: "Bangla",
    publisher: "অন্যপ্রকাশ",
    number_of_pages: 198,
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/1b3707c61_62834.jpg",
  },
  {
    category: "author",
    id: 3,
    slug: "suunz",
    author: "হুমায়ূন আহমেদ",
    book: "শূন্য",
    sell_price: 106,
    orginal_price: 120,
    country: "bangladesh",
    language: "Bangla",
    publisher: "সময় প্রকাশন",
    number_of_pages: 64,
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/img141201_1336.gif",
  },
  {
    category: "author",
    id: 4,
    author: "হুমায়ূন আহমেদ",
    slug: "dwoirth",
    book: "দ্বৈরথ",
    sell_price: 350,
    orginal_price: 450,
    country: "bangladesh",
    language: "Bangla",
    publisher: "কাকলী প্রকাশনী",
    number_of_pages: 220,
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/4a45431b5_1236.jpg",
  },
  {
    category: "author",
    id: 5,
    slug: "krrishnpksh",
    author: "হুমায়ূন আহমেদ",
    book: "কৃষ্ণপক্ষ",
    sell_price: 350,
    orginal_price: 450,
    country: "bangladesh",
    language: "Bangla",
    publisher: "সুবর্ণ",
    number_of_pages: 504,
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/68cb707d2_1354.jpg",
  },
  {
    category: "author",
    id: 6,
    author: "হুমায়ূন আহমেদ",
    book: "শঙ্খনীল কারাগার",
    slug: "snkhneel-karagar",
    sell_price: 350,
    orginal_price: 450,
    country: "bangladesh",
    language: "Bangla",
    publisher: "অন্যপ্রকাশ",
    number_of_pages: 93,
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/fef28b5e8_1163.jpg",
  },
  {
    category: "author",
    id: 7,
    slug: "himu",
    author: "হুমায়ূন আহমেদ",
    book: "হিমু",
    sell_price: 350,
    orginal_price: 450,
    country: "bangladesh",
    language: "Bangla",
    publisher: "প্রতীক প্রকাশনা সংস্থা",
    number_of_pages: 96,
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/0e743778fca4_1277.gif",
  },
  {
    category: "author",
    id: 8,
    slug: "tomake",
    author: "হুমায়ূন আহমেদ",
    book: "তোমাকে",
    sell_price: 350,
    orginal_price: 450,
    country: "bangladesh",
    language: "Bangla",
    publisher: "অনন্যা",
    number_of_pages: 72,
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/1bf45d109584_884.gif",
  },
  {
    category: "author",
    id: 9,
    slug: "misir-ali-smgr-1",
    author: "হুমায়ূন আহমেদ",
    book: "মিসির আলি সমগ্র ১",
    sell_price: 350,
    orginal_price: 450,
    country: "bangladesh",
    language: "Bangla",
    publisher: "অনন্যা",
    number_of_pages: 1144,
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/1d3988f5b104_56.gif",
  },
  {
    category: "author",
    id: 10,
    slug: "hijibiji",
    author: "হুমায়ূন আহমেদ",
    book: "হিজিবিজি",
    sell_price: 350,
    orginal_price: 450,
    country: "bangladesh",
    language: "Bangla",
    publisher: "অন্যপ্রকাশ",
    number_of_pages: 128,
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/imgrok0610_60870.GIF",
  },
  {
    category: "author",
    id: 11,
    slug: "debee",
    author: "হুমায়ূন আহমেদ",
    book: "দেবী",
    sell_price: 113,
    orginal_price: 150,
    country: "bangladesh",
    language: "Bangla",
    publisher: "অবসর প্রকাশনা সংস্থা",
    number_of_pages: 80,
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/02a54b70c_1207.jpg",
  },
  {
    category: "author",
    id: 12,
    slug: "kothaoo-keu-nei",
    author: "হুমায়ূন আহমেদ",
    book: "কোথাও কেউ নাই",
    sell_price: 250,
    orginal_price: 300,
    country: "bangladesh",
    language: "Bangla",
    publisher: "কাকলী প্রকাশনী",
    number_of_pages: 253,
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/c6b15407b_1241.jpg",
  },
  {
    id: 13,
    slug: "badsah-namdar",
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/e71eda8aa_1171.jpg",
    category: "author",
    author: "হুমায়ূন আহমেদ",
    orginal_price: 450,
    sell_price: 383,
    book: "বাদশাহ নামদার",
    publisher: "অন্যপ্রকাশ",
    edition: "6th",
    number_of_pages: "232",
    country: "বাংলাদেশ",
    language: "বাংলা",
  },
  {
    category: "author",
    id: 14,
    slug: "dwoirth",
    author: "হুমায়ূন আহমেদ",
    book: "দ্বৈরথ",
    sell_price: 119,
    orginal_price: 135,
    country: "bangladesh",
    language: "Bangla",
    publisher: "কাকলী প্রকাশনী",
    number_of_pages: 253,
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/4a45431b5_1236.jpg",
  },
  {
    id: 15,
    slug: "botl-vuut",
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/57fc71c3d_1306.jpg",
    category: "author",
    author: "হুমায়ূন আহমেদ",
    orginal_price: 120,
    sell_price: 106,
    book: "বোতল ভূত",
    publisher: "প্রতীক প্রকাশনা সংস্থা",
    number_of_pages: 34,
    country: "বাংলাদেশ",
    language: "বাংলা",
  },
  {
    id: 16,
    slug: "1971",
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/97779befa_1215.jpg",
    category: "author",
    author: "হুমায়ূন আহমেদ",
    orginal_price: 120,
    sell_price: 106,
    book: "১৯৭১",
    publisher: "আফসার ব্রাদার্স",
    number_of_pages: 68,
    country: "বাংলাদেশ",
    language: "বাংলা",
  },
  {
    id: 17,
    slug: "himur-hate-kyekti-neelpdm",
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/e5875abb6274_1191.gif",
    category: "author",
    author: "হুমায়ূন আহমেদ",
    orginal_price: 200,
    sell_price: 176,
    book: "হিমুর হাতে কয়েকটি নীলপদ্ম",
    publisher: "অন্বেষা প্রকাশন",
    edition: "5th Printed, 2015",
    number_of_pages: 126,
    country: "বাংলাদেশ",
    language: "বাংলা",
  },
  {
    id: 18,
    slug: "chotder-sera-glp",
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/ca6ebb957_1319.jpg",
    category: "author",
    author: "হুমায়ূন আহমেদ",
    orginal_price: 200,
    sell_price: 176,
    book: "ছোটদের সেরা গল্প",
    publisher: "মাওলা ব্রাদার্স",
    edition: "5th Printed, 2015",
    number_of_pages: 126,
    country: "বাংলাদেশ",
    language: "বাংলা",
  },
  {
    id: 19,
    slug: "brrihnnla",
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/eda017486_1283.jpg",
    category: "author",
    author: "হুমায়ূন আহমেদ",
    orginal_price: 90,
    sell_price: 68,
    book: "বৃহন্নলা",
    publisher: "প্রতীক প্রকাশনা সংস্থা",
    edition: "7th Printed, 2012",
    number_of_pages: 47,
    country: "বাংলাদেশ",
    language: "বাংলা",
  },
  {
    id: 20,
    slug: "nirwasn",
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/877d679f7c54_1195.gif",
    category: "author",
    author: "হুমায়ূন আহমেদ",
    orginal_price: 135,
    sell_price: 119,
    book: "নির্বাসন",
    publisher: "অন্বেষা প্রকাশন",
    edition: "3th Printed, 2015",
    number_of_pages: "48",
    country: "বাংলাদেশ",
    language: "বাংলা",
  },
  {
    id: 21,
    slug: "bhubreehi",
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/e36702381_1211.jpg",
    category: "author",
    author: "হুমায়ূন আহমেদ",
    orginal_price: 250,
    sell_price: 213,
    book: "বহুব্রীহি",
    publisher: "আফসার ব্রাদার্স",
    isbn: "9847016600272",
    edition: "15th Print, 2016",
    number_of_pages: "192",
    country: "বাংলাদেশ",
    language: "বাংলা",
  },
  {
    id: 22,
    slug: "drjar-oopase",
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/rokimg_20140709_8981.GIF",
    category: "author",
    author: "হুমায়ূন আহমেদ",
    orginal_price: 200,
    sell_price: 176,
    book: "দরজার ওপাশে",
    publisher: "জ্ঞানকোষ প্রকাশনী",
    isbn: "9789848933510",
    edition: "11th Printed, 2014",
    number_of_pages: 120,
    country: "বাংলাদেশ",
    language: "বাংলা",
  },
  {
    id: 23,
    slug: "ebng-himu",
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/89f032f8a_1323.jpg",
    category: "author",
    author: "হুমায়ূন আহমেদ",
    orginal_price: 125,
    sell_price: 110,
    book: "এবং হিমু",
    publisher: "সময় প্রকাশন",
    isbn: "9844580986",
    edition: "15th Printed, 2014",
    "number-of-pages": "80",
    country: "বাংলাদেশ",
    language: "বাংলা",
  },
  {
    id: 24,
    slug: "mdhzahn-okhnd",
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/5dbfb616b_1141.jpg",
    category: "author",
    author: "হুমায়ূন আহমেদ",
    orginal_price: 700,
    sell_price: 595,
    book: "মধ্যাহ্ন অখণ্ড",
    publisher: "অন্যপ্রকাশ",
    isbn: "9848684948",
    number_of_pages: 408,
    country: "বাংলাদেশ",
    language: "বাংলা",
  },
  {
    id: 25,
    slug: "hrtn-iskapn",
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/img141201_129.gif",
    category: "author",
    author: "হুমায়ূন আহমেদ",
    orginal_price: 160,
    sell_price: 141,
    book: "হরতন ইশকাপন",
    publisher: "অনন্যা",
    isbn: "9847010500691",
    edition: "4rd Printed, 2016",
    country: "বাংলাদেশ",
    language: "বাংলা",
  },
  {
    id: 26,
    slug: "onees",
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/643dc37c2_1183.jpg",
    category: "author",
    author: "হুমায়ূন আহমেদ",
    orginal_price: 120,
    sell_price: 106,
    book: "অনীশ",
    publisher: "অনুপম প্রকাশনী",
    isbn: "9789844042995",
    edition: "6th Printed, 2015",
    country: "বাংলাদেশ",
    language: "বাংলা",
  },
  {
    id: 27,
    slug: "oparesn-neelanjna",
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/1dad5bf48_212324.jpg",
    category: "publisher",
    author: "মুহম্মদ জাফর ইকবাল",
    orginal_price: 230,
    sell_price: 196,
    book: "অপারেশন নীলাঞ্জনা",
    publisher: "অনুপম প্রকাশনী",
    isbn: "978984955803",
    edition: "1st Published, 2021",
    country: "বাংলাদেশ",
    language: "বাংলা",
  },
  {
    id: 28,
    slug: "zetuku-tuntuni-setuku-chotaccu",
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/8cab4ed92_212130.jpg",
    category: "publisher",
    author: "মুহম্মদ জাফর ইকবাল",
    orginal_price: 272,
    sell_price: 272,
    book: "যেটুকু টুনটুনি সেটুকু ছোটাচ্চু",
    publisher: "সময় প্রকাশন",
    isbn: "9789845027342",
    edition: "1st Published, 2021",
    country: "বাংলাদেশ",
    language: "বাংলা",
  },
  {
    id: 29,
    slug: "badsah-namdar",
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/e71eda8aa_1171.jpg",
    category: "publisher",
    author: "হুমায়ূন আহমেদ",
    orginal_price: 450,
    sell_price: 383,
    book: "বাদশাহ নামদার",
    publisher: "অন্যপ্রকাশ",

    edition: "6th",

    country: "বাংলাদেশ",
    language: "বাংলা",
  },
  {
    id: 30,
    slug: "himu-rimande",
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/imgrok_20141214_1139.gif",
    category: "publisher",
    author: "হুমায়ূন আহমেদ",
    orginal_price: 380,
    sell_price: 323,
    book: "হিমু রিমান্ডে",
    publisher: "অন্যপ্রকাশ",
    edition: "1st Published, 2020",
    country: "বাংলাদেশ",
    language: "বাংলা",
  },
  {
    id: 31,
    slug: "himu-ds",
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/rokimg_2110_72879.GIF",
    category: "publisher",
    author: "হুমায়ূন আহমেদ",
    orginal_price: 400,
    sell_price: 300,
    book: "হিমু দশ",
    publisher: "অন্যপ্রকাশ",
    isbn: "9789845028028",
    edition: "1st Published, 2021",
    country: "বাংলাদেশ",
    language: "বাংলা",
  },
  {
    id: 32,
    slug: "zkhn-namibe-adhannr",
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/ef033fea6_12316.jpg",
    category: "publisher",
    author: "হুমায়ূন আহমেদ",
    orginal_price: 191,
    sell_price: 191,
    book: "যখন নামিবে আধাঁর",
    publisher: "অন্যপ্রকাশ",
    edition: "2nd Printed, 2006",
    country: "বাংলাদেশ",
    language: "বাংলা",
  },
  {
    id: 33,
    slug: "tetul-bne-jochna",
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/img141201_923.gif",
    category: "publisher",
    author: "হুমায়ূন আহমেদ",
    orginal_price: 255,
    sell_price: 255,
    book: "তেতুল বনে জোছনা",
    publisher: "অন্যপ্রকাশ",
    isbn: "9789845024341",
    edition: "1st Published, 2018",

    country: "বাংলাদেশ",
    language: "বাংলা",
  },
  {
    id: 34,
    slug: "hijibiji",
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/imgrok0610_60870.GIF",
    category: "publisher",
    author: "হুমায়ূন আহমেদ",
    orginal_price: 300,
    sell_price: 255,
    book: "হিজিবিজি",
    publisher: "অন্যপ্রকাশ",

    edition: "6th Printed, 2009",

    country: "বাংলাদেশ",
    language: "বাংলা",
  },
  {
    id: 35,
    slug: "niuyrker-neelakase-jhkjhke-rod",
    image:
      "https://ds.rokomari.store/rokomari110/ProductNew20190903/260X372/imgrok0526_11275.gif",
    category: "publisher",
    author: " হুমায়ূন আহমেদ",
    orginal_price: 380,
    sell_price: 323,
    book: "নিউইয়র্কের নীলাকাশে ঝকঝকে রোদ",
    publisher: "অন্যপ্রকাশ",
    isbn: "9789845027595",
    edition: "1st Published, 2021",

    country: "বাংলাদেশ",
    language: "বাংলা",
  },
];
