import React,{useContext} from "react";

import BookDetails from "../Components/BookDetails";


import { useLocation } from "react-router-dom";
import StickyButton from "../Components/StickyButton";
import { AuthContext } from "../Context/AuthState";
import StickyWishlist from "../Components/StickyWishlist";

const ProductDetails = () => {
  const location = useLocation();
  const {state} = useContext(AuthContext)
  return (
    <div>
     {state.isAuthenticated ?   <StickyWishlist/> : ""}
      <StickyButton />
  
      <BookDetails />
      {/* BookDetails->productInfo */}

      {/* <ReviewComments /> */}
      {/* <ReviewComments /> */}
     
    </div>
  );
};

export default ProductDetails;
