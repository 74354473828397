import React from "react";
import Radium, { StyleRoot } from "radium";
import Home from "./Pages/Home";
import ProductDetails from "./Pages/ProductDetails";
import BookList from "./Pages/BookList";
import AllBookList from "./Pages/AllBookList";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Authors from "./Pages/Authors";
import Publications from "./Pages/Publications";
import Categories from "./Pages/Categories";
import About from "./Pages/About";
import NotFound from "./Pages/NotFound";
import Contact from "./Pages/Contact";
import Login from "./Pages/Login/Login";
import Register from "./Pages/Register/Register";
import Wishlist from "./Pages/Wishlist/Wishlist";
import Cart from "./Pages/Cart/Cart";
import GlobalStateProvider from "./Context/GlobalContext/GlobalState";

import Checkout from "./Pages/Checkout/Checkout";
import Profile from "./Pages/Profile/Profile";
import Blog from "./Pages/Blog/Blog";
import BlogCategory from "./Pages/Blog/BlogCategory";
import BlogTag from "./Pages/Blog/BlogTag";
import AuthStateProvider from "./Context/AuthState";
import ProtectedRoute from "./Services/ProtectedRoute";
import BlogDetails from "./Pages/Blog/BlogDetails";
import Page from "./Pages/Page/Page";
import NavMenu from "./Components/NavMenu";
import HeaderTopBar from "./Components/HeaderTopBar";
import Navbar from "./Components/NavbarNew/Navbar";
import GlobalFooter from "./Components/GlobalFooter";
import Search from "./Pages/Search/Search";
function App() {
  return (
    <StyleRoot>
      <div className="">
        <Router>
        <HeaderTopBar />
          
          <Switch>
            <AuthStateProvider>
              <GlobalStateProvider>

              <Navbar/>
              <NavMenu/>
                <Route path="/" exact component={Home} />
                <Route
                  path="/product-details/:bookname"
                  exact
                  component={ProductDetails}
                />
                <Route
                  path="/books/authors/:id/:name"
                  exact
                  component={BookList}
                />
                <Route
                  path="/books/publishers/:slug"
                  exact
                  component={BookList}
                />

                <Route path="/books/search/:name" exact component={BookList}/>

                <Route path="/books/" exact component={AllBookList} />
                <Route path="/authors/" exact component={Authors} />
                <Route path="/publications/" exact component={Publications} />
                <Route path="/categories/" exact component={Categories} />
                <Route
                  path="/books/categories/:slug"
                  exact
                  component={BookList}
                />
                <Route path="/about" exact component={About} />
                <Route path="/contact" exact component={Contact} />
                <Route path="/cart" exact component={Cart} />
                <ProtectedRoute path="/checkout" exact component={Checkout} />
                <ProtectedRoute path="/profile" exact component={Profile} />
                <Route path="/blog" exact component={Blog} />
                <Route
                  path="/blog/category/:slug"
                  exact
                  component={BlogCategory}
                />
                <Route path="/blog/tag/:slug" exact component={BlogTag} />
                <Route
                  path="/blog-details/:slug"
                  exact
                  component={BlogDetails}
                />
                <Route path="/login" exact component={Login} />
                <Route path="/register" exact component={Register} />
                <ProtectedRoute path="/wishlist" exact component={Wishlist} />

                {/* Footer Pages */}
                {/* Footer Pages End*/}

                <Route path="/page/:slug" exact component={Page} />
              </GlobalStateProvider>
            </AuthStateProvider>
            <Route exact component={NotFound} />
          </Switch>
          <GlobalFooter/>
        </Router>
      </div>
    </StyleRoot>
  );
}

export default Radium(App);

// this is new branch navbarResponsive trying to push to remote
