import React,{useState,useContext,useEffect} from "react";
import Breadcumbs from "../../Components/Breadcumbs";

import "./Checkout.css";
import CheckOutArea from "./CheckOutArea";
import { useForm } from "react-hook-form";
import axios from "axios";
import { AuthContext } from "../../Context/AuthState";
import { CartContext } from "../../Context/GlobalContext/GlobalState";
import { url } from "../../Services/Url";


const Checkout = () => {
  const { register, handleSubmit, reset } = useForm();
  const { state } = useContext(AuthContext);
  const { cart } = useContext(CartContext);
  const [totalAmount, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);

  const config = {
    headers: { Authorization: `Bearer ${state.token}` },
  };


  useEffect(()=>{
    let res = cart
    ? cart.reduce(function (accumulator, currentValue) {
        return (
          accumulator +
          currentValue.special_price.amount * currentValue.quantity
        );
      }, 0)
    : "";

  setTotal(res);
  
  },[cart])

  const onSubmit = (data) => {
    axios.post(`${url}/api/v1/coupon`,data,config)
    .then((res)=>{
     if (totalAmount>res.data.minimum_spend.amount) {
       setDiscount(res.data.value.amount)
     }
    })
    .catch((err)=>{
      console.log(err.message);
    })
    reset();
  };

  return (
    <>
 
      <Breadcumbs />
      <div className="entry-header-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="entry-header-title text-center">
                <h2>Checkout</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  coupon-area-area-start  */}
      <div className="coupon-area " style={{ marginBottom: "70px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="coupon-accordion">
                  <h3> 
                      Have a Coupon?
                      <a className="btn btn-link"  style={{textDecoration:"none",color:"black",fontSize:"14px"}} data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                      <span>Click here to enter your code</span>
                      </a>
                  </h3>
                  <div className="collapse" id="collapseExample">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <p className="checkout-coupon">
                        <input type="text" placeholder="Coupon code" {...register("code")}/>
                        <input type="submit" value="Apply Coupon" />
                      </p>
                    </form>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  coupon-area-area-end  */}
      {/* checkout-area-start */}
      <CheckOutArea discount={discount} totalAmount={totalAmount} setDiscount={setDiscount}/>
      {/* checkout-area-end */}

    </>
  );
};

export default Checkout;
