import React,{useState,useEffect,useContext, Fragment} from "react";
import { Link,useHistory } from "react-router-dom";
import { AuthContext } from "../../Context/AuthState";
import {url} from "../../Services/Url"
import axios from "axios"
import { toast } from 'react-toastify';
const ProfileAddress = () => {
  const [address, setAddress] = useState([])
  const [info,setInfo]=useState({id:"",first_name:"",last_name:"",address_1:"",city:"",zip:"",country_name:"",state_name:""})
  const [myId,setId]=useState(null)




  const {state} = useContext(AuthContext)
  const[showAddress,setShowAddress]=useState(false)
  const config = {
    headers:{Authorization:`Bearer ${state.token}`}
  }

  useEffect(()=>{
    axios.get(`${url}/api/v1/user/addresses`,config)
    .then((res)=>
        setAddress(res.data),
    )
  },[])


  const getAddress = ()=>{
    axios.get(`${url}/api/v1/user/addresses`,config)
    .then((res)=>
        setAddress(res.data),
    )
  }

  const editSubmit = (id)=>{
    axios.get(`${url}/api/v1/user/addresses/${id}`,config)
    .then((res)=>
        setInfo(
          {
            id:res.data.id,
            first_name:res.data.first_name,
            last_name:res.data.last_name,
            address_1:res.data.address_1,
            city:res.data.city,
            zip:res.data.zip,
            state_name:res.data.state_name,
            country_name:res.data.country_name,
          })
    ).then(()=>setId(id))
    setShowAddress(true)
  }

  const deleteSubmit = (id)=>{
    axios.delete(`${url}/api/v1/user/addresses/${id}`,config)
    .then((res)=>{
      getAddress()
      toast.success("Successfully deleted",{autoClose:2000});
    })
  }

  const handleUpdateSubmit = (e)=>{
      e.preventDefault();
      const data = {
        first_name:info.first_name,
        last_name:info.last_name,
        address_1:info.address_1,
        city:info.city,
        zip:info.zip,
        country_name:info.country_name,
        state_name:info.state_name
      }
    
      axios.post(`${url}/api/v1/user/addresses/${myId}`,data,config)
      .then((res)=>{
        console.log(res.data);
        getAddress();
        toast.success("Successfully updated",{autoClose:2000});
      })
      setShowAddress(false)
  }

  return (
    <>
      {" "}
     
      <div className="tab-pane fade" id="address-edit" role="tabpanel">
      {!showAddress?
      <>      
      <div className="myaccount-content">
          <h5>Billing Address</h5>
          {address.map((item,index)=>( 
            <Fragment key={index}>
            <address key={index}>
            <p>
              <strong>{item.full_name}</strong>
            </p>
            <p>
             {item.address_1} <br />
              {item.city}, {item.zip},{item.country_name}
            </p>
            {/* <p>Mobile: (123) 456-7890</p> */}
          </address>
          
          <Link to="#" className="btn btn-sqr" onClick={()=>editSubmit(item.id)}>
            <i className="fa fa-edit"></i>
            Edit Address
          </Link>
          <Link to="#" className="btn btn-sqr" onClick={()=>deleteSubmit(item.id)}>
          <i className="far fa-trash-alt"></i>
            {" "}Delete Address
          </Link>
          </Fragment>
          
          ))}
        
        </div>
        </>:
        <>
            <div className="myaccount-content">
            <h5>Update Address </h5>
              <form onSubmit={handleUpdateSubmit}>
                 
                  <>
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-lg-6">
                                <label htmlFor="first_name" className="form-label" name="first_name">First Name</label>
                                <input type="text"  className="form-control" name="first_name" value={info.first_name} onChange={(e)=>setInfo({...info,first_name:e.target.value})}/>
                                {info.first_name === '' ? <span style={{color:"red"}}>First Name Required</span>:""}
                            </div>
                            <div className="col-lg-6">
                                <label htmlFor="last_name" className="form-label" name="last_name">Last Name</label>
                                <input type="text" className="form-control" name="last_name" value={info.last_name}  onChange={(e)=>setInfo({...info,last_name:e.target.value})}/>
                                {/* {errors.last_name?.type === 'required' && <span style={{color:"red"}}>Last Name is required</span>} */}
                                {info.last_name === '' ? <span style={{color:"red"}}>Last Name Required</span>:""}
                            </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="street_address" className="form-label" name="address_1">Street Address</label>
                      <input type="text"  className="form-control" name="address_1" value={info.address_1} onChange={(e)=>setInfo({...info,address_1:e.target.value})}/>
                      {info.address_1 === '' ? <span style={{color:"red"}}>Street Address Required</span>:""}
                    </div>
                  <div className="row">
                    <div className="col-lg-6">
                        <label htmlFor="city" className="form-label" name="city">City</label>
                        <input type="text" className="form-control" name="city" value={info.city}   onChange={(e)=>setInfo({...info,city:e.target.value})}/>
                        {info.city === '' ? <span style={{color:"red"}}>City Required</span>:""}
                    </div>
                    <div className="col-lg-6">
                        <label htmlFor="zip" className="form-label" name="zip">PostCode</label>
                        <input type="text" className="form-control" name="zip" value={info.zip}  onChange={(e)=>setInfo({...info,zip:e.target.value})}/>
                        {info.zip === '' ? <span style={{color:"red"}}>Zip Required</span>:""}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                        <label htmlFor="country_name"  className="form-label" name="country_name">Country</label>
                        <input type="text" className="form-control" name="country_name"  value={info.country_name} onChange={(e)=>setInfo({...info,country_name:e.target.value})}/>
                        {info.country_name === '' ? <span style={{color:"red"}}>Country Name is Required</span>:""}
                    </div>
                    <div className="col-lg-6">
                        <label htmlFor="state_name" className="form-label" name="state_name">State</label>
                        <input type="text" className="form-control" name="state_name" value={info.state_name}  onChange={(e)=>setInfo({...info,state_name:e.target.value})}/>
                        {info.state_name === '' ? <span style={{color:"red"}}>State Name is Required</span>:""}
                    </div>
                  </div>
                  </>
                
                
                  <div className="single-register mt-2">
                      <input type="submit" style={{color:"#fff",background:"#259030"}} value="Update" onClick={handleUpdateSubmit}/>
                  </div>
              </form>
            </div>
        </>
        }
  
      </div>
    </>
  );
};

export default ProfileAddress;
