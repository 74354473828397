export const AuthorsData = [
  {
    id: 1,
    author: "মুনজেরিন শহীদ",
    image: "/img/authors/1.jpg",
  },
  {
    id: 2,
    author: "হুমায়ুন আহমেদ",
    image: "/img/authors/2.jpg",
  },
  {
    id: 3,
    author: "আরিফ আজাদ",
    image: "/img/authors/3.jpg",
  },
  {
    id: 4,
    author: "মুহম্মদ জাফর ইকবাল",
    image: "/img/authors/4.jpg",
  },
  {
    id: 5,
    author: "আহমদ ছফা",
    image: "/img/authors/5.jpg",
  },
  {
    id: 6,
    author: "ডেল কার্নেগী",
    image: "/img/authors/6.jpg",
  },
  {
    id: 7,
    author: "পাওলো কোয়েলহো",
    image: "/img/authors/7.jpg",
  },
  {
    id: 8,
    author: "মিজানুর রহমান আজহারি",
    image: "/img/authors/8.jpg",
  },
  {
    id: 9,
    author: "অনুপম হায়াৎ",
    image: "/img/author.png",
  },
  {
    id: 10,
    author: "আফরোজা ডেইজী",
    image: "/img/author.png",
  },
  {
    id: 11,
    author: "এ উইমার",
    image: "/img/author.png",
  },
  {
    id: 12,
    author: "এ কে শেরাম",
    image: "/img/author.png",
  },
  {
    id: 13,
    author: "আতাউর রহমান মজুমদার",
    image: "/img/author.png",
  },
  {
    id: 14,
    author: "আবদুল্লাহ আল মামুন",
    image: "/img/authors/author.png",
  },
  {
    id: 15,
    author: "অনিরুদ্ধ রায়",
    image: "/img/authors/author.png",
  },
  {
    id: 16,
    author: "অ্যান লেথব্রিজ",
    image: "/img/authors/author.png",
  },
  {
    id: 17,
    author: "অ্যামি মরিন",
    image: "/img/authors/author.png",
  },
  {
    id: 18,
    author: "অ্যালেন হারপার",
    image: "/img/authors/author.png",
  },
  {
    id: 19,
    author: "এ কে শেরাম",
    image: "/img/authors/author.png",
  },
  {
    id: 20,
    author: "আতাউর রহমান মজুমদার",
    image: "/img/authors/author.png",
  },
  {
    id: 21,
    author: "আবদুল্লাহ আল মামুন",
    image: "/img/authors/author.png",
  },
  {
    id: 22,
    author: "অনিরুদ্ধ রায়",
    image: "/img/authors/author.png",
  },
  {
    id: 23,
    author: "অ্যান লেথব্রিজ",
    image: "/img/authors/author.png",
  },
  {
    id: 24,
    author: "অ্যামি মরিন",
    image: "/img/authors/author.png",
  },
  
];
