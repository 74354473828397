import React from 'react'
import {Link} from "react-router-dom"
const BlogArchive = () => {
    return (
        <>
            <div className="single-blog" style={{marginBottom:"30px"}}>
							<div className="blog-left-title">
								<h3>Archive</h3>
							</div>
							<div className="blog-side-menu">
								<ul>
									<li><Link to="#"><i className="fa fa-calendar-o"></i>March 2016 (1)</Link></li>
									<li><Link to="#"><i className="fa fa-calendar-o"></i>April 2016 (2)</Link></li>
									<li><Link to="#"><i className="fa fa-calendar-o"></i>May 2016 (3)</Link></li>
									<li><Link to="#"><i className="fa fa-calendar-o"></i>June 2016 (4)</Link></li>
									<li><Link to="#"><i className="fa fa-calendar-o"></i>July 2016 (5)</Link></li>
									<li><Link to="#"><i className="fa fa-calendar-o"></i>August 2016 (6)</Link></li>
								</ul>
							</div>
						</div>
        </>
    )
}

export default BlogArchive
