import React,{useContext} from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../Context/AuthState";
const StickyWishlist = () => {
  const { state, dispatch } = useContext(AuthContext);
  const { wishList } = useContext(AuthContext);
  return (
    <>
      <div>
        <section className="floating-button floating-button-renew-2">
          <div className="btn-cart resize" style={{ padding: "0px 8px" }}>
            <Link to="/wishlist">
              <div className="count-wrapper" style={{ position: "relative" }}>
                <img src="/img/Boi-Bazaar/wishlist.png" alt="" width="26px" />
                <span
                  style={{
                    color: "white",
                    textDecoration: "none",
                    background: "#259030 none repeat scroll 0 0",
                    borderRadius: "50%",
                    bottom: "16px",
                    display: "inline-block",
                    fontSize: "18px",
                    height: "25px",
                    left: "23px",
                    lineHeight: "25px",
                    position: "absolute",
                    textAlign: "center",
                    width: "25px",
                  }}
                >
                   {state.isAuthenticated ? wishList.length : "0"}
                </span>
              </div>
            </Link>
            <p
              className="amount"
              style={{
                fontSize: "12px",
                letterSpacing: "-0.5px",
                paddingTop: "2px",
                fontWeight: "700",
                color: "#333",
              }}
            >
              WishList
            </p>
          </div>
        </section>
      </div>
    </>
  );
};

export default StickyWishlist;
