export const url = (link) => {
    if(link.type === 'url')
        if(link.url !== '/')
            return `/${link.url}`;
        else
            return '/';

    if(link.type === 'page')
        return `/page/${link.url}`;

    if(link.type === 'blog')
        return `/blogs/${link.url}`;

    if(link.type === 'author')
        return `/books/authors/${link.url}/${link.item.name.replace(/\s+/g, '-').toLowerCase()}`;
    
    if(link.type === 'category')
        return `/books/categories/${link.url}`;

    if(link.type === 'publisher')
        return `/books/publishers/${link.url}`
    
    return `/books/${link.type}/${link.url}`;
}