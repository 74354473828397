import axios from "axios";
import React,{useEffect,useState} from "react";
import { Link } from "react-router-dom";
import {url} from "../Services/Url"
import MultirowSlickSlider from "./MultirowSlickSlider";
import MultiThreeRowSlickSlider from "./MultiThreeRowSlickSlider";
const ThreeCatagoryBooks = () => {
  const [sliderOne,setSliderOne]=useState([])
  const [sliderOneBooks,setSliderOneBooks]=useState([])
  const [sliderTwo,setSliderTwo]=useState([])
  const [sliderTwoBooks,setSliderTwoBooks]=useState([])
  const [sliderThree,setSliderThree]=useState([])
  const [sliderThreeBooks,setSliderThreeBooks]=useState([])

  useEffect(()=>{
    const fetchRecentSold = async()=>{
      await axios.get(`${url}/api/v1/homepage/recently-sold`)
      .then((res)=>{
        setSliderOne(res.data);
        setSliderOneBooks(res.data.products)
      })
    }

    const fetchBestSeller = async ()=>{
      await axios.get(`${url}/api/v1/homepage/weekly-best-seller`)
      .then((res)=>{
        setSliderTwo(res.data);
        setSliderTwoBooks(res.data.products)
      })
      .catch((err)=>console.log(err.message))
    }

    const fetchLatestRated = async () =>{
      await axios.get(`${url}/api/v1/homepage/latest-rated-product`)
      .then((res)=>{
        setSliderThree(res.data);
        setSliderThreeBooks(res.data.products)
      })
      .catch((err)=>{
        console.log(err.message);
      })
    }


    fetchRecentSold();
    fetchBestSeller();
    fetchLatestRated();
  },[])

  return (
    <>
      <div
        className="most-product-area"
        style={{ paddingTop: "90px", paddingBottom: "100px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-12 sliderOne">
              <div className="section-title-2" style={{ marginBottom: "30px" }}>
                <h3 style={{fontFamily:"Hind Siliguri"}}>{sliderOne.title}</h3>
              </div>

              <MultiThreeRowSlickSlider books={sliderOneBooks} />
            </div>
            <div className="col-lg-3 col-md-4 col-12 sliderTwo">
              <div className="section-title-2" style={{ marginBottom: "30px" }}>
                <h3>{sliderTwo.title} </h3>
              </div>
       
              <MultiThreeRowSlickSlider books={sliderTwoBooks}/>
            </div>
            <div className="col-lg-3 col-md-4 col-12 sliderThree">
              <div
                className="section-title-2 "
                style={{ marginBottom: "30px" }}
              >
                <h3>{sliderThree.title}</h3>
              </div>
            
              <MultiThreeRowSlickSlider books={sliderThreeBooks}/>
            </div>
            <div className="col-lg-3 col-md-12 col-12">
              <div
                className="block-newsletter"
                style={{
                  backgroundImage:
                    "url(" + "img/Boi-Bazaar/Newslatter-background.jpg" + ")",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundAttachment: "scroll",
                }}
              >
                <h2>Sign up for send newsletter</h2>
                <p>You can be always up to date with our company new!</p>
                <form action="#">
                  <input type="text" placeholder="Enter your email address" />
                </form>
                <Link to="#">Send Email</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThreeCatagoryBooks;
