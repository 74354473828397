import axios from "axios";
import React, { useEffect, useState } from "react";
import BookLists from "../Components/BookLists";

import LeftFilterBookList from "../Components/LeftFilterBookList";
import StickyButton from "../Components/StickyButton";
import { url } from "../Services/Url";

const AllBookList = () => {
  const[data,setData]= useState([])

  const fetchData = async () => {
    await axios.get(`${url}/api/v1/products`)
      .then((res) => {
        setData(res.data)
      })
      .catch((err) => {
        console.log(err.message);
      })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>

      <StickyButton />

      <div className="shop-main-area mt-5" style={{ marginBottom: "70px" }}>
        <div className="container">
          <div className="row">
            <LeftFilterBookList />
            {/* LeftFilterBookList->BookLists->TabBook->Pagination */}
            {/* TabBook->SingleListBook */}
            {/* TabBook->SingleGridBook */}
            <BookLists data={data} />
          </div>
        </div>
      </div>

    </>
  );
};

export default AllBookList;
