import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Breadcumbs from "../../Components/Breadcumbs";

import "./Cart.css";
import { CartContext } from "../../Context/GlobalContext/GlobalState";
import StickyButton from "../../Components/StickyButton";


const Cart = () => {
  const {
    cart,
    updatedQuantity,
    addToCart,
    decrementQuantity,
    incrementQuantity,
    removeToCart,
  } = useContext(CartContext);
  const [myCart, setMyCart] = useState([]);
  const [totalAmount, setTotal] = useState(0);

  useEffect(() => {
    setMyCart(cart);
    let res = cart
      ? cart.reduce(function (accumulator, currentValue) {
          return (
            accumulator +
            currentValue.special_price.amount * currentValue.quantity
          );
        }, 0)
      : "";
    setTotal(res);
  }, [cart]);


  return (
    <>

      <StickyButton />
    
      <Breadcumbs />
      <div className="entry-header-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="entry-header-title text-center">
                <h2 style={{ fontWeight: "bold" }}>Cart</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cart-main-area " style={{ marginBottom: "70px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <form action="#">
                <div
                  className="table-content table-responsive mb-15 border-1"
                  style={{ marginBottom: "15px" }}
                >
                  <table>
                    <thead>
                      <tr>
                        <th className="product-thumbnail">Image</th>
                        <th className="product-name">Product</th>
                        <th className="product-price">Price</th>
                        <th className="product-quantity">Quantity</th>
                        <th className="product-subtotal">Total</th>
                        <th className="product-remove">Remove</th>
                      </tr>
                    </thead>
                    {myCart && myCart.length > 0
                      ? myCart.map((item,index) => (
                          <tbody key={index}>
                            <tr>
                              <td className="product-thumbnail">
                                <Link to="#">
                                  <img
                                    src={
                                      item.files.length
                                        ? item.base_image.path
                                        : "/img/product/1.jpg"
                                    }
                                    alt="man"
                                    height="100"
                                  />
                                </Link>
                              </td>
                              <td className="product-name">
                                <Link to="#">{item.name}</Link>
                              </td>
                              <td className="product-price">
                                <span className="amount">
                                  Tk.{" "}
                                  {parseFloat(
                                    item.special_price.amount
                                  ).toFixed(2)}
                                </span>
                              </td>
                              <td className="product-quantity">
                                <div class="input-group input-group-sm mb-3 mt-2">
                                  <div class="input-group-prepend">
                                    <span
                                      class="input-group-text"
                                      id="inputGroup-sizing-sm"
                                      onClick={() => {
                                        if (item.quantity > 1) {
                                          decrementQuantity(item);
                                        }
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i class="fas fa-minus"></i>
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    class="form-control"
                                    aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"
                                    style={{
                                      width: "20px",
                                      background: "white",
                                      border: "1px solid #c5c7c9",
                                    }}
                                    value={item.quantity}
                                    onChange={(e) =>
                                      updatedQuantity(item, e.target.value)
                                    }
                                  />

                                  <div class="input-group-prepend">
                                    <span
                                      class="input-group-text"
                                      id="inputGroup-sizing-sm"
                                      onClick={() => {
                                        incrementQuantity(item);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i class="fas fa-plus"></i>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td className="product-subtotal">
                                TK.{" "}
                                {parseFloat(
                                  item.quantity * item.special_price.amount
                                ).toFixed(2)}
                              </td>
                              <td className="product-remove">
                                <Link
                                  to="#"
                                  onClick={() => {
                                    removeToCart(item);
                                  }}
                                >
                                  <i className="fa fa-times"></i>
                                </Link>
                              </td>
                            </tr>
                          </tbody>
                        ))
                      : ""}
                  </table>
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-6 col-12">
              <div className="buttons-cart" style={{ marginBottom: "30px" }}>
                <ul>
                  {/* <li>
                    <Link to="#">Update Cart</Link>
                  </li> */}
                  <li>
                    <Link to="/">Continue Shopping</Link>
                  </li>
                </ul>
              </div>
              <div className="coupon">
                <h3>Coupon</h3>
                <p>Enter your coupon code if you have one.</p>
                <form action="#">
                  <input type="text" placeholder="Coupon code" />
                  <Link to="#">Apply Coupon</Link>
                </form>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="cart_totals">
                {/* <h2>Cart Totals</h2> */}
                <table>
                  <tbody>
                    <tr className="order-total mt-2">
                      <th>Total</th>
                      <td>
                        <strong>
                          <span className="amount">TK. {totalAmount}</span>
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {myCart && myCart.length > 0 ? (
                  <div className="wc-proceed-to-checkout">
                    <Link to="/checkout">Proceed to Checkout</Link>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </>
  );
};

export default Cart;
