import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthorsData } from "../Services/AuthorsData";
import AuthorsPagination from "./AuthorsPagination";
const AuthorsList = () => {
  let location = useLocation();
  let checkLocation = location.pathname;
  const [loading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [authorsPerPage] = useState(12);
  const newAuthorsData = [...AuthorsData].reverse();
  const indexOfLastAuthor = currentPage * authorsPerPage;
  const indexOfFirstAuthor = indexOfLastAuthor - authorsPerPage;
  const currentAuthor = newAuthorsData.slice(
    indexOfFirstAuthor,
    indexOfLastAuthor
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);
  return (
    <>
      <div className="container mt-5 mb-5">
        <div className="container">
          <hr />
          <div className="row" style={{ justifyContent: "center", alignItems: "center" }}>
            {currentAuthor.map((data, index) => (
              <div className="col-sm-2" key={index} style={{ marginLeft: 0 }}>
                <Link
                  to="#"
                  style={{ textDecoration: "none", color: "#c7c7c7" }}
                  className="circle-link"
                >
                  <img
                    className="card-img-top ml-2"
                    id="circle-img"
                    src={
                      checkLocation === "/"
                        ? data.image
                        : "/img/author.png"
                    }
                    alt=""
                    style={{
                      borderRadius: "50%",
                      border: "1px solid #c7c7c7",
                      width: "140px",
                      height: "140px",
                    }}
                  />
                </Link>
                <div className="card-body text-center">
                  <Link
                    to="#"
                    className="authors-title card-title"
                    style={{ textDecoration: "none", color: "#333" }}
                  >
                    {data.author}
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <AuthorsPagination
            authorsPerPage={authorsPerPage}
            totalAuthors={AuthorsData.length}
            loading={loading}
            paginate={paginate}
            nextPage={nextPage}
            prevPage={prevPage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </>
  );
};

export default AuthorsList;
