import React from "react";
import { Link } from "react-router-dom";
import { url, local } from "../Services/Url";
import axios from 'axios';

class PromotionBooks extends React.Component {

  constructor() {
    super();
    this.state = {
      banners: {},
      isReady: false
    }
  }

  componentDidMount() {
    axios.get(`${url}/api/v1/homepage/two-column-banner`)
      .then(response => {
        if (response.status === 200) {
          this.setState({
            banners: response.data,
            isReady: true
          });
        }
      })
  }


  render() {

    if (this.state.isReady) {

      return (
        <>
          <div
            className="banner-static-area bg mb-5"
            style={{
              padding: "100px 0",
              backgroundImage:
                "url(" + "img/Boi-Bazaar/Promotional-Campaign-Background.jpg" + ")",
              backgroundPosition: "center",
              backgroundAttachment: "scroll",
              backgroundRepeat: "repeat",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="banner-shadow-hover xs-mb">
                    <Link to={this.state.banners.one.url?this.state.banners.one.url:""}>
                      <img
                        src={this.state.banners.one.file}
                        style={{ width: "100%" }}
                        alt="banner"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="banner-shadow-hover">
                    <Link to={this.state.banners.two.url?this.state.banners.two.url:""}>
                      <img
                        src={this.state.banners.two.file}
                        style={{ maxWidth: "100%" }}
                        alt="banner"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>

        </>
      );
    }
  }
};

export default PromotionBooks;
