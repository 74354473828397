import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import {  local } from "../Services/Url";
import { url as LinkUrl } from '../Services/LinkGenerator';

class GlobalFooter extends React.Component {

  constructor()
  {
    super();

    this.state = {
      footer_menu_one: {},
      footer_menu_two: {},
      footer_menu_three: {},
      contacts: {},
      isLoading: true,
      isContact: false
    };
  }

  componentDidMount() {
    axios.get(`${local}/navigation/footers`)
    .then(response => {
      
      this.setState({
        footer_menu_one: response.data.footer_menu_one,
        footer_menu_two: response.data.footer_menu_two,
        footer_menu_three: response.data.footer_menu_three,
        isLoading: false
      });
    })

    axios.get(`${local}/informations`)
    .then(response => {
      this.setState({
        contacts: response.data,
        isContact: true
      });
    })
  }

  render() {

    return (
      <>
        <footer>
          {/* footer-mid-start */}
          <div className="footer-mid" style={{ padding: "50px 0" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-12">
                  {(this.state.isLoading) ? (<></>) : (

                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="single-footer br-2 xs-mb">
                        <div
                          className="footer-title"
                          style={{ marginBottom: "20px" }}
                        >
                          <h3>{this.state.footer_menu_one.title}</h3>
                        </div>
                        <div className="footer-mid-menu">
                          <ul>
                            
                            {this.state.footer_menu_one.menus.map((item, index) => (
                              <li key={index}>
                                <Link to={LinkUrl({type: item.type, url: item.url, item})}>{item.name}</Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="single-footer br-2 xs-mb">
                        <div
                          className="footer-title"
                          style={{ marginBottom: "20px" }}
                        >
                          <h3>{this.state.footer_menu_two.title}</h3>
                        </div>
                        <div className="footer-mid-menu">
                          <ul>
                            {this.state.footer_menu_two.menus.map((item, index) => (
                              <li key={index}>
                                <Link to={LinkUrl({type: item.type, url: item.url, item})}>{item.name}</Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                      <div className="single-footer br-2 xs-mb">
                        <div
                          className="footer-title"
                          style={{ marginBottom: "20px" }}
                        >
                          <h3>{this.state.footer_menu_three.title}</h3>
                        </div>
                        <div className="footer-mid-menu">
                          <ul>
                            {this.state.footer_menu_three.menus.map((item, index) => (
                              <li key={index}>
                                <Link to={LinkUrl({type: item.type, url: item.url, item})}>{item.name}</Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  )}
                </div>
                <div className="col-lg-4 col-md-12">
                  <div className="single-footer  mrg-sm">
                    <div
                      className="footer-title"
                      style={{ marginBottom: "20px" }}
                    >
                      <h3>Contact us</h3>
                    </div>
                    {(this.state.isContact) ? (
                      <div className="footer-contact">
                        <p className="adress">
                          <span>{this.state.contacts.store_name}</span>
                          {this.state.contacts.store_address_1}, &nbsp;
                          {this.state.contacts.store_address_2}
                        </p>
                        <p>
                          <span>Call us now:</span> {this.state.contacts.store_phone}
                        </p>
                        <p>
                          <span>Email:</span> {this.state.contacts.store_email}
                        </p>
                      </div>
                    ) : (
                      <div className="footer-contact">
                      </div>
                    )}
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* footer-mid-end */}
          {/* footer-bottom-start */}
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12  bt-2">
                <div className="copy-right-area">
                  <p>
                    &copy; <> Copyright 2021 </>
                    <Link
                      to="/"
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      <strong>BoiBazaar</strong>
                    </Link>{" "}
                    by
                    <strong> ibusiness</strong>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12 text-right">
                <div className="link-follow">
                  <ul style={{ paddingLeft: "0px" }}>
                    <li>
                      <Link to="#">
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-youtube"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
  
          {/* footer-bottom-end */}
        </footer>
      </>
    );
  }
};

export default GlobalFooter;
