import React from 'react'
import {Link} from "react-router-dom"
import BlogComment from './BlogComment'
import PostBlogComment from './PostBlogComment'
import Loader from "../../Components/Loader";
import ReactHtmlParser from "react-html-parser";


const BlogPostDetails = (props) => {

	const post = props.post;

	// Format API response date to human readable.
	const formatDate = (dateString) => {
		const options = { year: "numeric", month: "long", day: "numeric" }
		return new Date(dateString).toLocaleDateString(undefined, options)
	}


	// Convert HTML description to plain text and limit character
	const convertToText = (html, length = 200) => {
		let tempDivElement = document.createElement("div");

		tempDivElement.innerHTML = html;

		let string = tempDivElement.textContent || tempDivElement.innerText || "";

		return string.substring(0, length) + "...";
	}

	// Return the comments inforamtion
	const comments = (comment) => {
		if(comment === 0)
			return 'No Comments';

		if(comment === 1)
			return "1 Comment";

		return `${comment} Comments`;
	}

	if(props.isLoading)
	{

		return <>
		<div className="col-lg-9 col-md-12 col-12 order-lg-2 order-1">
			<div className="blog-main-wrapper" style={{ textAlign: "center", marginTop: "50px" }}>
				<Loader />
			</div>
		</div>
	</>

	}else{
		return (
			<>
				<div className="col-lg-9 col-md-12 col-12 order-lg-2 order-1">
					<div className="blog-main-wrapper">
					<div className="author-destils mt-5" style={{marginBottom:"30px"}}>
							<div className="author-left">
									<div className="author-img">
											<Link to="#"><img src="../img/author/1.jpg" alt="man" style={{maxWidth:"100%"}}/></Link>
									</div>
									<div className="author-description">
										<p>Posted by: 
											<Link to="#"  style={{textDecoration:"none"}}><span>{ post.author }</span>in</Link>
											{props.categories.map((item, index) => (
												<Link to={`/blog/category/${item.slug}`} key={index} style={{textDecoration:"none"}}> {item.name}{(props.categories.length === index+1) ? '' : ','} </Link>
											))}
										</p>
											<span>{ formatDate(post.created_at) }</span>
									</div>
							</div>
							<div className="author-right">
								<span>Share this:</span>
									<ul>
										<li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
										<li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
										<li><Link to="#"><i className="fab fa-linkedin-in"></i></Link></li>
									</ul>
							</div>
						</div>
	
						<div className="blog-img" style={{marginBottom:"30px"}}>
								{(post.image.length === 0) ? '' : <Link to={`/blog-details/${post.slug}`}><img style={{ width: "100%", height: "auto" }} src={post.image.path} alt="blog" /></Link>}
								{/* <Link to="#"><img src="img/blog/1.jpg" alt="blog" /></Link> */}
						</div>
	
						<div className="single-blog-content">
								<div className="single-blog-title">
									<h3>{ post.name }</h3>
								</div>
								<div className="blog-single-content">
									{ReactHtmlParser(post.description)}
								</div>
						</div>
						<div className="comment-tag">
								<p>{comments(props.comments.length)}/Tags: {props.tags.map((item, index) => (
												<Link to={`/blog/tag/${item.slug}`} key={index} style={{textDecoration:"none"}}> {item.name}{(props.tags.length === index+1) ? '' : ','} </Link>
											))} </p>
						</div>
	
						<div className="sharing-post " style={{marginTop:"20px"}}>
								<div className="share-text">
									<span>Share this post</span>
								</div>
								<div className="share-icon">
									<ul>
										<li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
										<li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
										<li><Link to="#"><i className="fab fa-linkedin-in"></i></Link></li>
									</ul>
								</div>
						</div>
	
						<div className="comment-title-wrap" style={{marginTop:"30px"}}>
								<h3>{comments(props.comments.length)}</h3>
						</div>
	
						<BlogComment comments={props.comments} />
	
						<div className="comment-title-wrap " style={{marginTop:"30px"}}>
							<h3>Leave a comment </h3>
						</div>
						<PostBlogComment submitFromComment={props.submitFromComment} slug={props.slug} />
						
					</div>
				</div>
			</>
		)
	}

}

export default BlogPostDetails
