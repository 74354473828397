import React,{useContext} from "react";
import { Link } from "react-router-dom";
import Breadcumbs from "../../Components/Breadcumbs";

import { AuthContext } from "../../Context/AuthState";
import { ScaleLoader } from "react-spinners";
import "./Wishlist.css";
import { CartContext } from "../../Context/GlobalContext/GlobalState";


const loaderStyle = { textAlign: "center" };
const Wishlist = () => {
  const {wishList,loading,deleteWishList} = useContext(AuthContext)
  const { addToCart } = useContext(CartContext);
  return (
    <>
  
      <Breadcumbs />
      <div className="entry-header-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="entry-header-title text-center">
                <h2 style={{ fontWeight: "bold" }}>Wishlist</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cart-main-area " style={{ marginBottom: "120px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="wishlist-content">
                <form action="#">
                  <div className="wishlist-table table-responsive">
                   {loading?( 
                    <div style={loaderStyle}>
                      <ScaleLoader color="#24902f" />
                    </div>):
                    <>         
                     {wishList && wishList.length>0
                      ?<table>
                      <thead>
                        <tr>
                          <th className="product-thumbnail">Image</th>
                          <th className="product-name">Product Name</th>
                          <th className="product-price">Unit Price </th>
                          <th className="product-stock-stauts">
                            Stock Status{" "}
                          </th>
                          <th className="product-subtotal">Add to Cart</th>
                          <th className="product-subtotal">Remove</th>
                        </tr>
                      </thead>
                     
                      <tbody>
                      {wishList.map((item,index)=>(
                        <React.Fragment key={index}>
                        <tr key={index}>
                          <td className="product-thumbnail">
                            <Link to="#">
                              <img src={item.base_image?item.base_image.path:"/img/product/1.jpg"} alt="man" height="100" />
                            </Link>
                          </td>
                          <td className="product-name">
                            <Link to="#">{item.name}</Link>
                          </td>
                          <td className="product-price">
                            <span className="amount">Tk. {parseFloat(item.special_price.amount).toFixed(2)}</span>
                          </td>
                          <td className="product-stock-status">
                            <span className="wishlist-in-stock">{item.in_stock!=='false'?"In Stock":""}</span>
                          </td>
                          <td className="">
                            <Link className="btn btn-success" to="#"  onClick={() => addToCart(item)}>
                              <i className="fas fa-shopping-cart"></i>
                            </Link>{" "}
                          </td>
                          <td className="">
                            <Link className="btn btn-danger" to="#" onClick={()=>deleteWishList(item.id)}>
                              {" "}
                              <i
                                className="far fa-trash-alt"
                                style={{ color: "white" }}
                              ></i>
                            </Link>
                          </td>
                        </tr>
                        </React.Fragment>
                      ))}
                    
                      </tbody>
                    </table>
                    :<><h1 className="text-center" style={{marginTop:"100px"}}>no Data is Available</h1></>}
                       
               </>}
                    {/* {wishList.length!==0?"":<p className="text-center" style={{fontWeight:"bold"}}>You have no items in your wish list.</p>} */}
                
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default Wishlist;
