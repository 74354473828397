import React from 'react'
import {Link} from "react-router-dom";
const InfoBar = () => {
    return (
        <>
            <div className="banner-area banner-res-large " style={{paddingTop:"30px",paddingBottom:"5px"}}>
                <div className="container info-container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="single-banner " style={{marginBottom:"30px"}}>
                                <div className="banner-img">
                                    <Link to="#"><img src="img/banner/1.png" alt="banner" /></Link>
                                </div>
                                <div className="banner-text">
                                    <h4>Free shipping item</h4>
                                    <p>For all orders over $500</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="single-banner" style={{marginBottom:"30px"}}>
                                <div className="banner-img">
                                    <Link to="#"><img src="img/banner/2.png" alt="banner" /></Link>
                                </div>
                                <div className="banner-text">
                                    <h4>Money back guarantee</h4>
                                    <p>100% money back guarante</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="single-banner" style={{marginBottom:"30px"}}>
                                <div className="banner-img">
                                    <Link to="#"><img src="img/banner/3.png" alt="banner" /></Link>
                                </div>
                                <div className="banner-text">
                                    <h4>Cash on delivery</h4>
                                    <p>Lorem ipsum dolor consecte</p>
                                </div>
                            </div>
                            </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="single-banner " style={{marginBottom:"30px"}}>
                                <div className="banner-img">
                                    <Link to="#"><img src="img/banner/4.png" alt="banner" /></Link>
                                </div>
                                <div className="banner-text">
                                    <h4>Help & Support</h4>
                                    <p>Call us : + 0123.4567.89</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InfoBar
