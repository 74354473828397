import React,{useState} from "react";
import Breadcumbs from "../../Components/Breadcumbs";

import "./Profile.css";
import ProfileAccountDetails from "./ProfileAccountDetails";
import ProfileAddress from "./ProfileAddress";
import ProfileDashboard from "./ProfileDashboard";
import ProfileDownloads from "./ProfileDownloads";
import ProfileOrders from "./ProfileOrders";
import ProfilePaymentMethod from "./ProfilePaymentMethod";
const Profile = () => {
  const [clicking,setClicking]=useState(false)
  const [userData,setUserData] = useState({first_name:"",last_name:"",email:"",phone:"",password:"",confirm_password:""})


  return (
    <>
    
      <Breadcumbs />
      <div className="my-account-wrapper mb-70">
        <div className="container">
          <div className="section-bg-color">
            <div className="row">
              <div className="col-lg-12">
                <div className="myaccount-page-wrapper">
                  <div className="row">
                    <div className="col-lg-3 col-md-4">
                      <div className="myaccount-tab-menu nav" role="tablist">
                        <a
                          href="#dashboad"
                          className="active"
                          data-toggle="tab"
                          onClick={()=>{ setClicking(false)}}
                        >
                          {"  "}
                          <i className="fas fa-tachometer-alt"></i> Dashboard
                        </a>
                        <a href="#orders" data-toggle="tab" onClick={()=>{ setClicking(false)}}>
                          <i className="fa fa-cart-arrow-down"></i>
                          Orders
                        </a>
                        <a href="#download" data-toggle="tab"  onClick={()=>{ setClicking(false)}}>
                          <i className="fa fa-download" aria-hidden="true"></i>
                          Download
                        </a>
                        <a href="#payment-method" data-toggle="tab"  onClick={()=>{ setClicking(false)}}>
                          <i className="fa fa-credit-card"></i>
                          Payment Method
                        </a>
                        <a href="#address-edit" data-toggle="tab"  onClick={()=>{ setClicking(false)}}>
                          <i className="fa fa-map-marker"></i>
                          address
                        </a>
                        <a href="#account-info" data-toggle="tab"  onClick={()=>{ setClicking(false)}}>
                          <i className="fa fa-user"></i> Account Details
                        </a>
                
                      </div>
                    </div>
                    <div className="col-lg-9 col-md-8">
                      <div className="tab-content" id="myaccountContent">
                        <ProfileDashboard data={userData}/>
                        <ProfileOrders setClicking={setClicking} clicking={clicking}/>
                        <ProfileDownloads />
                        <ProfilePaymentMethod />
                        <ProfileAddress />
                        <ProfileAccountDetails userData={userData} setUserData={setUserData}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default Profile;
