import React from "react";
import NotFoundList from "../Components/NotFoundList";
const NotFound = () => {
  return (
    <>
   
      <NotFoundList />
 
    </>
  );
};

export default NotFound;
