import React from "react";
import { Link } from "react-router-dom";
const MobileMenuBar = () => {
  return (
    <>
      <div className="mobile-menu-area d-lg-none d-block fix" id="mobileHeaderPrevious">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="mobile-menu">
                <nav id="mobile-menu-active">
                  <ul id="nav">
                    <li>
                      <Link to="#">Home</Link>
                      <ul>
                        <li>
                          <Link to="#">Home-1</Link>
                        </li>
                        <li>
                          <Link to="#">Home-2</Link>
                        </li>
                        <li>
                          <Link to="#">Home-3</Link>
                        </li>
                        <li>
                          <Link to="#">Home-4</Link>
                        </li>
                        <li>
                          <Link to="#">Home-5</Link>
                        </li>
                        <li>
                          <Link to="#">Home-6</Link>
                        </li>
                        <li>
                          <Link to="#">Home-7</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#">Book</Link>
                      <ul>
                        <li>
                          <Link to="#">Tops & Tees</Link>
                        </li>
                        <li>
                          <Link to="#">Polo Short Sleeve</Link>
                        </li>
                        <li>
                          <Link to="#">Graphic T-Shirts</Link>
                        </li>
                        <li>
                          <Link to="#">Jackets & Coats</Link>
                        </li>
                        <li>
                          <Link to="#">Fashion Jackets</Link>
                        </li>
                        <li>
                          <Link to="#">Crochet</Link>
                        </li>
                        <li>
                          <Link to="#">Sleeveless</Link>
                        </li>
                        <li>
                          <Link to="#">Stripes</Link>
                        </li>
                        <li>
                          <Link to="#">Sweaters</Link>
                        </li>
                        <li>
                          <Link to="#">hoodies</Link>
                        </li>
                        <li>
                          <Link to="#">Heeled sandals</Link>
                        </li>
                        <li>
                          <Link to="#">Polo Short Sleeve</Link>
                        </li>
                        <li>
                          <Link to="#">Flat sandals</Link>
                        </li>
                        <li>
                          <Link to="#">Short Sleeve</Link>
                        </li>
                        <li>
                          <Link to="#">Long Sleeve</Link>
                        </li>
                        <li>
                          <Link to="#">Polo Short Sleeve</Link>
                        </li>
                        <li>
                          <Link to="#">Sleeveless</Link>
                        </li>
                        <li>
                          <Link to="#">Graphic T-Shirts</Link>
                        </li>
                        <li>
                          <Link to="#">Hoodies</Link>
                        </li>
                        <li>
                          <Link to="#">Jackets</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="product-details.html">Audio books</Link>
                      <ul>
                        <li>
                          <Link to="shop.html">Tops & Tees</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Sweaters</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Hoodies</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Jackets & Coats</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Long Sleeve</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Short Sleeve</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Polo Short Sleeve</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Sleeveless</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Sweaters</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Hoodies</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Wedges</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Vests</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Polo Short Sleeve</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Sleeveless</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Graphic T-Shirts</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Hoodies</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="product-details.html">children’s books</Link>
                      <ul>
                        <li>
                          <Link to="shop.html">Shirts</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Florals</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Crochet</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Stripes</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Shorts</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Dresses</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Trousers</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Jeans</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Heeled sandals</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Flat sandals</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Wedges</Link>
                        </li>
                        <li>
                          <Link to="shop.html">Ankle boots</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="#">blog</Link>
                      <ul>
                        <li>
                          <Link to="blog.html">Blog</Link>
                        </li>
                        <li>
                          <Link to="blog-details.html">blog-details</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="product-details.html">Page</Link>
                      <ul>
                        <li>
                          <Link to="shop.html">shop</Link>
                        </li>
                        <li>
                          <Link to="shop-list.html">shop list view</Link>
                        </li>
                        <li>
                          <Link to="product-details.html">product-details</Link>
                        </li>
                        <li>
                          <Link to="product-details-affiliate.html">
                            product-affiliate
                          </Link>
                        </li>
                        <li>
                          <Link to="blog.html">blog</Link>
                        </li>
                        <li>
                          <Link to="blog-details.html">blog-details</Link>
                        </li>
                        <li>
                          <Link to="contact.html">contact</Link>
                        </li>
                        <li>
                          <Link to="about.html">about</Link>
                        </li>
                        <li>
                          <Link to="login.html">login</Link>
                        </li>
                        <li>
                          <Link to="register.html">register</Link>
                        </li>
                        <li>
                          <Link to="my-account.html">my-account</Link>
                        </li>
                        <li>
                          <Link to="cart.html">cart</Link>
                        </li>
                        <li>
                          <Link to="compare.html">compare</Link>
                        </li>
                        <li>
                          <Link to="checkout.html">checkout</Link>
                        </li>
                        <li>
                          <Link to="wishlist.html">wishlist</Link>
                        </li>
                        <li>
                          <Link to="404.html">404 Page</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenuBar;
