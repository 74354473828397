import React,{useContext} from "react";
import Breadcumbs from "../Components/Breadcumbs";
import CategoriesList from "../Components/CategoriesList";
import CategoryBanner from "../Components/CategoryBanner";

import StickyButton from "../Components/StickyButton";
import StickyWishlist from "../Components/StickyWishlist";
import { AuthContext } from "../Context/AuthState";
const Categories = () => {
  const {state} = useContext(AuthContext)
  return (
    <>
    
       {state.isAuthenticated ?   <StickyWishlist/> : ""}
      <StickyButton />
 
      <Breadcumbs />
      <CategoryBanner />
      <CategoriesList />

    </>
  );
};

export default Categories;
