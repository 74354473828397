import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { set } from 'react-hook-form';
import Breadcumbs from "../../Components/Breadcumbs";
import GlobalFooter from "../../Components/GlobalFooter";
import HeaderTopBar from "../../Components/HeaderTopBar";
import LogoBar2 from "../../Components/LogoBar2";
import MenuBar from "../../Components/MenuBar";
import { url, local } from "../../Services/Url";
import NotFoundList from "../../Components/NotFoundList";
import Loader from "../../Components/Loader";
import ReactHtmlParser from "react-html-parser";
import Navbar from '../../Components/NavbarNew/Navbar';
import NavMenu from '../../Components/NavMenu';

const Page = (props) => {

    const [page, setPage] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        setIsLoading(true);

        const slug = props.match.params.slug;

        axios.get(`${local}/pages/${slug}`)
            .then(response => {
                setPage(response.data)
                setIsLoading(false);
            })

    }, [props.match.params.slug])

    return (
        <>
            {/* <HeaderTopBar /> */}
            {/* <LogoBar2 />
            <MenuBar /> */}
            {/* <Navbar/>
            <NavMenu/> */}
            <Breadcumbs />
            <div className="about-me" style={{ marginBottom: "50px" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-12 order-lg-2 order-1">
                            {(isLoading) ? (<>
                                <div className="blog-main-wrapper" style={{ textAlign: "center", marginTop: "50px" }}>
                                    <Loader />
                                </div>
                            </>) : (    
                                <div className="blog-main-wrapper">
                                    <div
                                        className="author-destils mb-30"
                                        style={{ marginBottom: "30px" }}
                                    ></div>
                                    <div
                                        className="blog-img mb-30"
                                        style={{ marginBottom: "30px" }}
                                    >
                                        {(page.image?.path) ? (
                                            <img src={`${page.image.path}`} alt="blog" />
                                        ) : (
                                            <></>
                                        )}
                                        
                                    </div>
                                    <div className="single-blog-content">
                                        <div className="single-blog-title">
                                            <h3>{ page.name }</h3>
                                        </div>
                                        <div className="blog-single-content">
                                            {ReactHtmlParser(page.body)}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* <GlobalFooter /> */}
        </>
    );
}

export default Page;