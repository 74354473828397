import React, { useState, useEffect } from "react";
import BookSlide from "./BookSlide";
import { Data } from "../Services/Data";
import axios from "axios";
import { url } from "../Services/Url";
import Test from "../Components/Test";
import Test2 from "./Test2";
import HighlightedBooksTab from "./HighlightedBooksTab";
const HighlightedBooks = () => {

  const [books, setBooks] = useState([]);
  const[data,setData]=useState([])
  useEffect(()=>{
    const fetchData = async()=>{
      await axios.get(`${url}/api/v1/homepage/tabs/products`)
      .then((res)=>{
        setData(res.data)
      })
      .catch((err)=>{
        console.log(err.message);
      })
    }
    fetchData()
  },[])



  return (
    <>
      <div className="product-area mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center "
                style={{ marginBottom: "50px" }}
              >
                <h2>জনপ্রিয় বইসমুহ</h2>
                <p>
                  Browse the collection of our best selling and top interresting
                  products. <br /> ll definitely find what you are looking for..
                </p>
              </div>
            </div>
                <HighlightedBooksTab data={data}/>
          </div>
          {/* Audiobooks */}
          <div className="tab-content">
            <div className="tab-pane fade show active" id="Audiobooks">
        
              <Test2 data={data.tab_one}/>
            </div>
            {/* AudioBooks end */}
            {/* books */}
            <div className="tab-pane fade show " id="books">
       
              <Test2 data={data.tab_two}/>
            </div>
            {/* Books end */}
            {/* bussiness */}
            <div className="tab-pane fade show " id="bussiness">
        
              <Test2 data={data.tab_three}/>
            </div>
            {/* bussiness end */}
          </div>
        </div>
      </div>
    </>
  );
};

export default HighlightedBooks;
