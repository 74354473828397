import React, { useEffect,useState,useContext } from "react";
import HeroSlider from "../Components/HeroSlider";
import HighlightedBooks from "../Components/HighlightedBooks";
import PromotionBanner from "../Components/PromotionBanner";
import BestSeller from "../Components/BestSeller";
import PromotionBooks from "../Components/PromotionBooks";
import ThreeCatagoryBooks from "../Components/ThreeCatagoryBooks";
import Reviews from "../Components/Reviews";
import InfoBar from "../Components/InfoBar";
import LatestBlog from "../Components/LatestBlog";
import Test from "../Components/Test";
import DealsWeek from "../Components/DealsWeek";
import WeeklyTopAuthors from "../Components/WeeklyTopAuthors";
import StickyButton from "../Components/StickyButton";
import axios from "axios"
import {url} from "../Services/Url"
import StickyWishlist from "../Components/StickyWishlist";
import { AuthContext } from "../Context/AuthState";

const Home = () => {
  const[data,setData]=useState([])
  const {state} = useContext(AuthContext)
  useEffect(()=>{
    const fetchData = async()=>{
      await axios.get(`${url}/api/v1/homepage/section/products`)
      .then((res)=>{
        setData(res.data)
      })
      .catch((err)=>{
        console.log(err.message);
      })
    }
    fetchData()
  },[])

  return (
    <div>
   {state.isAuthenticated ?   <StickyWishlist/> : ""}
    
      <StickyButton />
  
      <HeroSlider />
      <InfoBar />

      <HighlightedBooks />

      <PromotionBanner />

      <Test data={data.section_one}/>
      <Test data={data.section_two}/>
      <Test data={data.section_three}/>
   
      <BestSeller />

      <Test data={data.section_four}/>
      <Test data={data.section_five}/>
      <Test data={data.section_six}/>
  
      <PromotionBooks />
      <Test data={data.section_seven}/>
      <Test data={data.section_eight}/>
      <Test data={data.section_nine}/>

      <DealsWeek />


      <Test data={data.section_ten}/>
      <Test data={data.section_eleven}/>
      <Test data={data.section_twelve}/>
      <WeeklyTopAuthors />
      <ThreeCatagoryBooks />
      <Reviews />

      <LatestBlog />

   
    </div>
  );
};

export default Home;
