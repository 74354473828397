import React from "react";
import { Link } from "react-router-dom";
import { url, local } from "../Services/Url";
import axios from 'axios';

class PromotionBanner extends React.Component {

  constructor() {
    super()
    this.state = {
      isReady: false,
      banner: '',
      url: ''
    }
  }

  componentDidMount() {
    axios.get(`${url}/api/v1/homepage/one-column-banner`)
      .then(response => {
        if (response.status == 200) {
          this.setState({
            banner: response.data.file,
            url: response.data.url,
            isReady: true
          });
        }
      })
  }

  render() {

    if (this.state.isReady) {
      return (
        <>
          <div className="banner-area-5" style={{ margin: "95px 0" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="banner-img-2">
                    <Link to={this.state.url}>
                      <img src={this.state.banner} alt="banner" style={{ objectFit: "contain" }} />
                    </Link>
                    {/* <div className="banner-text">
                      <h3>G. Meyer Books & Spiritual Traveler Press</h3>
                      <h2>
                        Sale up to{" "}
                        <span
                          style={{
                            color: "#24902f",
                            fontWeight: "bold",
                            fontSize: "48px",
                          }}
                        >
                          30%
                        </span>{" "}
                        off
                      </h2>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="banner-area-5" style={{ margin: "95px 0" }}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="banner-img-2">
                    {/* <p className="text-center">Loading...</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }


  }
};

export default PromotionBanner;