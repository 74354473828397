import { counter } from '@fortawesome/fontawesome-svg-core';
import React from 'react'
import {Link} from "react-router-dom"

const SingleBlogPost = (props) => {

	const post = props.post;

	// Format API response date to human readable.
	const formatDate = (dateString) => {
		const options = { year: "numeric", month: "long", day: "numeric" }
		return new Date(dateString).toLocaleDateString(undefined, options)
	}


	// Convert HTML description to plain text and limit character
	const convertToText = (html, length = 200) => {
		let tempDivElement = document.createElement("div");

		tempDivElement.innerHTML = html;

		let string = tempDivElement.textContent || tempDivElement.innerText || "";

		return string.substring(0, length) + "...";
	}

	// Return the comments inforamtion
	const comments = (comment) => {
		if(comment === 0)
			return 'No Comments';

		if(comment === 1)
			return "1 Comment";

		return `${comment} Comments`;
	}
	
    return (
        <>
              <div className="single-blog-post">
                <div className="author-destils mt-5" style={{marginBottom:"30px"}}>
					    <div className="author-left">
							    <div className="author-img">
									    <Link to={`/blog-details/${post.slug}`}><img src="/img/author/1.jpg" alt="man" style={{maxWidth:"100%"}}/></Link>
								</div>
								<div className="author-description">
									<p>Posted by: 
										<Link to={`/blog-details/${post.slug}`}  style={{textDecoration:"none"}}><span>{post.author}</span>in</Link>
										{post.categories.map((item, index) => (
											<Link to={`/blog/category/${item.slug}`} key={index} style={{textDecoration:"none"}}> {item.name}{(post.categories.length === index+1) ? '' : ','} </Link>
										))}
									</p>
										<span>{ formatDate(post.created_at) }</span>
								</div>
						</div>
						<div className="author-right">
							<span>Share this:</span>
								<ul>
									<li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
									<li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
									<li><Link to="#"><i className="fab fa-linkedin-in"></i></Link></li>
								</ul>
						</div>
				    </div>

                    <div className="blog-img" style={{marginBottom:"30px"}}>
						{(post.image.length === 0) ? '' : <Link to={`/blog-details/${post.slug}`}><img style={{ width: "100%", height: "auto" }} src={post.image.path} alt="blog" /></Link>}
						{/* <Link to="#"><img src="img/blog/1.jpg" alt="blog" /></Link> */}
					</div>

                    <div className="single-blog-content">
						<div className="single-blog-title">
							<h3><Link to={`/blog-details/${post.slug}`}>{post.name}</Link></h3>
						</div>
						<div className="blog-single-content">
							{convertToText(post.description, 300)}
						</div>
					</div>

                    <div className="blog-comment-readmore">
						<div className="blog-readmore">
							<Link to={`/blog-details/${post.slug}`}>Read more<i className="fal fa-arrow-right"></i></Link>
						</div>
						<div className="blog-com">
							<Link to={`/blog-details/${post.slug}`}>{comments(post.comments.length)}</Link>
						</div>
					</div>
              </div>
        </>
    )
}

export default SingleBlogPost
