import React from 'react'
import {Link} from "react-router-dom"
const BlogSearch = () => {
    return (
        <>
           <div className="single-blog " style={{marginBottom:"50px"}}>
				<div className="blog-left-title">
					<h3>Search</h3>
				</div>
				<div className="side-form">
					<form action="#">
						<input type="text" placeholder="Search...." />
						<Link to="#"><i className="fa fa-search"></i></Link>
					</form>
				</div>
			</div> 
        </>
    )
}

export default BlogSearch
