import React, { createContext, useReducer, useEffect } from "react";
import { cartReducer } from "./Reducer";

const initialState = {
  cart: [],
  total: 0,
};

export const CartContext = createContext(initialState);

const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState, () => {
    const localData = localStorage.getItem("books");
    return localData ? JSON.parse(localData) : initialState;
  });
  // const [state, dispatch] = useReducer(cartReducer, initialState);

  useEffect(() => {
    localStorage.setItem("books", JSON.stringify(state));
  }, [state]);

  const addToCart = (book) => {
    dispatch({
      type: "ADD_TO_CART",
      payload: book,
    });
  };
  const removeToCart = (book) => {
    dispatch({
      type: "REMOVE_TO_CART",
      payload: book,
    });
  };
  const incrementQuantity = (book) => {
    dispatch({
      type: "INCREMENT_QUANTITY",
      payload: book,
    });
  };

  const decrementQuantity = (book) => {
    dispatch({
      type: "DECREMENT_QUANTITY",
      payload: book,
    });
  };

  const updatedQuantity = (book, quantity) => {
    dispatch({
      type: "UPDATED_QUANTITY",
      payload: book,
      quantity: quantity,
    });
  };

  return (
    <CartContext.Provider
      value={{
        cart: state.cart,
        total: state.total,
        addToCart,
        incrementQuantity,
        decrementQuantity,
        updatedQuantity,
        removeToCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default GlobalStateProvider;
