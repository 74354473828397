import React,{useState} from "react";
import Breadcumbs from "../../Components/Breadcumbs";

import { Link ,useHistory} from "react-router-dom";
import "./Register.css";

import {url} from '../../Services/Url'
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import axios from "axios";

const Register = () => {
  const { register, formState: { errors },handleSubmit ,reset } = useForm();
  const [check,setCheck]= useState(false)
  let history = useHistory();
  const onSubmit = data =>{
    axios.post(`${url}/api/v1/user/registration`,
    {first_name:data.first_name,
      last_name:data.last_name,
      email:data.email,
      phone:data.phone,
      password:data.password,
      confirm_password:data.confirm_password
    }).then((res)=>{
      console.log(res.data);
      toast.success("Successfully Registered",{autoClose:2000});
      reset();
      history.push('/login')
    }).catch((err)=>{
      console.log(err.message);
    })
  };

  const handleCheck = ()=>{
    if (!check) {
      setCheck(true)
    }
    else{
      setCheck(false)
    }
  }
  return (
    <>
   
      <Breadcumbs />
      <div
        className="user-login-area mb-70 mt-5"
        style={{ marginBottom: "70px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="login-title text-center mb-30"
                style={{ marginBottom: "30px" }}
              >
                <h2>Sign Up</h2>
              </div>
            </div>
            <div className="offset-lg-2 col-lg-8 col-md-12 col-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="billing-fields">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="single-register">
                        <label>
                          First Name<span>*</span>
                        </label>
                        <input type="text" {...register("first_name", { required: true })}/>
                        {errors.first_name?.type === 'required' && <span style={{color:"red"}}>First Name is required</span>}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="single-register">
                        <label>
                          Last Name<span>*</span>
                        </label>
                        <input type="text" {...register("last_name", { required: true })}/>
                        {errors.last_name?.type === 'required' && <span style={{color:"red"}}>Last Name is required</span>}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="single-register">
                        <label>
                          Email Address<span>*</span>
                        </label>
                        <input type="text"  {...register("email", { required: true })}/>
                        {errors.email?.type === 'required' && <span style={{color:"red"}}>Email is required</span>}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="single-register">
                        <label>
                          Phone<span>*</span>
                        </label>
                        <input type="text" {...register("phone", { required: true })}/>
                        {errors.phone?.type === 'required' && <span style={{color:"red"}}>Phone is required</span>}
                    </div>
                  </div>
                </div>
                <div className="single-register"> 
                    <label>
                      Account password<span>*</span>
                    </label>
                    <input type="password" placeholder="Password" {...register("password", { required: true })} />
                    {errors.password?.type === 'required' && <span style={{color:"red"}}>Password is required</span>}
                </div>
                <div className="single-register">
                    <label>
                      Confirm password<span>*</span>
                    </label>
                    <input type="password" placeholder="Confirm Password" {...register("confirm_password", { required: true })} />
                    {errors.confirm_password?.type === 'required' && <span style={{color:"red"}}>Confirm Password is required</span>}
                </div>
                <div className="checkInput">
                  <input
                    id="rememberme"
                    type="checkbox"
                    name="rememberme"
                    value="forever"
                    className=" mt-1"
                    checked = {check?"checked":""}
                    onChange={handleCheck}
                  />
        
                  <span class="checkmark">I agree <Link to="#" style={{textDecoration:"none",color:"#259030"}}>Terms & Condition</Link></span>
                </div>
               
                <div className="single-register">
                  <input type="submit" style={{color:!check?"black":"#fff",background:!check?"":"#259030"}} disabled={!check?"disabled":""} />
                </div>
              </div>
              </form>
              <p className="mt-2">
                Already have an account?{" "}
                <span>
                  <strong>
                    <Link
                      to="/login"
                      style={{ textDecoration: "none", color: "#259030" }}
                    >
                      LogIn Now!
                    </Link>
                  </strong>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
};

export default Register;
