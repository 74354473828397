import React,{useState,useEffect,useContext} from "react";
import axios from 'axios'
import {Link, useHistory} from "react-router-dom"
import {url} from "../../Services/Url"
import { AuthContext } from "../../Context/AuthState";
import { toast } from 'react-toastify';
const ProfileAccountDetails = ({userData,setUserData}) => {
  // const [userData,setUserData] = useState({first_name:"",last_name:"",email:"",phone:"",password:"",confirm_password:""})
  const {state} = useContext(AuthContext)
  const history = useHistory();
  // const [first_name,setFirstName]=useState("");
  // const [last_name,setLastName]=useState("")

  const config = {
    headers:{Authorization:`Bearer ${state.token}`}
  }

  const getUser = ()=>{
      axios.get(`${url}/api/v1/user`,config)
      .then((res)=>
        setUserData({first_name:res.data.first_name,last_name:res.data.last_name,email:res.data.email,phone:res.data.phone}),
      )
      .catch((err)=>console.log(err.message))
  }

  useEffect(()=>{
    axios.get(`${url}/api/v1/user`,config)
    .then((res)=>setUserData({first_name:res.data.first_name,last_name:res.data.last_name,email:res.data.email,phone:res.data.phone}))
    .catch((err)=>console.log(err.message))
  },[])


// console.log("MYdata",userData.first_name);

const handleSubmit = (e)=>{
  e.preventDefault();
  const data = {
    first_name:userData.first_name,
    last_name:userData.last_name,
    email:userData.email,
    phone:userData.phone,
    password:userData.password,
    confirm_password:userData.confirm_password
  }
  axios.put(`${url}/api/v1/user`,data,config)
  .then(()=>
      // getUser(),
      history.push("/profile"),
      // window.location.reload('/profile'),
      toast.success("Successfully updated",{autoClose:2000})
      // setUserData({password:"",confirm_password:""})
     
  )
  .catch((err)=>console.log(err.message))
}




  return (
    <>
      <div className="tab-pane fade" id="account-info" role="tabpanel">
        <div className="myaccount-content">
          <h5>Account Details</h5>
          <div className="account-details-form">
            <form >
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-input-item">
                  <label htmlFor="first_name">First Name</label>
                  <input type="text" id="first_name"  placeholder="First Name" value={userData.first_name} onChange={(e)=>setUserData({...userData,first_name:e.target.value})}/>
                  {userData.first_name === '' ? <span style={{color:"red"}}>First Name Required</span>:""}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-input-item">
                    <label htmlFor="last_name" className="required">
                      Last Name
                    </label>
                    <input type="text" id="last_name" placeholder="Last Name" value={userData.last_name} onChange={(e)=>setUserData({...userData,last_name:e.target.value})}/>
                    {userData.last_name === '' ? <span style={{color:"red"}}>Last Name Required</span>:""}
                  </div>
                </div>
              </div>
              <div className="single-input-item">
                <label htmlFor="display-name" className="required">
                  Phone
                </label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="Phone Number"
                  value={userData.phone}
                  onChange={(e)=>setUserData({...userData,phone:e.target.value})}
                />
                 {userData.phone === '' ? <span style={{color:"red"}}>Phone Number is  Required</span>:""}
              </div>
              <div className="single-input-item">
                <label htmlFor="email" className="required">
                  Email Addres
                </label>
                <input type="email" id="email" placeholder="Email Address" value={userData.email} onChange={(e)=>setUserData({...userData,email:e.target.value})}/>
                {userData.email === '' ? <span style={{color:"red"}}>Email is Required</span>:""}
              </div>
              <fieldset>
                <legend>Password change</legend>
               
                <div className="row">
                  <div className="col-lg-6">
                    <div className="single-input-item">
                      <label htmlFor="new-pwd" className="required">
                        New Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        placeholder="New Password"
                        onChange={(e)=>setUserData({...userData,password:e.target.value})}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="single-input-item">
                      <label htmlFor="confirm_password">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        id="confirm_password"
                        name="confirm_password"
                        placeholder="Confirm Password"
                        onChange={(e)=>setUserData({...userData,confirm_password:e.target.value})}
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
              <div className="single-input-item mybutton">
                <Link to="" className="" type="submit" onClick={handleSubmit}>Save Changes</Link>
              </div>
            </form>

          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileAccountDetails;
