import React from 'react'
import {Link} from "react-router-dom"

const BlogPagination = () => {
    return (
        <>
            <div className="blog-pagination text-center">
				<ul>
					<li><Link to="#"><i className="fa fa-angle-left"></i></Link></li>
					<li  className="active"><Link to="#">1</Link></li>
					<li><Link to="#">2</Link></li>
					<li><Link to="#">3</Link></li>
					<li><Link to="#">4</Link></li>
					<li><Link to="#"><i className="fa fa-angle-right"></i></Link></li>
				</ul>
			</div>
        </>
    )
}

export default BlogPagination
