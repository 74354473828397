import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MobileMenuBar from "./MobileMenuBar";
import axios from "axios";
import { url } from "../Services/Url";
const linkStyle = {
  textDecoration: "none",
};

const MenuBar = () => {
  const [authors, setAuthors] = useState([]);
  const [publishers, setPublishers] = useState([]);
  const [categories, setCategories] = useState([]);

  const fetchData = () => {
    const authorsApi = `${url}/api/v1/authors`;
    const publishersApi = `${url}/api/v1/publishers`;
    const categoriesApi = `${url}/api/v1/categories`;
    const getAllAuthors = axios.get(authorsApi);
    const getAllPublishers = axios.get(publishersApi);
    const getAllCategories = axios.get(categoriesApi);
    axios.all([getAllAuthors, getAllPublishers, getAllCategories]).then(
      axios.spread((...allData) => {
        const AllAuthors = allData[0];
        const AllPublishers = allData[1];
        const AllCategories = allData[2];
        // const arr1 = AllAuthors.data.slice(211, 215);
        // const arr2 = AllAuthors.data.slice(232, 233);
        // const arr3 = [...arr1, ...arr2];
        setAuthors(AllAuthors.data);
        // console.log("AllPublishers", AllPublishers.data.slice(0, 10));
        const pubarr1 = AllPublishers.data.slice(0, 6);
        const pubarr2 = AllPublishers.data.slice(11, 14);
        const pubarr3 = AllPublishers.data.slice(21, 23);
        const pubarr = [...pubarr1, ...pubarr2, ...pubarr3];
        setPublishers(pubarr);
        // console.log("AllCategories", AllCategories.data.slice(0, 10));
        const arr1 = AllCategories.data.slice(0, 6);
        const arr2 = AllCategories.data.slice(22, 23);
        const arr3 = AllCategories.data.slice(47, 50);
        const arr4 = [...arr1, ...arr2, ...arr3];
        setCategories(arr4);
      })
    );
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div
        className="main-menu-area d-md-none d-none d-lg-block sticky-header-1"
        id="header-sticky"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="menu-area">
                <nav>
                  <ul style={{ paddingLeft: "0px" }}>
                    <li className="active">
                      <Link
                        to="/"
                        onClick={() => (window.location.href = `/`)}
                        style={linkStyle}
                      >
                        হোম
                      </Link>
                    </li>
                    <li>
                      <Link
                        style={linkStyle}
                        to="/books"
                        onClick={() => (window.location.href = `/books`)}
                      >
                        সকল বই
                        {/* <i className="fa fa-angle-down"></i> */}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/books"
                        onClick={() => (window.location.href = `/books`)}
                        style={linkStyle}
                      >
                        জনপ্রিয় বই
                        {/* <i className="fa fa-angle-down"></i> */}
                      </Link>
                    </li>

                    <li>
                      <Link to="/authors" style={linkStyle}>
                        লেখক<i className="fa fa-angle-down"></i>
                      </Link>
                      <div className="mega-menu mega-menu-2">
                        {/* <span>
                          <Link
                            to="/books/authors/1/Harris David"
                            onClick={() =>
                              (window.location.href = `/books/authors/1/Harris David`)
                            }
                          >
                            Harris David
                          </Link>
                        
                        </span> */}
                        {authors.map((item, index) => (
                          <span key={index}>
                            <Link
                              key={item.id}
                              to={{
                                pathname: `/books/authors/${item.id
                                  }/${item.name.replace(/ /g, "-")}`,
                              }}
                            // onClick={() =>
                            //   (window.location.href = `/books/authors/${
                            //     item.id
                            //   }/${item.name.replace(/ /g, "-")}`)
                            // }
                            >
                              {item.name}
                            </Link>
                          </span>
                        ))}
                      </div>
                    </li>
                    <li>
                      <Link to="/categories" style={linkStyle}>
                        বিষয়
                        <i className="fa fa-angle-down"></i>
                      </Link>
                      <div className="mega-menu mega-menu-2">
                        {/* <span>
                          <Link to="#" className="title">
                            Tops
                          </Link>
                          <Link to="/books/categories/law">law</Link>
                          <Link to="#">Florals</Link>
                          <Link to="#">Crochet</Link>
                          <Link to="#">Stripes</Link>
                        </span> */}
                        {categories.map((item, index) => (
                          <span key={index}>
                            <Link
                              to={{
                                pathname: `/books/categories/${item.slug}`,
                              }}
                              key={item.id}
                              // onClick={() =>
                              //   (window.location.href = `/books/categories/${item.slug}`)
                              // }
                              style={{
                                overflow: "hidden",
                                maxWidth: "140px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.name}
                            </Link>
                          </span>
                        ))}
                      </div>
                    </li>
                    <li>
                      <Link to="/publications" style={linkStyle}>
                        প্রকাশনী<i className="fa fa-angle-down"></i>
                      </Link>
                      <div className="mega-menu mega-menu-2">
                        {publishers.map((item, index) => (
                          <span key={index}>
                            <Link
                              to={{
                                pathname: `/books/publishers/${item.slug}`,
                              }}
                              key={item.id}
                              // onClick={() =>
                              //   (window.location.href = `/books/publishers/${item.slug}`)
                              // }
                              style={{
                                overflow: "hidden",
                                maxWidth: "140px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item.name}
                            </Link>
                          </span>
                        ))}
                      </div>
                    </li>

                    <li>
                      <Link to="#" style={linkStyle}>
                        প্রি অর্ডার
                      </Link>
                    </li>
                    <li>
                      <Link to="#" style={linkStyle}>
                        প্যাকেজ
                      </Link>
                    </li>
                    {/* <li><Link to="#" style={linkStyle}>Packages</Link></li> */}

                    <li>
                      <Link to="#" style={linkStyle}>
                        বইমেলা ২০২১
                      </Link>
                    </li>
                    <li>
                      <Link to="/blog" style={linkStyle}>
                        ব্লগ
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MobileMenuBar />
    </>
  );
};

export default MenuBar;
