import React from "react";
import GlobalFooter from "../../Components/GlobalFooter";
import HeaderTopBar from "../../Components/HeaderTopBar";
import LogoBar2 from "../../Components/LogoBar2";
import MenuBar from "../../Components/MenuBar";
import "./Blog.css";
import BlogLeftSideBar from "./BlogLeftSideBar/BlogLeftSideBar";
import BlogPosts from "./BlogPosts/BlogPosts";

const Blog = () => {


  return (
    <>
      {/* <HeaderTopBar /> */}
      {/* <LogoBar2 /> */}
      {/* <MenuBar /> */}
      <div className="blog-main-area " style={{marginBottom:"70px"}}>
        <div className="container">
          <div className="row">
              <BlogLeftSideBar/> 
              <BlogPosts/>
          </div>
        </div>
      </div>
      {/* <GlobalFooter/> */}
    </>
  );
};

export default Blog;
