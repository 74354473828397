import React from "react";
import Breadcumbs from "../Components/Breadcumbs";
import GlobalFooter from "../Components/GlobalFooter";

const About = () => {
  return (
    <>
    
      <Breadcumbs />
      <div className="about-me" style={{ marginBottom: "50px" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-lg-3 col-md-12 col-12"
              style={{ marginTop: "50px" }}
            ></div>
           

            <div className="col-lg-9 col-md-12 col-12 order-lg-2 order-1">
              <div className="blog-main-wrapper">
                <div
                  className="author-destils mb-30"
                  style={{ marginBottom: "30px" }}
                ></div>
                <div
                  className="blog-img mb-30"
                  style={{ marginBottom: "30px" }}
                >
                  <img src="/img/Boi-Bazaar/about_us.jpg" alt="blog" />
                </div>
                <div className="single-blog-content">
                  <div className="blog-single-content">
                    <p>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                      sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                      magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                      quis nostrud exerci tation ullamcorper suscipit lobortis
                      nisl ut aliquip ex ea commodo consequat. Duis autem vel
                      eum iriure dolor in hendrerit in vulputate velit esse
                      molestie consequat, vel illum dolore eu feugiat nulla
                      facilisis at vero eros et accumsan et iusto odio dignissim
                      qui blandit praesent luptatum zzril delenit augue duis
                      dolore te feugait nulla facilisi. Nam liber tempor cum
                      soluta nobis eleifend option congue nihil imperdiet doming
                      id quod mazim placerat facer possim assum. Typi non habent
                      claritatem insitam; est usus legentis in iis qui facit
                      eorum
                    </p>
                    <blockquote className="blockstyle">
                      claritatem. Investigationes demonstraverunt lectores
                      legere me lius quod ii legunt saepius. Claritas est etiam
                      processus dynamicus, qui sequitur mutationem consuetudium
                      lectorum. Mirum est notare quam littera gothica, quam nunc
                      putamus parum claram, anteposuerit litterarum formas
                      humanitatis per seacula quarta decima et quinta decima.
                      Eodem modo typi, qui nunc nobis videntur parum clari,
                      fiant sollemnes in futurum.
                    </blockquote>
                    <p>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                      sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                      magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                      quis nostrud exerci tation ullamcorper suscipit lobortis
                      nisl ut aliquip ex ea commodo consequat. Duis autem vel
                      eum iriure dolor in hendrerit in vulputate velit esse
                      molestie consequat, vel illum dolore eu feugiat nulla
                      facilisis at vero eros et accumsan et iusto odio
                      dignissim. laoreet dolore magna aliquam erat volutpat. Ut
                      wisi enim ad minim veniam, quis nostrud exerci tation
                      ullamcorper suscipit lobortis nisl ut aliquip ex ea
                      commodo consequat. Duis autem vel eum iriure dolor in
                      hendrerit in vulputate velit esse molestie consequat, vel
                      illum dolore eu feugiat nulla facilisis at vero eros et
                      accumsan et iusto odio dignissim.
                    </p>
                    <p>
                      dolore eu feugiat nulla facilisis at vero eros et accumsan
                      et iusto odio dignissim. laoreet dolore magna aliquam erat
                      volutpat. Ut wisi enim ad minim veniam, quis nostrud
                      exerci tation ullamcorper suscipit lobortis nisl ut
                      aliquip ex ea commodo consequat. Duis autem vel eum iriure
                      dolor in hendrerit in vulputate velit esse molestie
                      consequat, vel illum dolore eu feugiat nulla facilisis at
                      vero eros et accumsan et iusto odio dignissim.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GlobalFooter />
    </>
  );
};

export default About;
